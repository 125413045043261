/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";
import FailModal from "../../../components/Modals/FailModal";

import {getSmartLegalCovers} from '../../../apis/legal';

const validationSchema = yup.object({
});

function Step1(props) {
  const [backdropClose, setBackdropClose] = useState(true);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [showError, setShowError] = useState(false);
  const [smartLegalCoverData, setSmartLegalCoverData] = useState(null);
  let { coverId } = useParams();
  const [stepValues, setStepValues] = useState({

});

useEffect(() => {
  let cancel = false;
  if(cancel)
    return;

  setIsMobile(props.isMobile);

  return () => { 
    cancel = true;
  }
}, [props.isMobile]);

useEffect(() => {
  let cancel = false;
  if(cancel)
    return;
  
    if(coverId) {
      getSmartLegalCovers(coverId)
        .then((data) => {
          setSmartLegalCoverData(data);
          setBackdropClose(false);
        })
        .catch((err) => {
          setShowError(true);
          setBackdropClose(false);
        });
    }

  return () => { 
    cancel = true;
  }
}, [coverId])

useEffect(() => {
  let cancel = false;
  if(cancel)
    return;
  if(props.step1Values) {
    setStepValues(props.step1Values);
  }

  return () => { 
    cancel = true;
  }
}, [props.step1Values]);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
      
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(0);
    props.setCurrentStepName("Quote Details");
    props.setNextStepName("Personal Info");

    return () => { 
      cancel = true;
    }
  }, [props]);
  
  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Quote Details
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Please confirm the product details
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <Formik
            enableReinitialize={true}
            initialValues={stepValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            const productInfo = {
              coverId: coverId,
              cover: smartLegalCoverData?.name,
              coverAmount: smartLegalCoverData?.coverAmount,
              coverRate: smartLegalCoverData?.coverRate
            };
            props.setStep1Values({...values, ...productInfo});
            props.setCurrentStep(2);
          }}
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
              {smartLegalCoverData && <Grid item xs={12}>
                  <MKInput 
                    type="text" 
                    label="Cover"
                    id="cover" 
                    value={smartLegalCoverData?.name}
                    fullWidth 
                    disabled={true}
                  />
                </Grid>}
              {smartLegalCoverData && <Grid item xs={12}>
                  <MKInput 
                    type="text" 
                    label="Cover amount"
                    id="coverAmount" 
                    value={smartLegalCoverData?.coverAmount}
                    fullWidth 
                    disabled={true}
                  />
                </Grid>}
                {smartLegalCoverData && <Grid item xs={12}>
                  <MKInput 
                    type="text" 
                    label="Premium (per month)"
                    id="coverRate" 
                    value={smartLegalCoverData?.coverRate}
                    fullWidth 
                    disabled={true}
                  />
                </Grid>}
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                  {props.errors.coverType && props.errors.coverType}
                  {!props.errors.coverType && props.errors.noOfDependents && props.errors.noOfDependents}
                  </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" href="/our-offering/legal-cover/personal-legal-cover" variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>
        </MKBox>
      </MKBox>
      <FailModal isMobile={isMobile} show={showError} header="Data download" text="We apologise, some data could not be downloaded. Please reload." />
    </>
  );
}

export default Step1;