const baseURL = process.env.REACT_APP_API_Base_Url;
const apiKey = process.env.REACT_APP__Api_Key;

export const getUserDebtCollections = async (userId) =>
  await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/debt/all/get`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "x-functions-key": apiKey,
            },
            body: JSON.stringify({userId: userId}),
          }).then((response) => {
            resolve(response.json());
          }).catch((reqErr) => {
              reject(reqErr);
          });
        });

export const getUserDebtCollection = async (debtId, userId) =>
  await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/debt/get`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "x-functions-key": apiKey,
            },
            body: JSON.stringify({userId: userId, debtId: debtId}),
          }).then((response) => {
            resolve(response.json());
          }).catch((reqErr) => {
              reject(reqErr);
          });
        });

export const postUserDebtCollection = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/debt/post`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
                resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });