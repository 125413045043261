// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import DefaultCounterCard from "../../components/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "../../components/MaterialKit/MKTypography";
import MKButton from "../../components/MaterialKit/MKButton";


function DebtCollection(props) {
  
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
              <Breadcrumbs
                routes={[
                  { label: "Our Offering", route: "/our-offering/debt-collection" },
                  { label: "Debt Collection" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
            <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                  <MKTypography variant="h3" color="dark">
                    Debt Collection
                  </MKTypography>
                  <MKTypography variant="body2" color="dark" opacity={0.8}>
                    Our online Overdue Debt Collection application is divided into 7 simple steps:
                  </MKTypography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DefaultCounterCard
                  count={1}
                  prefix="Step "
                  separator=","
                  title="Debt Information"
                  description='Tell us about your debt'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DefaultCounterCard
                  count={2}
                  separator=","
                  prefix="Step "
                  title="Letter of Demand"
                  description="Decide if you are ready to continue"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DefaultCounterCard
                  count={3}
                  prefix="Step "
                  title="Choose Collector"
                  description="Let us know which collector you would like to use"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DefaultCounterCard
                  count={4}
                  prefix="Step "
                  title="Confirmation"
                  description="We advise you of how the debtor could react after the letter of demand is sent. This is to better prepare you for the next steps."
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DefaultCounterCard
                  count={5}
                  prefix="Step "
                  title="Invoice"
                  description="We show you exactly what you are paying for."
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DefaultCounterCard
                  count={6}
                  prefix="Step "
                  title="Payment"
                  description="We take you to a secure payment facility. Your security is important to US."
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <DefaultCounterCard
                  count={7}
                  prefix="Step "
                  title="Documentation"
                  description="We show you the document we will be sending the debtor and allow you to download it."
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                  <MKTypography variant="body2" color="dark" opacity={0.8}>
                    Once the online application has been done, you then become a client of “THE COLLECTOR” and they will keep you updated as to the progress of the Debt Collect matter.
                  </MKTypography>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKButton
                  component={MuiLink}
                  href="/start-debt-collecting"
                  rel="noreferrer"
                  variant="gradient"
                  size="small"
                  color="info"
                >
                  start debt collecting
                </MKButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default DebtCollection;