/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from "@mui/material/Switch";

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKTypography from "../../MaterialKit/MKTypography";
import MKInput from "../../MaterialKit/MKInput";
import MKButton from "../../MaterialKit/MKButton";

// @mui icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoginIcon from '@mui/icons-material/Login';

import { AccountContext } from '../../../cognito/Account';

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

const validationRegisterSchema = yup.object({
    name: yup.string()
    .required('Name is required'),
    surname: yup.string()
      .required('Surname is required'),
    telephone: yup.string().required('Telephone is required').matches(phoneRegExp, 'A valid mobile number is required'),
    email: yup.string().email('Must be a valid email').max(255).required('Email is required')
  });

const validationSignInSchema = yup.object({
    email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
  });

const validationSignInPassCodeSchema = yup.object({
    authCode: yup.string().required('Authentication code is required')
  });

  
function StepperSignIn(props) {

    const { authenticate, answerCustomChallenge, signUp } = useContext(AccountContext);
    const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);

    const [activeTab, setActiveTab] = useState(0);
    const [activeSubTab, setActiveSubTab] = useState(0);
    const [backdropClose, setBackdropClose] = useState(false);
    const [saveFailedText, setSaveFailedText] = useState(null);
    const [signInUserName, setSignInUserName] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);
    
    const handleChecked = () => setTermsAccepted(!termsAccepted);

    useEffect(() => {
      let cancel = false;
      if(cancel)
        return;

      setSessionInfo(props.sessionInfo);

      return () => { 
        cancel = true;
      }
    }, [props.sessionInfo]);

    const handleTabType = (event, newValue) => {
        setSaveFailedText(null);
        setActiveTab(newValue);
    }
  
  const goBack = (e) => {
    props.goBack();
  }

  const getRandomString = (length) =>
    window.btoa(Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2))).map((b) => String.fromCharCode(b)).join("")).replace(/[+/]/g, "").substring(0, length);

  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
      {!sessionInfo && <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <AppBar position="static" style={{"padding": "5px"}}>
                  <Tabs value={activeTab} onChange={handleTabType} aria-label="icon label tabs example">
                      <Tab icon={<LoginIcon />} label="SIGN IN" />
                      <Tab icon={<AddCircleOutlineIcon />} label="REGISTER" />
                  </Tabs>
            </AppBar>
            <Divider sx={{ my: 0 }} />
          </Grid>
        {activeTab === 0 && activeSubTab === 0 && <Grid item xs={12}>
            <Formik
                    initialValues={{
                        email: ""
                    }}
                    validationSchema={validationSignInSchema}
                    onSubmit={values => {
                        setBackdropClose(true);

                        authenticate(values.email)
                            .then(data => {
                                setActiveSubTab(1);
                                setSignInUserName(values.email);
                                setSaveFailedText(null);
                                setBackdropClose(false);
                            })
                            .catch(err => {
                                const msg = err.message ? err.message : "An unknown error occurred";
                                setSaveFailedText(msg);
                                setSignInUserName(null);
                                setBackdropClose(false);
                            });
                }}
                >
            {(props) => ( 
                <Form>
                  <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                      <Grid item xs={12}>
                            <MKInput 
                                type="email" 
                                label="Email" 
                                id="email" 
                                value={props.values.email}
                                onChange={props.handleChange('email')}
                                error={props.errors.email && Boolean(props.errors.email)}
                                fullWidth 
                            />
                        </Grid>
                        <Grid item xs={12}  sx={{ textAlign: "left" }}>
                          <Switch checked={termsAccepted} onChange={handleChecked} />
                          <MKTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            ml={-1}
                            sx={{ cursor: "pointer", userSelect: "none" }}
                            onClick={handleChecked}
                          >
                            &nbsp;&nbsp;I accept the&nbsp;
                          </MKTypography>
                          <MKTypography
                            component="a"
                            href="/collectionterms"
                            target="_blank"
                            variant="button"
                            fontWeight="regular"
                            color="dark"
                          >
                            Terms and Conditions
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <MKTypography variant="caption" color="error">
                              &nbsp;
                              {saveFailedText && saveFailedText}
                              {!saveFailedText && props.errors.email && props.errors.email}
                          </MKTypography>
                        </Grid>
                        <Grid item xs={6}  sx={{ textAlign: "left" }}>
                          <MKButton type="button" onClick={goBack} variant="gradient" color="light" sx={{ height: "100%" }}>
                            Back
                          </MKButton>
                        </Grid>
                        <Grid item xs={6}  sx={{ textAlign: "right" }}>
                          <MKButton type="submit" disabled={!termsAccepted} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Sign in
                          </MKButton>
                        </Grid>
                      </Grid>
                </Form>
          )}
          </Formik>
        </Grid>}
        {activeTab === 0 && activeSubTab === 1 && <Grid item xs={12}>
          <Formik
            initialValues={{
                authCode: ""
            }}
            validationSchema={validationSignInPassCodeSchema}
            onSubmit={values => {
                setBackdropClose(true);

                answerCustomChallenge(signInUserName, values.authCode.trim())
                  .then(data => {
                    setActiveSubTab(0);
                    props.refreshSession();
                    setSaveFailedText(null);
                    setBackdropClose(false);
                  })
                  .catch(err => {
                    // TODO: we can move the user to the register tab automatically
                    // if the code email can't be sent because of no user existing?
                    console.error('error', err);
                    let msg = err.message ? err.message : "An unknown error occurred";
                    if (msg === 'Incorrect username or password.') {
                      msg = 'Incorrect authentication code provided. Please recheck your e-mail containing your authentication code.';
                    }
                    setSaveFailedText(msg);
                    setBackdropClose(false);
                  });
        }}
        >
        {(props) => (
            <Form>
              <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <MKInput 
                        type="text" 
                        label="Authentication code" 
                        id="authCode" 
                        value={props.values.authCode}
                        onChange={props.handleChange('authCode')}
                        error={props.errors.authCode && Boolean(props.errors.authCode)}
                        helperText="An authentication code was emailed to you. Please check your inbox or spam folder." 
                        fullWidth 
                    />
                </Grid>
                <Grid item xs={12}>
                    <MKTypography variant="caption" color="error">
                        &nbsp;
                        {saveFailedText && saveFailedText}
                        {!saveFailedText && props.errors.authCode && props.errors.authCode}
                    </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" onClick={goBack} variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="dark" sx={{ height: "100%" }}>
                    Continue
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
        )}
        </Formik>
        </Grid>}
        {activeTab === 1 && <Grid item xs={12}>
          <Formik
            initialValues={{
                name: '',
                surname: '',
                company: '',
                telephone: '',
                email: ''
            }}
            validationSchema={validationRegisterSchema}
            onSubmit={values => {
                setBackdropClose(true);

                const newPassword = getRandomString(30);

                signUp(values.email, newPassword, values.name, values.surname, values.company, values.telephone)
                    .then(data => {
                      authenticate(values.email)
                          .then(data => {
                              setActiveSubTab(1);
                              setSaveFailedText(null);
                              setActiveTab(0);
                              setSignInUserName(values.email);
                              setBackdropClose(false);
                          })
                          .catch(err => {
                              const msg = err.message ? err.message : "An unknown error occurred";
                              setSaveFailedText(msg);
                              setSignInUserName(null);
                              setBackdropClose(false);
                          });
                    })
                    .catch(err => {
                        const msg = err.message ? err.message : "An unknown error occurred";
                        setSaveFailedText(msg);
                        setSignInUserName(null);
                        setBackdropClose(false);
                    });
        }}
        >
        {(props) => (
        <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <MKInput 
                        type="text" 
                        label="Name" 
                        id="name" 
                        value={props.values.name}
                        onChange={props.handleChange('name')}
                        error={props.errors.name && Boolean(props.errors.name)}
                        fullWidth 
                    />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                      type="text" 
                      label="Surname" 
                      id="surname" 
                      value={props.values.surname}
                      onChange={props.handleChange('surname')}
                      error={props.errors.surname && Boolean(props.errors.surname)}
                      fullWidth 
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                      type="text" 
                      label="Company" 
                      id="company" 
                      value={props.values.company}
                      onChange={props.handleChange('company')}
                      error={props.errors.company && Boolean(props.errors.company)}
                      fullWidth 
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                      type="text" 
                      label="Telephone"
                      id="telephone" 
                      value={props.values.telephone}
                      onChange={props.handleChange('telephone')}
                      error={props.errors.telephone && Boolean(props.errors.telephone)}
                      fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                      type="text" 
                      label="Email" 
                      id="email" 
                      value={props.values.email}
                      onChange={props.handleChange('email')}
                      error={props.errors.email && Boolean(props.errors.email)}
                      fullWidth 
                  />
                </Grid>
                <Grid item xs={12}  sx={{ textAlign: "left" }}>
                  <Switch checked={termsAccepted} onChange={handleChecked} />
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    ml={-1}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    onClick={handleChecked}
                  >
                    &nbsp;&nbsp;I accept the&nbsp;
                  </MKTypography>
                  <MKTypography
                    component="a"
                    href="/collectionterms"
                    target="_blank"
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    Terms and Conditions
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error">
                          &nbsp;
                          {saveFailedText && saveFailedText}
                          {!saveFailedText && props.errors.name && props.errors.name}
                          {!saveFailedText && !props.errors.name && props.errors.surname && props.errors.surname}
                          {!saveFailedText && !props.errors.name && !props.errors.surname && props.errors.telephone && props.errors.telephone}
                          {!saveFailedText && !props.errors.name && !props.errors.surname && !props.errors.telephone && props.errors.email && props.errors.email}
                      </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" onClick={goBack} variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton disabled={!termsAccepted} type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Register
                  </MKButton>
                </Grid>
              </Grid>
        </Form>
        )}
        </Formik>
          </Grid>}
        </Grid>}
    </>
  );
}

export default StepperSignIn;