// react native
import { useEffect, useState } from "react";
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment'


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from "@mui/material/Link";

import { styled } from '@mui/material/styles';


// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";


import {getUserDebtCollections} from '../../apis/api';

function MyDebtDashboard(props) {
  const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
  const [userDebtCollections, setUserDebtCollections] = useState(null);
  const [backdropClose, setBackdropClose] = useState(true);
  const [isMobile, setIsMobile] = useState(props.isMobile);

  
  useEffect(() => {

    let cancel = false;
    if(cancel)
      return;
   
    if(sessionInfo && sessionInfo.sub) {
      getUserDebtCollections(sessionInfo.sub)
        .then((data) => {
          setUserDebtCollections(data);
          setBackdropClose(false);
        })
        .catch((err) => {
          setBackdropClose(false);
        });
    }

    return () => { 
      cancel = true;
    }

  }, [sessionInfo]);

  useEffect(() => {
    let cancel = false;
    if(cancel) return;

    setIsMobile(props.isMobile);

    return () => { 
      cancel = true;
    }
  }, [props.isMobile]);

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;
   
    if(props.sessionInfo) {
      setSessionInfo(props.sessionInfo);
    }

    return () => { 
      cancel = true;
    }

  }, [props.sessionInfo]);
  
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  return (
    <>
    <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
              <Breadcrumbs
                routes={[
                  { label: "Profile", route: "/dashboard" },
                  { label: "Dashboard" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
            <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                  <MKTypography variant="h3" color="dark">
                    My Debt Dashboard
                  </MKTypography>
                  <MKTypography variant="body2" color="dark" opacity={0.8}>
                    All the transactions you have done so far...
                  </MKTypography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{"color": "white"}}>
                          Debtor
                        </TableCell>
                        {!isMobile && <><TableCell style={{"color": "white"}}>Date</TableCell>
                        <TableCell style={{"color": "white"}}>Attorney</TableCell></>}
                        <TableCell style={{"color": "white"}}>Status</TableCell>
                        <TableCell style={{"color": "white"}}>Payment Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userDebtCollections && userDebtCollections.debtCollections && userDebtCollections.debtCollections.map((row) => (
                        <StyledTableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            <Link style={{"borderBottom": "1px solid"}} href={`/dashboard/debt-review/applications/${row.id}`}>{row.debtorName}</Link>
                          </TableCell>
                          {!isMobile && <><TableCell>
                            {moment.utc(row.date).local().format(isMobile ? 'DD/MM' : 'DD MMM YYYY HH:mm')}
                          </TableCell>
                          <TableCell>{row.attorney ?? "-"}</TableCell></>}
                          <TableCell>
                            {row.status !== "Complete" && <MKTypography variant="body" color="info">
                              {row.status}
                            </MKTypography>}
                              {row.status === "Complete" && row.status}
                          </TableCell>
                          <TableCell>
                            {row.paymentStatus !== "Paid" && <MKTypography variant="body" color="info">
                              {row.paymentStatus}
                            </MKTypography>}
                              {row.paymentStatus === "Paid" && row.paymentStatus}
                            </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default MyDebtDashboard;