// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";

function TermsAndConditions(props) {
  
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
              <Breadcrumbs
                routes={[
                  { label: "Terms & Conditions" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
            <Grid container justifyContent="center" sx={{ textAlign: "left" }}>
              <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                  <MKBox
                    p={1}
                    width="100%"
                    variant="gradient"
                    bgColor="info">
                  <MKTypography variant="h3" color="white">
                  Overdue(PTY)LTD T/A OVERDUE TERMS AND CONDITIONS OF USE
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKTypography variant="body2" color="dark">
                  <ol>
                      <li>Information contained on the app is of a general nature and is not intended to be specific advice or an exhaustive review of any subject. If we use any calculations on the app these are intended to be general guidelines and should not be taken as absolutes. Should you wish to rely on any calculation you are advised to check the calculations before relying on them. Overdue (Pty) Ltd T/A Overdue will not be liable for any inaccuracy in the calculation.</li>
                      <li>Anyone may use any information presented on this app for non-commercial purposes, subject to any specific terms of use that might appear with such information, provided that the use of such information is accompanied by an acknowledgement that Overdue (Pty) Ltd T/A Overdue is the source. If you choose to access and use any of the information made available to you on this app, you do so subject to these Terms and Conditions of Use and any specific terms that the owner or provider of that information has imposed on its use.</li>
                      <li>Please take note of all Terms and Conditions of Use. Your use of this app implies an acceptance of these Terms and Conditions of Use and any other specific terms and an agreement to be bound by them. In the event of a conflict between the Terms and Conditions of Use of this app and any specific terms applicable, and only to the extent of the conflict, the more specific terms will apply.</li>
                      <li>Overdue (Pty) Ltd T/A Overdue grants you a limited, revocable license to use this app subject to these Terms and Conditions of Use. The license is a personal, non-transferable, non-sublicensable, revocable license to access and use the app only as expressly permitted in these Terms and Conditions of Use. Except for this limited license, we do not grant you any other rights or license with respect to this app.</li>
                      <li>Unless we have granted you permission in advance and in writing, you may use the app only for your personal, non-commercial use, and not to provide services to a third party. Any person wishing to use this app contrary to these Terms and Conditions of Use must obtain prior written consent from Overdue (Pty) Ltd T/A Overdue.</li>
                      <li>Overdue (Pty) Ltd T/A Overdue makes every effort to ensure, but cannot and does not guarantee, and makes no warranties as to, the accuracy, accessibility, integrity and timeliness of this information. Overdue (Pty) Ltd T/A Overdue assumes no liability or responsibility for any errors or omissions in the content of this app and further disclaims any liability of any nature for any loss however arising in connection with using this app or any content contained herein. Furthermore, Overdue (Pty) Ltd T/A Overdue reserves the right to make changes to these materials at any time without notice. If you find any inaccurate, out of date or incomplete information or material on this app, or if you suspect that something is an infringement of intellectual property rights, you must let us know immediately by contacting Overdue (Pty) Ltd T/A Overdue or the owner or provider of the information to which the issue relates.</li>
                      <li>Unless otherwise stated, all right, title, interest, and ownership (including all rights under all copyright, patent, and other intellectual property laws) in, to or of this app are the sole property of or will vest in Overdue (Pty) Ltd T/A Overdue or a third party licensor. All moral rights are reserved.</li>
                      <li>The Overdue (Pty) Ltd T/A Overdue logo and sub-logos, trademarks (whether registered or not), other marks, and trade names are the property of Overdue (Pty) Ltd T/A Overdue and no person may use them without permission. Any other trademark or trade name that may appear on this app or other marketing material of Overdue (Pty) Ltd T/A Overdue is the property of its respective owner.</li>
                      <li>You should independently verify any information and material on this app before relying upon it. The information and material on this app are not substitutes for the exercise of professional judgment.  If you are not qualified or experienced enough to make that judgment, you should take professional advice or contact the relevant information owner or provider for further information and advice.</li>
                      <li>Hyperlinks to other apps or websites are provided as a convenience only, and imply neither responsibility for, nor approval of, the information contained in those other apps or websites on the part of Overdue (Pty) Ltd T/A Overdue. Overdue (Pty) Ltd T/A Overdue makes no warranty, either express or implied, as to the accuracy, availability, reliability or content of such information, text, graphics and hyperlinks. The use of this app is entirely at your own risk and Overdue (Pty) Ltd T/A Overdue will not be liable for any direct loss or indirect or consequential loss, or for any loss of business, profit, revenue, goodwill or data, lost or wasted management time or the lost time of other employees arising from your use of this app or any information or material on it, or your inability to use it (whether that loss is direct or indirect).</li>
                      <li>While Overdue (Pty) Ltd T/A Overdue makes every effort to ensure that any executable material available to be downloaded from the app is free of any virus, it cannot guarantee that the material is free from any or all viruses. Overdue (Pty) Ltd T/A Overdue is not responsible for any loss or damage however caused by the executable material and potentially malicious code contained therein.</li>
                      <li>You are responsible for ensuring that your computer systems are suitable to access and use this app. You are responsible for implementing sufficient anti-virus and other security checks to ensure the accuracy of data input and output.</li>
                      <li>The collection and retention of personal information by Overdue (Pty) Ltd T/A Overdue through its app and through the registration process is subject to the terms and conditions of Overdue (Pty) Ltd T/A Overdue’s Privacy Policy currently in force and available for review on this app.</li>
                      <li>Restrictions on Use of App:
                          <br/>Framing.  No person, business, or app may frame this app or any of the pages on this app, or use any other framing technique to enclose any portion or aspect of the app, or mirror or replicate any portion of the app;
                          <br/>Linking. We prohibit “deep linking” to any other apps or web site in a manner that would incorrectly suggest endorsement or support of Overdue (Pty) Ltd T/A Overdue or suggests you are the owner of any intellectual property belonging to Overdue (Pty) Ltd T/A Overdue. 
                          <br/>Spiders and Crawlers.  No person, business, or web site may use any technology (including spiders or crawlers) to search and gain any information from this app. 
                      </li>
                      <li>You specifically may not: 
                        <br/>Copy, reproduce, upload, post, display, republish, distribute, transmit, any part of the content in any form whatsoever;
                        <br/>Modify, translate into any language or computer language, or create derivative works from, any content or any part of this app;
                        <br/>Reverse engineer any part of this app;
                        <br/>Use the app to make any false, fraudulent or speculative claim;
                        <br/>Disguise the information or origin of the information transmitted through the app;
                        <br/>Sell, offer for sale, transfer, or license any portion of the app in any form to any third parties;
                        <br/>Use or access the app in any way that, in our reasonable judgment, adversely affects the performance or function of the app, or any other computer systems or networks used by Overdue (Pty) Ltd T/A Overdue other app users;
                        <br/>Upload or transmit to the app or use any device, software or routine that contains viruses, Trojan horses, worms, time bombs, or other computer programming routines that may damage, interfere or attempt to interfere with, intercept, the normal operation of our app, or appropriate the app or any system, or take any action that imposes an unreasonable load on our computer equipment, or that infringes upon the rights of a third party; or
                        <br/>Use any device, software, or routine that interferes, or attempts to interfere, with the normal operation of our app, or take any action that impose an unreasonable load on our equipment.
                      </li>
                      <li>All warranties, representations, terms, conditions and undertakings, whether implied by statute, common law, custom, trade usage, course of dealing or otherwise (including any implied warranty, representation, term, condition or undertaking of satisfactory quality or fitness for a particular purpose) are excluded to the fullest extent allowed by law by Overdue (Pty) Ltd T/A Overdue and the information owners and providers.
                      <br/><br/>If the use of any information or material on this app is unlawful in any jurisdiction (because of your nationality, residence or for some other reason), that information or material is not offered.  If you are outside the Republic of South Africa you must satisfy yourself that you are lawfully able to use any such information and materials.  We accept no liability, to the extent allowed by the law, for any costs, losses or damages resulting from, in connection with or related to the access or attempted access of any information or materials by anyone outside the Republic of South Africa.</li>
                      <li>No delay, neglect or forbearance on the part of Overdue (Pty) Ltd T/A Overdue in enforcing any of their rights under these Terms and Conditions of Use will be, or be deemed to be, a waiver and it will not prejudice any right of Overdue (Pty) Ltd T/A Overdue.</li>
                      <li>If any of the Terms and Conditions of Use is held to be unenforceable, illegal or in some other way invalid, the unenforceable, illegal or invalid provision will be deemed to be severable and will not affect the remainder of the Terms and Conditions of Use which will continue to be of full force and effect.</li>
                      <li>All conditions and terms of use contained in this app are governed by South African law and you agree to submit to the exclusive jurisdiction of the courts of South Africa.</li>
                      <li>No addition to or modification of any provision of these Terms and Conditions of Use will be binding on Overdue (Pty) Ltd T/A Overdue unless made in writing and signed by their duly authorised representatives.</li>
                      <li>Overdue (Pty) Ltd T/A Overdue may modify, suspend, or discontinue providing this app (with or without notice) and will not be liable.</li>
                  </ol>
                  </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default TermsAndConditions;