/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FailModal from "../../../components/Modals/FailModal";

import { AccountContext } from '../../../cognito/Account';

import { postUserLegalPolicy } from '../../../apis/legal';

const idNumberExpression = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

const validationSchema = yup.object({
  title: yup.string()
    .required("All fields are required"),
  firstName: yup.string()
    .required("All fields are required"),
  surname: yup.string()
    .required("All fields are required"),
  idNumber: yup.string()
    .matches(idNumberExpression, 'South African Id Number not valid')
    .max(13, 'South African Id Number not valid')
    .min(13, 'South African Id Number not valid')
    .required("All fields are required"),
  telephone: yup.string()
    .matches(phoneRegExp, 'A valid mobile number is required')
    .required("All fields are required"),
  email: yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('All fields are required')
});

const validationSignInPassCodeSchema = yup.object({
  authCode: yup.string().required('Authentication code is required')
});

function Step2(props) {
  const { signUpAndAuthenticate, answerCustomChallenge } = useContext(AccountContext);
  const [backdropClose, setBackdropClose] = useState(false);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [showError, setShowError] = useState(false);
  const [saveFailedText, setSaveFailedText] = useState("An unknown error occurred");
  const [signInUserName, setSignInUserName] = useState(null);
  const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
  const [showPostError, setShowPostError] = useState(false);
  const [postResult, setPostResult] = useState(null);

  const [stepValues, setStepValues] = useState({
    userId: "",
    userEmail: "",
    title: "",
    firstName: "",
    surname: "",
    idNumber: "",
    telephone: "",
    email: ""
});

useEffect(() => {
  let cancel = false;
  if(cancel)
    return;

  setIsMobile(props.isMobile);

  return () => { 
    cancel = true;
  }
}, [props.isMobile]);

useEffect(() => {
  let cancel = false;
    if(cancel)
      return;

  if(props.step2Values) {
    setStepValues(props.step2Values);
  }
  else if(props.sessionInfo) {
    setSessionInfo(props.sessionInfo);
    setStepValues({
        title: "",
        userId: props.sessionInfo?.sub ?? "",
        userEmail: props.sessionInfo?.email ?? "",
        firstName: props.sessionInfo.given_name ?? "",
        surname: props.sessionInfo.family_name ?? "",
        idNumber: props.sessionInfo.locale ?? "",
        telephone: props.sessionInfo.phone_number ?? "",
        email: props.sessionInfo.email ?? ""
    });
  }

  return () => { 
    cancel = true;
  }
}, [props.step2Values, props.sessionInfo])

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;


    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(50);
    props.setCurrentStepName("Personal Info");
    props.setNextStepName("Confirmation");

  return () => { 
          cancel = true;
        }
  }, [props]);

  
  const goBack = (e) => {
    props.setCurrentStep(1);
  }

  return (
    <>
      {postResult && postResult.id && <Navigate to={`/dashboard/legal-cover/personal-legal-cover/quotes/${postResult.id}`} />}
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Tell us about yourself
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Please complete all fields
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          {!signInUserName && <Formik
          enableReinitialize={true}
          initialValues={stepValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            setShowError(false);
            props.setStep2Values(values);

            if(sessionInfo && sessionInfo.sub) {
              setBackdropClose(true);
              setShowPostError(false);

              var postData = {...props.step1Values, ...values};

              postUserLegalPolicy(postData)
                  .then(data => {
                      props.setStep2Values(values);
                      setPostResult(data);
                      setBackdropClose(false);
                  })
                  .catch(err => {
                    setShowPostError(true);
                    setBackdropClose(false);
                  });
            }
            else {
              setBackdropClose(true);
              signUpAndAuthenticate(values.email, values.firstName, values.surname, values.idNumber, values.telephone)
                .then(data => {
                  setSignInUserName(values.email);
                  setSaveFailedText(null);
                  setBackdropClose(false);
              })
              .catch(err => {
                  const msg = err.message ? err.message : "An unknown error occurred";
                  setShowError(true);
                  setSaveFailedText(msg);
                  setSignInUserName(null);
                  setBackdropClose(false);
              });
            }
          }}
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12}>
                  <MKInput 
                    label="Title" 
                    id="title" 
                    value={props.values.title}
                    onChange={props.handleChange('title')}
                    error={props.errors.title && Boolean(props.errors.title)}
                    fullWidth
                    select
                  >
                    <MenuItem key="0" value="Mr">
                      Mr.
                    </MenuItem>
                    <MenuItem key="1" value="Ms">
                      Ms.
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="First Name" 
                    id="firstName" 
                    value={props.values.firstName}
                    onChange={props.handleChange('firstName')}
                    error={props.errors.firstName && Boolean(props.errors.firstName)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Surname" 
                    id="surname" 
                    value={props.values.surname}
                    onChange={props.handleChange('surname')}
                    error={props.errors.surname && Boolean(props.errors.surname)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="ID Number" 
                    id="idNumber" 
                    value={props.values.idNumber}
                    onChange={props.handleChange('idNumber')}
                    error={props.errors.idNumber && Boolean(props.errors.idNumber)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Telephone" 
                    id="telephone" 
                    value={props.values.telephone}
                    onChange={props.handleChange('telephone')}
                    error={props.errors.telephone && Boolean(props.errors.telephone)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Email" 
                    id="email" 
                    value={props.values.email}
                    onChange={props.handleChange('email')}
                    error={props.errors.email && Boolean(props.errors.email)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                    {props.errors.title && props.errors.title}
                    {!props.errors.title && props.errors.firstName && props.errors.firstName}
                    {!props.errors.title && !props.errors.firstName && props.errors.surname && props.errors.surname}
                    {!props.errors.title && !props.errors.firstName && !props.errors.surname && props.errors.idNumber && props.errors.idNumber}
                    {!props.errors.title && !props.errors.firstName && !props.errors.surname && !props.errors.phone_number && props.errors.telephone && props.errors.telephone}
                    {!props.errors.title && !props.errors.firstName && !props.errors.surname && !props.errors.phone_number && !props.errors.telephone && props.errors.email && props.errors.email}
                  </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" onClick={goBack} variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>}
        {signInUserName && <Formik
            initialValues={{
                authCode: ""
            }}
            validationSchema={validationSignInPassCodeSchema}
            onSubmit={values => {
                setBackdropClose(true);

                answerCustomChallenge(signInUserName, values.authCode.trim())
                  .then(data => {
                    const userId = {
                      userId: data.idToken.payload.sub
                    };
                    const userEmail = {
                      userEmail: data.idToken.payload.email
                    };
                    
                    const step2Values = {...stepValues, ...userId, ...userEmail};
                    props.setStep2Values(step2Values);

                    props.refreshSession();
                    setSaveFailedText(null);

                    const postData = {...props.step1Values, ...step2Values};

                    postUserLegalPolicy(postData)
                        .then(data => {
                            setPostResult(data);
                            setBackdropClose(false);
                        })
                        .catch(err => {
                          setShowPostError(true);
                          setBackdropClose(false);
                        });
                  })
                  .catch(err => {
                    // TODO: we can move the user to the register tab automatically
                    // if the code email can't be sent because of no user existing?
                    console.error('error', err);
                    let msg = err.message ? err.message : "An unknown error occurred";
                    if (msg === 'Incorrect username or password.') {
                      msg = 'Incorrect authentication code provided. Please recheck your e-mail containing your authentication code.';
                    }
                    setSaveFailedText(msg);
                    setBackdropClose(false);
                  });
        }}
        >
        {(props) => (
            <Form>
              <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <MKInput 
                        type="text" 
                        label="Authentication code" 
                        id="authCode" 
                        value={props.values.authCode}
                        onChange={props.handleChange('authCode')}
                        error={props.errors.authCode && Boolean(props.errors.authCode)}
                        helperText="An authentication code was emailed to you. Please check your inbox or spam folder." 
                        fullWidth 
                    />
                </Grid>
                <Grid item xs={12}>
                    <MKTypography variant="caption" color="error">
                        &nbsp;
                        {saveFailedText && saveFailedText}
                        {!saveFailedText && props.errors.authCode && props.errors.authCode}
                    </MKTypography>
                </Grid>
                <Grid item xs={12}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="dark" sx={{ height: "100%" }}>
                    Continue
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
        )}
        </Formik>}
        </MKBox>
      </MKBox>
      {showError && <FailModal isMobile={isMobile} show={showError} header="Email Verification" text={saveFailedText} />}
      {showPostError && <FailModal isMobile={isMobile} show={showPostError} header="Data upload" text="We apologise, some data could not be uploaded. Please try again." />}
    </>
  );
}

export default Step2;