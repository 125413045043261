/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "../../../components/Cards/TeamCards/HorizontalTeamCard";

function Team(props) {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color={props && props.invert ? "dark" : "white"}>
              The Team
            </MKTypography>
            <MKTypography variant="body2" color={props && props.invert ? "dark" : "white"} opacity={0.8}>
              Our dedicated team offers industry expertise.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/home/coert.jpg`}
                name="Coert Knoetze"
                position={{ color: "info", label: "Managing Director" }}
                description={`Persistence and "don't waste my time" is synonymous with who I am. I'll ask once and then my action will speedily follow, making sure the team bears your dues at heart. “Thinking right comes naturally, oblivious to objectivity blows me away.”`}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/home/melouise.jpg`}
                name="Melouise Otto"
                position={{ color: "info", label: "Financial Director" }}
                description={`I've been called "Miss Rottenmeimer", you know the one from Heidi and her goatherd boyfriend Peter. I am old school, what is right is right and everything else is wrong. My loyalty finds its roots in supporting the morally correct.`}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
