/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";

import SuccessModal from "../../../components/Modals/SuccessModal";
import FailModal from "../../../components/Modals/FailModal";

import {postOhNo} from '../../../apis/comms';

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

const validationSchema = yup.object({
  name: yup.string()
      .required('Name is required'),
  telephone: yup.string().matches(phoneRegExp, 'A valid land telephone number needs to be captured'),
  email: yup.string().email('Must be a valid email').max(255)
      .when(['telephone'], {                                                    
        is: (telephone) => (!telephone || telephone.length === 0),
        then: yup.string()
        .required('At least one contact detail is required'),                 
                    }),
  message: yup.string()
      .required('Message is required'),
});

function Step2Cancel(props) {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
  const [backdropClose, setBackdropClose] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setCurrentStepName("Oh no!");

    return () => { 
      cancel = true;
    }
  }, [props]);
 

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;

    setSessionInfo(props.sessionInfo);

    return () => { 
      cancel = true;
    }
  }, [props.sessionInfo]);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;

    setIsMobile(props.isMobile);

    return () => { 
      cancel = true;
    }
  }, [props.isMobile]);
  
  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Oh no
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            We are sorry to see you go.  If you prefer to speak to someone, please fill in this form and we will get right back to you
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <Formik
          enableReinitialize={true}
          initialValues={{
              userId: sessionInfo?.sub ?? "",
              name: sessionInfo?.given_name ?? "",
              surname: sessionInfo?.family_name ?? "",
              company: sessionInfo?.nickname ?? "",
              telephone: sessionInfo?.phone_number ?? "",
              email: sessionInfo?.email ?? "",
              message: "",
              step: -props.currentStep
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            setBackdropClose(true);
            setShow(false);
            setShowError(false);
            postOhNo(values)
              .then((data) => {
                setBackdropClose(false);
                setFormSubmitted(true);
                setShow(true);
              })
              .catch((err) => {
                setBackdropClose(false);
                setShowError(true);
              });
          }}
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12}>
                      <MKInput 
                          type="text" 
                          label="Name" 
                          id="name" 
                          value={props.values.name}
                          onChange={props.handleChange('name')}
                          error={props.errors.name && Boolean(props.errors.name)}
                          fullWidth 
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput 
                        type="text" 
                        label="Surname" 
                        id="surname" 
                        value={props.values.surname}
                        onChange={props.handleChange('surname')}
                        error={props.errors.surname && Boolean(props.errors.surname)}
                        fullWidth 
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput 
                        type="text" 
                        label="Company" 
                        id="company" 
                        value={props.values.company}
                        onChange={props.handleChange('company')}
                        error={props.errors.company && Boolean(props.errors.company)}
                        fullWidth 
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput 
                        type="text" 
                        label="Telephone (International Format)"
                        id="telephone" 
                        value={props.values.telephone}
                        onChange={props.handleChange('telephone')}
                        error={props.errors.telephone && Boolean(props.errors.telephone)}
                        fullWidth
                        helperText="Eg: +27821234567"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput 
                        type="text" 
                        label="Email" 
                        id="email" 
                        value={props.values.email}
                        onChange={props.handleChange('email')}
                        error={props.errors.email && Boolean(props.errors.email)}
                        fullWidth 
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput 
                      type="text" 
                      label="Message" 
                      id="message" 
                      value={props.values.message}
                      onChange={props.handleChange('message')}
                      error={props.errors.message && Boolean(props.errors.message)}
                      fullWidth 
                      multiline
                      rows={4}
                    />
                  </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                  {props.errors.name && props.errors.name}
                  {!props.errors.name && props.errors.telephone && props.errors.telephone}
                  {!props.errors.name && !props.errors.telephone && props.errors.email && props.errors.email}
                  {!props.errors.name && !props.errors.telephone  && !props.errors.email && props.errors.message && props.errors.message}
                  </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="link" href="/our-offering/debt-collection" variant="gradient" color="light" sx={{ height: "100%" }}>
                    Close
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  {!formSubmitted && <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>}
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>
        </MKBox>
      </MKBox>
      <SuccessModal isMobile={isMobile} show={show} header="Message sent" text="Thank you for getting in touch. You can expect a response from us." />
      <FailModal isMobile={isMobile} show={showError} header="Message not sent" text="We appreciate you getting in touch, unfortunately we didnt receive your message." />
    </>
  );
}

export default Step2Cancel;