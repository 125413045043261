// react native
import { useState, useContext, useEffect } from "react";
import {  useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from "@mui/material/AppBar";

// @mui icons
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Switch from "@mui/material/Switch";
import GppGoodIcon from '@mui/icons-material/GppGood';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import MKInput from "../../components/MaterialKit/MKInput";
import MKTypography from "../../components/MaterialKit/MKTypography";
import MKButton from "../../components/MaterialKit/MKButton";
import Breadcrumbs from "../../components/Breadcrumbs";
import colors from "../../assets/theme/base/colors";

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import {AccountContext} from '../../cognito/Account';

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

const validationRegisterSchema = yup.object({
    name: yup.string()
    .required('Name is required'),
    surname: yup.string()
      .required('Surname is required'),
    telephone: yup.string().required('Telephone is required').matches(phoneRegExp, 'A valid mobile number in internartional format'),
    email: yup.string().email('Must be a valid email').max(255).required('Email is required')
  });

const validationSignInSchema = yup.object({
    email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
  });

  const validationSignInPassCodeSchema = yup.object({
    authCode: yup.string().required('Login code is required. This code will be emailed to you.')
  });
  
function AuthenticatePage(props) {
    const url = new URL(window.location.href);
    const returnUri = url.searchParams.get('return');
    const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
    const navigate = useNavigate();

    const { authenticate, answerCustomChallenge, signUp, signOut } = useContext(AccountContext);

    const [activeTab, setActiveTab] = useState(0);
    const [activeSubTab, setActiveSubTab] = useState(0);
    const [backdropClose, setBackdropClose] = useState(false);
    const [saveFailedText, setSaveFailedText] = useState(null);
    const [signInUserName, setSignInUserName] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);
    
    const handleChecked = () => setTermsAccepted(!termsAccepted);

    const { info } = colors;
    

    const handleTabType = (event, newValue) => {
        setSaveFailedText(null);
        setActiveTab(newValue);
    }

    useEffect(() => {
        let cancel = false;
          if(cancel)
            return;
       
        setSessionInfo(props.sessionInfo);
    
        return () => { 
          cancel = true;
        }
    
      }, [props.sessionInfo]);

    function getRandomString(length) {
        return window.btoa(Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2))).map((b) => String.fromCharCode(b)).join("")).replace(/[+/]/g, "").substring(0, length);
    }
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Breadcrumbs
                routes={[
                  { label: "Profile", route: "/authenticate" },
                  { label: (sessionInfo ? "Sign Out" : "Sign In") },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          alignItems: 'center', 
          justifyContent:'center'
        }}
      >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <MKBox
                position="relative"
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
            >
                <Grid container sx={{ textAlign: "center" }}>
                    {!sessionInfo && <Grid item xs={12} sx={{ mt: -3 }}>
                        <MKTypography style={{"fontSize": "100px"}}>
                            <AdminPanelSettingsIcon style={{"color": info.main}} />
                        </MKTypography>
                        <MKTypography variant="h2"sx={{ mt: -5 }}>Sign In</MKTypography>
                        <MKTypography variant="body1" color="text" mb={2}>
                            Verify that it is you and enable more services.
                        </MKTypography>
                    </Grid>}
                    {sessionInfo && <Grid item xs={12} sx={{ mt: -3 }}>
                        <MKTypography style={{"fontSize": "90px"}}>
                            <RemoveModeratorIcon style={{"color": info.main}} />
                        </MKTypography>
                        <MKTypography variant="h2"sx={{ mt: -5 }}>Sign Out</MKTypography>
                        <MKTypography variant="body1" color="text" mb={2}>
                            Some services wont be available anymore.
                        </MKTypography>
                    </Grid>}
                </Grid>
                <Divider sx={{ my: 0 }} />
                {sessionInfo && <MKBox p={2}>
                    <Formik
                        initialValues={{
                            name: sessionInfo?.given_name ?? "",
                            surname: sessionInfo?.family_name ?? "",
                            company: sessionInfo?.nickname ?? "",
                            telephone: sessionInfo?.phone_number ?? "",
                            email: sessionInfo?.email ?? ""

                        }}
                        enableReinitialize={true}
                        onSubmit={values => {
                            setBackdropClose(true);

                            signOut(values.email)
                                .then(data => {
                                    props.refreshSession();
                                    navigate(`/${returnUri || ''}`, {replace: true});
                                });

                            setBackdropClose(false);
                    }}
                    >
                {(props) => ( 
                        <Form>
                            <MKBox pt={4} pb={3} px={3}>
                                <MKBox mb={2}>
                                    <MKInput 
                                        type="text" 
                                        label="Name" 
                                        id="name" 
                                        value={props.values.name}
                                        onChange={props.handleChange('name')}
                                        disabled={true}
                                        fullWidth 
                                        />
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput 
                                        type="text" 
                                        label="Surname" 
                                        id="surname" 
                                        value={props.values.surname}
                                        onChange={props.handleChange('surname')}
                                        disabled={true}
                                        fullWidth 
                                    />
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput 
                                        type="text" 
                                        label="Company" 
                                        id="company" 
                                        value={props.values.company}
                                        onChange={props.handleChange('company')}
                                        disabled={true}
                                        fullWidth 
                                    />
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput 
                                        type="text" 
                                        label="Telephone"
                                        id="telephone" 
                                        value={props.values.telephone}
                                        onChange={props.handleChange('telephone')}
                                        disabled={true}
                                        fullWidth
                                    />
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput 
                                        type="text" 
                                        label="Email" 
                                        id="email" 
                                        value={props.values.email}
                                        onChange={props.handleChange('email')}
                                        disabled={true}
                                        fullWidth 
                                    />
                                </MKBox>
                                <Divider sx={{ my: 0 }} />
                                <MKBox mt={4} mb={1}>
                                    <MKButton type="submit" variant="gradient" color="info" fullWidth>
                                        sign out
                                    </MKButton>
                                </MKBox>
                            </MKBox>
                        </Form>
                    )}
                    </Formik>
                </MKBox>}
                {!sessionInfo && <MKBox p={2}>
                    <AppBar position="static" style={{"padding": "5px"}}>
                        <Tabs value={activeTab} onChange={handleTabType} aria-label="Sign In / Register">
                            <Tab icon={<GppGoodIcon />} label="SIGN IN" />
                            <Tab icon={<HealthAndSafetyIcon />} label="REGISTER" />
                        </Tabs>
                    </AppBar>
                    <Divider sx={{ my: 0 }} />
                    {activeTab === 0 && activeSubTab === 0 && <Formik
                                    initialValues={{
                                        email: ""
                                    }}
                                    validationSchema={validationSignInSchema}
                                    onSubmit={values => {
                                        setBackdropClose(true);

                                        authenticate(values.email)
                                            .then(data => {
                                                setActiveSubTab(1);
                                                setSignInUserName(values.email);
                                                setSaveFailedText(null);
                                                setBackdropClose(false);
                                            })
                                            .catch(err => {
                                                const msg = err.message ? err.message : "An unknown error occurred";
                                                setSaveFailedText(msg);
                                                setSignInUserName(null);
                                                setBackdropClose(false);
                                            });

                                }}
                                >
                            {(props) => ( 
                                <Form>
                                    <MKBox pt={4} pb={3} px={3}>
                                        <MKBox mb={2}>
                                            <MKInput 
                                                type="email" 
                                                label="Email" 
                                                id="email" 
                                                value={props.values.email}
                                                onChange={props.handleChange('email')}
                                                error={props.errors.email && Boolean(props.errors.email)}
                                                fullWidth 
                                            />
                                        </MKBox>
                                        <MKBox mb={2}>
                                            <Switch checked={termsAccepted} onChange={handleChecked} />
                                            <MKTypography
                                                variant="button"
                                                fontWeight="regular"
                                                color="text"
                                                ml={-1}
                                                sx={{ cursor: "pointer", userSelect: "none" }}
                                                onClick={handleChecked}
                                            >
                                                &nbsp;&nbsp;I accept the&nbsp;
                                            </MKTypography>
                                            <MKTypography
                                                component="a"
                                                href="/terms-and-conditions"
                                                target="_blank"
                                                variant="button"
                                                fontWeight="regular"
                                                color="dark"
                                            >
                                                Terms and Conditions
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox mb={2}>
                                            <MKTypography variant="caption" color="error">
                                                &nbsp;
                                                {saveFailedText && saveFailedText}
                                                {!saveFailedText && props.errors.email && props.errors.email}
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox mt={4} mb={1}>
                                            <MKButton disabled={!termsAccepted} type="submit" variant="gradient" color="info" fullWidth>
                                                sign in
                                            </MKButton>
                                        </MKBox>
                                    </MKBox>
                                </Form>
                    )}
                    </Formik>}
                    {activeTab === 0 && activeSubTab === 1 && <Formik
                            initialValues={{
                                authCode: ""
                            }}
                            validationSchema={validationSignInPassCodeSchema}
                            onSubmit={values => {
                            setBackdropClose(true);

                            answerCustomChallenge(signInUserName, values.authCode.trim())
                                .then(data => {
                                setActiveSubTab(0);
                                props.refreshSession();
                                setSaveFailedText(null);
                                navigate(`/${returnUri || ''}`, {replace: true});
                                setBackdropClose(false);
                                })
                                .catch(err => {
                                // TODO: we can move the user to the register tab automatically
                                // if the code email can't be sent because of no user existing?
                                console.error('error', err);
                                let msg = err.message ? err.message : "An unknown error occurred";
                                if (msg === 'Incorrect username or password.') {
                                    msg = 'Incorrect authentication code provided. Please recheck your e-mail containing your authentication code.';
                                }
                                setSaveFailedText(msg);
                                setBackdropClose(false);
                                });
                        }}
                        >
                    {(props) => (
                        <MKBox pt={4} pb={3} px={3}>
                            <Form>
                                <MKBox mb={2}>
                                    <MKInput 
                                        type="text" 
                                        label="Authentication code" 
                                        id="authCode" 
                                        value={props.values.authCode}
                                        onChange={props.handleChange('authCode')}
                                        error={props.errors.authCode && Boolean(props.errors.authCode)}
                                        helperText="An authentication code was emailed to you. Please check your inbox or spam folder." 
                                        fullWidth 
                                    />
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKTypography variant="caption" color="error">
                                        &nbsp;
                                        {saveFailedText && saveFailedText}
                                        {!saveFailedText && props.errors.authCode && props.errors.authCode}
                                    </MKTypography>
                                </MKBox>
                                <MKBox mt={4} mb={1}>
                                    <MKButton type="submit" variant="gradient" color="info" fullWidth>
                                        continue
                                    </MKButton>
                                </MKBox>
                            </Form>
                        </MKBox>
                    )}
                    </Formik>}
                    {activeTab === 1 && <Formik
                            initialValues={{
                                name: '',
                                surname: '',
                                company: '',
                                telephone: '',
                                email: ''
                            }}
                            validationSchema={validationRegisterSchema}
                            onSubmit={values => {
                                setBackdropClose(true);

                                const newPassword = getRandomString(30);

                                signUp(values.email, newPassword, values.name, values.surname, values.company, values.telephone)
                                    .then(data => {
                                        authenticate(values.email)
                                        .then(data => {
                                            setActiveSubTab(1);
                                            setSaveFailedText(null);
                                            setActiveTab(0);
                                            setSignInUserName(values.email);
                                            setBackdropClose(false);
                                        })
                                        .catch(err => {
                                            const msg = err.message ? err.message : "An unknown error occurred";
                                            setSaveFailedText(msg);
                                            setSignInUserName(null);
                                            setBackdropClose(false);
                                        });
                                    })
                                    .catch(err => {
                                        const msg = err.message ? err.message : "An unknown error occurred";
                                        setSaveFailedText(msg);
                                        setSignInUserName(null);
                                        setBackdropClose(false);
                                    });
                        }}
                        >
                    {(props) => (
                        <Form>
                            <MKBox pt={4} pb={3} px={3}>
                                <MKBox role="form">
                                    <MKBox mb={2}>
                                        <MKInput 
                                            type="text" 
                                            label="Name" 
                                            id="name" 
                                            value={props.values.name}
                                            onChange={props.handleChange('name')}
                                            error={props.errors.name && Boolean(props.errors.name)}
                                            fullWidth 
                                        />
                                    </MKBox>
                                    <MKBox mb={2}>
                                        <MKInput 
                                            type="text" 
                                            label="Surname" 
                                            id="surname" 
                                            value={props.values.surname}
                                            onChange={props.handleChange('surname')}
                                            error={props.errors.surname && Boolean(props.errors.surname)}
                                            fullWidth 
                                        />
                                    </MKBox>
                                    <MKBox mb={2}>
                                        <MKInput 
                                            type="text" 
                                            label="Company" 
                                            id="company" 
                                            value={props.values.company}
                                            onChange={props.handleChange('company')}
                                            error={props.errors.company && Boolean(props.errors.company)}
                                            fullWidth 
                                        />
                                    </MKBox>
                                    <MKBox mb={2}>
                                        <MKInput 
                                            type="text" 
                                            label="Telephone"
                                            id="telephone" 
                                            value={props.values.telephone}
                                            onChange={props.handleChange('telephone')}
                                            error={props.errors.telephone && Boolean(props.errors.telephone)}
                                            fullWidth
                                        />
                                    </MKBox>
                                    <MKBox mb={2}>
                                        <MKInput 
                                            type="text" 
                                            label="Email" 
                                            id="email" 
                                            value={props.values.email}
                                            onChange={props.handleChange('email')}
                                            error={props.errors.email && Boolean(props.errors.email)}
                                            fullWidth 
                                        />
                                    </MKBox>
                                    <MKBox mb={2}>
                                        <Switch checked={termsAccepted} onChange={handleChecked} />
                                        <MKTypography
                                            variant="button"
                                            fontWeight="regular"
                                            color="text"
                                            ml={-1}
                                            sx={{ cursor: "pointer", userSelect: "none" }}
                                            onClick={handleChecked}
                                        >
                                            &nbsp;&nbsp;I accept the&nbsp;
                                        </MKTypography>
                                        <MKTypography
                                            component="a"
                                            href="/terms-and-conditions"
                                            target="_blank"
                                            variant="button"
                                            fontWeight="regular"
                                            color="dark"
                                        >
                                            Terms and Conditions
                                        </MKTypography>
                                    </MKBox>
                                    <MKBox mb={2}>
                                        <MKTypography variant="caption" color="error">
                                            &nbsp;
                                            {saveFailedText && saveFailedText}
                                            {!saveFailedText && props.errors.name && props.errors.name}
                                            {!saveFailedText && !props.errors.name && props.errors.surname && props.errors.surname}
                                            {!saveFailedText && !props.errors.name && !props.errors.surname && props.errors.telephone && props.errors.telephone}
                                            {!saveFailedText && !props.errors.name && !props.errors.surname && !props.errors.telephone && props.errors.email && props.errors.email}
                                        </MKTypography>
                                    </MKBox>
                                    <MKBox mt={4} mb={1}>
                                        <MKButton disabled={!termsAccepted} type="submit" variant="gradient" color="info" fullWidth>
                                            register
                                        </MKButton>
                                    </MKBox>
                                </MKBox>
                            </MKBox>
                        </Form>
                    )}
                    </Formik>}
                </MKBox>}
            </MKBox>
      </Card>
    </>
  );
}

export default AuthenticatePage;