// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import HorizontalPageCard from "../../components/Cards/PageCards/HorizontalPageCard";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";
import Icon from "@mui/material/Icon";
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

function WhyOverdue(props) {

  const whyOverdue = [
    "Are you a business owner, tired of issuing overdue invoices?",
    "Is your tolerance level just through the roof because of bad debt?",
    "Are you taken aback by the audacity of not being paid for services rendered?",
    "",
    "Entrepreneurs, sole proprietors and SMME business owners cannot afford not being paid, how about we place that debtor on terms right now?",
    "OVERDUE can empower you in real time with a process of collecting your outstanding debts. With our automated process, OVERDUE will send your debtor a Letter of Demand 24/7/365. Swift action by OVERDUE is guaranteed, collecting what is due to you.",
    "",
    "Put your trust in OVERDUE. A team of qualified legal and expert debt collectors who are eager to alleviate your frustration. Together with our legal experts, we focus on supporting you in overcoming any difficulties of taking the next legal step, against your debtors."
  
  ];
  
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
              <Breadcrumbs
                routes={[
                  { label: "Our Offering", route: "/our-offering/why-overdue" },
                  { label: "Why Overdue" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
          <MKBox component="section" py={{ xs: 0, lg: 6 }}>
              <Container>
                  <Grid container item>
                    <HorizontalPageCard
                      image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/our-offering/why-overdue/background.jpg`}
                      name="Why Overdue?"
                      position={{ color: "info", label: "" }}
                      paragraphs={whyOverdue}
                    />
                  </Grid>
              </Container>
          </MKBox>
          <br/>
          <MKBox 
            component="section" 
            pt={3} 
            pb={3}
            bgColor="dark"
            variant="gradient"
          >
          <Container>
            <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                  <MKTypography variant="h3" color="white">
                    Overdue Benefits
                  </MKTypography>
              </Grid>
              <Grid item xs={12} md={3}>
                  <MKTypography variant="h4" color="white">
                    Affordable
                  </MKTypography>
                  <MKTypography style={{"fontSize": "150px"}}>
                    <Icon color="info">savings</Icon>
                  </MKTypography>
              </Grid>
              <Grid item xs={12} md={3}>
                  <MKTypography variant="h4" color="white">
                    Comprehensive
                  </MKTypography>
                  <MKTypography style={{"fontSize": "150px"}}>
                    <AllInclusiveIcon color="info"/>
                  </MKTypography>
              </Grid>
              <Grid item xs={12} md={3}>
                  <MKTypography variant="h4" color="white">
                    Flexible
                  </MKTypography>
                  <MKTypography style={{"fontSize": "150px"}}>
                    <SelfImprovementIcon color="info" />
                  </MKTypography>
              </Grid>
              <Grid item xs={12} md={3}>
                  <MKTypography variant="h4" color="white">
                    1 Stop Solution
                  </MKTypography>
                  <MKTypography style={{"fontSize": "150px"}}>
                    <EmojiObjectsIcon color="info" />
                  </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <br/>
      </Card>
    </>
  );
}

export default WhyOverdue;