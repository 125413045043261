/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKButton from "../../../components/MaterialKit/MKButton";

import NewsTicker from "../../../components/NewsTicker";

//import { getNewsFeeds } from '../../../apis/feeds';

function News(props) {
  const [newsFeed, setNewsFeed] = useState(null);

  useEffect(() => {
    /*let cancel = false;
     if(cancel)
       return;
 
   
       getNewsFeeds()
       .then((data) => {
         setNewsFeed(data);
       })
       .catch((err) => {
         
       });
   
 
   return () => { 
           cancel = true;*/
    const headlines = [
      {
        title: "Your Essential Guide to One of the Safest Investment Havens: Property with Protection"
      },
      {
        title: "Mastering Property Investment and Real Estate Tenancy in South Africa"
      },
      {
        title: "By Understanding and Leveraging the Following Insurances and Legal Protections, You can Navigate the Complexities of the South African Property Market"
      },
      {
        title: "Protecting Your Investment: A Guide to Rental Insurance and Property Investment"
      },
      {
        title: "We Ensure Everyone Has Access To Justice"
      },
      {
        title: "Property Investment In South Africa Doesn't Have To Be A Solo Journey"
      },
      {
        title: "A Complete Guide to Rental Insurance and Property Investment in South Africa"
      },
      {
        title: "Navigating the World of Rental Insurance and Landlord Rights in South Africa"
      }
    ];
    setNewsFeed(headlines);
  }, [props.maxArticles]);

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color={props && props.invert ? "dark" : "white"}>
              Team Blog
            </MKTypography>
            <MKTypography variant="body2" color={props && props.invert ? "dark" : "white"} opacity={0.8}>
              Stay Informed and Empowered with Personal and Business Coverage, Debt Collection Expertise, and More!
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MKBox mb={1}>
              {newsFeed && <NewsTicker headlines={newsFeed} speed={20} />}
            </MKBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <MKButton
              component={MuiLink}
              href="/team-blog"
              rel="noreferrer"
              variant="gradient"
              size="small"
              color="info"
            >
              read more
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default News;
