/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";


import YoutubeEmbed from "../../../components/YoutubeEmbed";

function Videos(props) {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor={props && props.invert ? "white" : "dark"}
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color={props && props.invert ? "dark" : "white"}>
             We are on your side
            </MKTypography>
            <MKTypography variant="body2" color={props && props.invert ? "dark" : "white"} opacity={0.8}>
              Don't wait until it is too late!
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
                <YoutubeEmbed embedId="JGapMLIkn7M" />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
                <YoutubeEmbed embedId="IW9fh6GX9BU" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Videos;
