/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../MaterialKit/MKBox";
import MKTypography from "../../../MaterialKit/MKTypography";


function HorizontalPageCard({ image, name, position, flipImage, bullets, bullets2, paragraphs }) {
  let randKey = 0;
  return (
    
      <Grid container>
        {!flipImage && <Grid item xs={12} md={6} lg={6} sx={{ mt: 0 }}>
          <MKBox width="100%" pt={2} pb={1} px={bullets ? 3 : 1}>
            <MKBox
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="md"
              shadow="lg"
            />
          </MKBox>
        </Grid>}
        <Grid item xs={12} md={6} lg={6} sx={{ mt: -1 }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MKTypography variant="h5">{name}</MKTypography>
            <MKTypography variant="h6" color={position.color} mb={1}>
              {position.label}
            </MKTypography>
            {bullets && <Grid container columnSpacing={3}>
              <Grid item xs={12} lg={6}>
                  <ul>
                  {bullets && bullets.map((bullet) => (
                      <MKTypography variant="body2" color="text" key={++randKey}><li>{bullet}</li></MKTypography>
                  ))}
                  </ul>
              </Grid>
              {bullets2 && <Grid item xs={12} lg={6}>
                  <ul>
                  {bullets2 && bullets2.map((bullet) => (
                      <MKTypography variant="body2" color="text" key={++randKey}><li>{bullet}</li></MKTypography>
                  ))}
                  </ul>
              </Grid>}
            </Grid>}
            {paragraphs && paragraphs.map((desc) => (
                <MKTypography variant="body2" color="text" key={++randKey}>{desc}{desc === '' ? <span>&nbsp;</span> : ''}</MKTypography>
            ))}
          </MKBox>
        </Grid>
        {flipImage && <Grid item xs={12} md={6} lg={6} sx={{ mt: 0 }}>
          <MKBox width="100%" pt={2} pb={1} px={1}>
            <MKBox
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="md"
              shadow="lg"
            />
          </MKBox>
        </Grid>}
      </Grid>

  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalPageCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string)
};

export default HorizontalPageCard;
