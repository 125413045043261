/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";

function Partners() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container spacing={3} sx={{ textAlign: "center", alignItems: "center" }}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
              <MKTypography variant="h3" color="dark">
                Partners
              </MKTypography>
              <MKTypography variant="body2" color="dark" opacity={0.8}>
                The following companies form part of our microcosm, thus increasing the wealth of expertise.
              </MKTypography>
          </Grid>
          <Grid item xs={6} md={3}>
            <MKTypography
              component="a"
              href="https://www.santam.co.za/"
              target="_blank"
              >
              <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/home/logos/santam.jpeg`} alt="santam" opacity={0.5} width="100%" />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={3}>
            <MKTypography
              component="a"
              href="https://truckplant.com/"
              target="_blank"
              >
              <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/home/logos/truck-plant.jpg`} alt="truckplant" opacity={0.5} width="100%" />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={3}>
            <MKTypography
              component="a"
              href="https://insurancebroker.co.za/"
              target="_blank"
              >
              <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/home/logos/staysure.png`} alt="staysure" opacity={0.5} width="100%" />
            </MKTypography>
          </Grid>
          <Grid item xs={6} md={3}>
            <MKTypography
              component="a"
              href="https://www.fia.org.za/"
              target="_blank"
              >
              <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/home/logos/fia.jpeg`} alt="fia" maxWidth="70%" opacity={0.5} width="100%" />
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Partners;
