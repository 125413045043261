/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import { ListSubheader } from '@mui/material';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FailModal from "../../../components/Modals/FailModal";

import {getCollectors} from '../../../apis/collectors';

const validationSchema = yup.object({
  attorneyId: yup.string()
    .required("All fields are required")
});

function Step3(props) {
  const [stepValues, setStepValues] = useState({
    attorneyId: ""
});
const [backdropClose, setBackdropClose] = useState(true);
const [showError, setShowError] = useState(false);
const [isMobile, setIsMobile] = useState(props.isMobile);
const [collectors, setCollectors] = useState(null);

useEffect(() => {
  let cancel = false;
    if(cancel)
      return;

  if(props.step3Values) {
    setStepValues(props.step3Values);
  }

  return () => { 
    cancel = true;
  }
}, [props.step3Values])

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;


    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(50);
    props.setCurrentStepName("Choose Attorney");
    props.setNextStepName("Confirmation");

    getCollectors()
        .then((data) => {
          setCollectors(data);
          setBackdropClose(false);
        })
        .catch((err) => {
          setShowError(true);
          setBackdropClose(false);
        });

  return () => { 
          cancel = true;
        }
  }, [props]);


  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;

    setIsMobile(props.isMobile);

    return () => { 
      cancel = true;
    }
  }, [props.isMobile]);
  
  const goBack = (e) => {
    props.setCurrentStep(2);
  }

  function getCollectorItems(data) {
    const items = [];
    let id = 0;
    for (let region of data.regions) {
      items.push(<ListSubheader key={id} >{region.name}</ListSubheader>);
      for (let collector of region.collectors) {
        items.push(
          <MenuItem key={collector.id} value={collector.id}>
            {collector.name} {collector.surname}
          </MenuItem>
        );
      }
      id++;
    }
    return items;
  };
  
  return (
    <>
    <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Time to choose an Attorney
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            &nbsp;
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <Formik
            enableReinitialize={true}
            initialValues={stepValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            props.setStep3Values(values);
            props.setCurrentStep(4);
          }}
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                  {collectors && <MKInput 
                    label="Please choose your Attorney?" 
                    id="attorneyId" 
                    value={props.values.attorneyId}
                    onChange={props.handleChange('attorneyId')}
                    error={props.errors.attorneyId && Boolean(props.errors.attorneyId)}
                    fullWidth
                    select
                  > 
                    {getCollectorItems(collectors)}
                  </MKInput>}
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                    {props.errors.attorneyId && props.errors.attorneyId}
                  </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" onClick={goBack} variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>
        </MKBox>
      </MKBox>
      <FailModal isMobile={isMobile} show={showError} header="Data download" text="We apologise, some data could not be downloaded. Please reload." />
    </>
  );
}

export default Step3;