/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import * as crypto from "crypto-js";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKButton from "../../../components/MaterialKit/MKButton";

import payfastLogo from "../../../assets/images/logos/logo-payfast.svg";

function Step5(props) {
  
  const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
  const [debtCollectId, setDebtCollectId] = useState(props.debtCollectId);

  const [payfastValues, setPayValues] = useState({
    merchant_id: process.env.REACT_APP_Payfast_Merchant_Id,
    merchant_key: process.env.REACT_APP_Payfast_Merchant_Key,
    return_url: process.env.REACT_APP_App_Root + "/dashboard/debt-review/applications/" +  debtCollectId ?? "",
    cancel_url: process.env.REACT_APP_App_Root + "/dashboard",
    notify_url: process.env.REACT_APP_Payments_Base_Url + "/api/notification",
    name_first: sessionInfo ? sessionInfo.given_name : "",
    name_last: sessionInfo ? sessionInfo.family_name : "",
    email_address: sessionInfo ? sessionInfo.email : "",
    //cell_number: sessionInfo ? sessionInfo.phone_number : "",
    m_payment_id: debtCollectId ?? "",
    amount: "402.50",
    item_name: "Overdue Fee",
    item_description: "Overdue Fee",
    custom_int1: "2",
    custom_str1: "Extra order information",
    email_confirmation: "1",
    confirmation_address: sessionInfo ? sessionInfo.email : ""
  });

  useEffect(()=>{

    let cancel = false;
      if(cancel)
        return;


    if(!payfastValues?.signature) {

      let hashData = [];
      Object.entries(payfastValues).forEach(res=>{
        hashData.push({
          key: res[0],
          value: res[1]
        });
      });
      
      let hashKey = generateMD5(hashData);
      setPayValues({...payfastValues,signature:hashKey});
    }

    return () => { 
      cancel = true;
    }
   
  },[payfastValues])

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;

    if(props.debtCollectId) {
      setDebtCollectId(props.debtCollectId);
    }

    return () => { 
      cancel = true;
    }
  }, [props.debtCollectId])

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;

    if(props.sessionInfo) {
      setSessionInfo(props.sessionInfo);
    }

    return () => { 
      cancel = true;
    }
  }, [props.sessionInfo])

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(75);
    props.setCurrentStepName("Invoice");
    props.setNextStepName("Secure Payment powered by PayFast");

    return () => { 
      cancel = true;
    }
  }, [props]);


  const generateMD5 = (data, passphrase = null) => {
    let hash = '';
    
    data.forEach(res => {
      hash += `${res.key}=${encodeURIComponent(res.value.trim()).replace(/%20/g, "+")}&`;
    });
    
    let cleanString = hash.substring(0,hash.length-1).trim();
   
    if(passphrase) {
      cleanString += `passphrase=${encodeURIComponent(passphrase.trim()).replace(/%20/g, "+")}&`;
    }
  
    return crypto.MD5(cleanString).toString(crypto.enc.Hex);
  }

  const payfastPaymnetFormView =(data)=> {
    let inputs=[];
    let count=0;
    Object.entries(data).forEach(res=> {
          let key=res[0];
          let val=res[1];
          inputs.push(<input key={count++} type="hidden" name={key} value={val}/>)
    });
    return  inputs;
  }

  return (
    <>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
            <MKTypography variant="h2" mb={1}>
              Invoice
            </MKTypography>
            <Grid container spacing={0}>
              <Grid item xs={12} md={10}>
                <MKTypography variant="body1" color="text" mb={2}>You will be redirected to <em>PayFast</em>, a secure payment facility</MKTypography>
              </Grid>
              <Grid item xs={12} md={2} sx={{ textAlign: "right" }}>
                <MKBox component="img" src={payfastLogo} alt="Powered by Payfast" width="140px" opacity={0.8} />
              </Grid>
            </Grid>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <form action={`${process.env.REACT_APP_Payfast_Base}/eng/process`} method="post">
            {payfastPaymnetFormView(payfastValues)}
            <Grid container spacing={1} alignItems="right" sx={{ textAlign: "right" }}>
              <Grid item xs={6} sx={{ textAlign: "left" }}>
                <MKTypography variant="body2" color="text">
                  OVERDUE fee
                </MKTypography>
              </Grid>
              <Grid item xs={6}>
                <MKTypography variant="body2" color="text">
                  R 350.00
                </MKTypography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "left" }}>
                <MKTypography variant="body2" color="text">
                  VAT @15%
                </MKTypography>
              </Grid>
              <Grid item xs={6}>
                <MKTypography variant="body2" color="text">
                  R 52.50
                </MKTypography>
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "left" }}>
                <MKTypography variant="h4" color="text">
                  Total
                </MKTypography>
              </Grid>
              <Grid item xs={6}>
                <MKTypography fontWeight="bold" variant="h4" color="text">
                  R 402.50
                </MKTypography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                  Make Payment
                </MKButton>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <MKTypography variant="body2" color="text">
                  You can pay using the following payment methods:
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
                <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/step5/instant-eft.png`} alt="Instant EFT" width="70px" opacity={0.8} />
              </Grid>
              <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
                <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/step5/maestro-visa-electron.png`} alt="Maestro / Visa Electron" width="70px" opacity={0.8} />
              </Grid>
              <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
                <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/step5/mobicred.png`} alt="mobicred" width="70px" opacity={0.8} />
              </Grid>
              <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
                <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/step5/s-code.png`} alt="SCode" width="70px" opacity={0.8} />
              </Grid>
              <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
                <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/step5/masterpass.png`} alt="masterpass" width="50px" opacity={0.8} />
              </Grid>
              <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
                <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/step5/snapscan.png`} alt="SnapScan" width="90px" opacity={0.8} />
              </Grid>
              <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
                <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/step5/tyme-bank.png`} alt="MoreTyme" width="70px" opacity={0.8} />
              </Grid>
              <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
                <MKBox component="img" src={`${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/step5/visa.png`} alt="Visa" width="70px" opacity={0.8} />
              </Grid>
            </Grid>
          </form>
        </MKBox>
      </MKBox>
    </>
  );
}

export default Step5;