/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FailModal from "../../../components/Modals/FailModal";

import { postCommercialQuote } from '../../../apis/legal';


const validationSchema = yup.object({
  coverId: yup.string()
    .required("All fields are required"),
  existingMatters: yup.string()
    .required("All fields are required")
});

function Step4(props) {
  let { coverId } = useParams();
  const [stepValues, setStepValues] = useState({
    coverId: "",
    existingMatters: "",
    incidents: "",
  
});
const [backdropClose, setBackdropClose] = useState(false);
const [showPostError, setShowPostError] = useState(false);
const [isMobile, setIsMobile] = useState(props.isMobile);
const [postResult, setPostResult] = useState(null);

useEffect(() => {
  let cancel = false;
    if(cancel)
      return;

  if(props.step3Values) {
    setStepValues(props.step3Values);
  }
  else if(coverId) {
    setStepValues({
      coverId: coverId,
      existingMatters: "",
      incidents: "",
    });

  }

  return () => { 
    cancel = true;
  }
}, [props.step3Values, coverId])

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;


    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(70);
    props.setCurrentStepName("Insurance Information");
    props.setNextStepName("Confirmation");

  return () => { 
    cancel = true;
  }
  }, [props]);


  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;

    setIsMobile(props.isMobile);

    return () => { 
      cancel = true;
    }
  }, [props.isMobile]);
  
  const goBack = (e) => {
    props.setCurrentStep(2);
  }


  return (
    <>
      {postResult && postResult.id && <Navigate to={`/dashboard/legal-cover/commercial-legal-cover/quotes/${postResult.id}`} />}
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Tell us about your insurance
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <Formik
            enableReinitialize={true}
            initialValues={stepValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              setBackdropClose(true);
              setShowPostError(false);

              var postData = {...props.step1Values, ...props.step2Values, ...values};

              postCommercialQuote(postData)
                  .then(data => {
                      props.setStep3Values(values);
                      setPostResult(data);
                      setBackdropClose(false);
                  })
                  .catch(err => {
                    setShowPostError(true);
                    setBackdropClose(false);
                  });
            }}
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12}>
                  <MKInput 
                    label="Cover limit" 
                    id="coverId" 
                    value={props.values.coverId}
                    onChange={props.handleChange('coverId')}
                    error={props.errors.coverId && Boolean(props.errors.coverId)}
                    fullWidth
                    select
                  >
                    <MenuItem key={1} value="a">
                      Plan A - Limit up to R150 000
                    </MenuItem>
                    <MenuItem key={2} value="b">
                      Plan B - Limit up to R250 000
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Any pre-existing matters?" 
                    id="existingMatters" 
                    value={props.values.existingMatters}
                    onChange={props.handleChange('existingMatters')}
                    error={props.errors.existingMatters && Boolean(props.errors.existingMatters)}
                    fullWidth
                    select
                    >
                      <MenuItem key={1} value="No">
                        No
                      </MenuItem>
                      <MenuItem key={2} value="Yes">
                        Yes
                      </MenuItem>
                    </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                      type="text" 
                      label="Claims / Incidents in past 5 years?" 
                      id="incidents" 
                      value={props.values.incidents}
                      onChange={props.handleChange('incidents')}
                      error={props.errors.incidents && Boolean(props.errors.incidents)}
                      fullWidth 
                      multiline
                      rows={6}
                    />
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                    {props.errors.bankId && props.errors.bankId}
                    {!props.errors.bankId && props.errors.accountType && props.errors.accountType}
                    {!props.errors.bankId && !props.errors.accountType && props.errors.accountName && props.errors.accountName}
                    {!props.errors.bankId && !props.errors.accountType && !props.errors.accountName && props.errors.accountNumber && props.errors.accountNumber}
                    {!props.errors.bankId && !props.errors.accountType && !props.errors.accountName && !props.errors.accountNumber && props.errors.branchCode && props.errors.branchCode}
                    {!props.errors.bankId && !props.errors.accountType && !props.errors.accountName && !props.errors.accountNumber && !props.errors.branchCode && props.errors.collectionDay && props.errors.collectionDay}
                  </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" onClick={goBack} variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>
        </MKBox>
      </MKBox>
      <FailModal isMobile={isMobile} show={showPostError} header="Data upload" text="We apologise, some data could not be uploaded. Please try again." />
    </>
  );
}

export default Step4;