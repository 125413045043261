/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";

const validationSchema = yup.object({
  whoOwesYou: yup.string()
    .required("All fields are required"),
  debtorName: yup.string()
    .required("All fields are required"),
  debtorEmail: yup.string()
    .email('A valid email is required').max(255)
    .required("All fields are required"),
  debtOutstandingAmount: yup.number().typeError('A valid amount is required').min(1000, "An amount larger than 1000 is required")
    .required("All fields are required"),
  howLognHasDebtOutstanding: yup.string()
    .required("All fields are required"),
  whatDescribesYourDebt: yup.string()
    .required("All fields are required"),
});

function Step1(props) {
  const [whoOwesYouQuestion, setWhoOwesYouQuestion] = useState("Individual's name and surname");
  const [stepValues, setStepValues] = useState({
    whoOwesYou: "",
    debtorName: "",
    debtorEmail: "",
    howLognHasDebtOutstanding: "",
    whatDescribesYourDebt: "",
    debtOutstandingAmount: ""

});

useEffect(() => {
  let cancel = false;
  if(cancel)
    return;
  if(props.step1Values) {
    setStepValues(props.step1Values);
  }

  return () => { 
    cancel = true;
  }
}, [props.step1Values])

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
      
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(0);
    props.setCurrentStepName("Debt Information");
    props.setNextStepName("Letter of Demand");

    return () => { 
      cancel = true;
    }
  }, [props]);
  
  
  return (
    <>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Debt Information
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Tell us about your debt
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <Formik
            enableReinitialize={true}
            initialValues={stepValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            props.setStep1Values(values);
            props.setCurrentStep(2);
          }}
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12}>
                <MKInput 
                  label="Who owes you?" 
                  id="whoOwesYou" 
                  value={props.values.whoOwesYou}
                  onChange={e => { props.handleChange('whoOwesYou')(e.target.value); setWhoOwesYouQuestion(e.target.value === "Company" ? "Company's name" : "Individual's name and surname")}}
                  error={props.errors.whoOwesYou && Boolean(props.errors.whoOwesYou)}
                  fullWidth
                  select
                >
                    <MenuItem key={0} value="Company">
                      Company
                    </MenuItem>
                    <MenuItem key={1} value="Individual">
                      Individual
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    type="text" 
                    label={whoOwesYouQuestion}
                    id="debtorName" 
                    value={props.values.debtorName}
                    onChange={props.handleChange('debtorName')}
                    error={props.errors.debtorName && Boolean(props.errors.debtorName)}
                    fullWidth 
                  />
                </Grid>
                <Grid item xs={12}>
                <MKInput 
                    type="email" 
                    label="Debtors email address" 
                    id="debtorEmail" 
                    value={props.values.debtorEmail}
                    onChange={props.handleChange('debtorEmail')}
                    error={props.errors.debtorEmail && Boolean(props.errors.debtorEmail)}
                    fullWidth 
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="How much is outstanding?" 
                    id="debtOutstandingAmount" 
                    value={props.values.debtOutstandingAmount}
                    onChange={props.handleChange('debtOutstandingAmount')}
                    error={props.errors.debtOutstandingAmount && Boolean(props.errors.debtOutstandingAmount)}
                    fullWidth
                  >
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                 <MKInput 
                    label="How long has the debt been outstanding?" 
                    id="howLognHasDebtOutstanding" 
                    value={props.values.howLognHasDebtOutstanding}
                    onChange={props.handleChange('howLognHasDebtOutstanding')}
                    error={props.errors.howLognHasDebtOutstanding && Boolean(props.errors.howLognHasDebtOutstanding)}
                    fullWidth
                    select
                  >
                    <MenuItem key={0} value="1 - 3 months">
                      1 - 3 months
                    </MenuItem>
                    <MenuItem key={1} value="3 - 6 months">
                      3 - 6 months
                    </MenuItem>
                    <MenuItem key={2} value="6 - 12 months">
                      6 - 12 months
                    </MenuItem>
                    <MenuItem key={3} value="More than 12 months">
                      More than 12 months
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="What describes your debt" 
                    id="whatDescribesYourDebt" 
                    value={props.values.whatDescribesYourDebt}
                    onChange={props.handleChange('whatDescribesYourDebt')}
                    error={props.errors.whatDescribesYourDebt && Boolean(props.errors.whatDescribesYourDebt)}
                    fullWidth
                    select
                  >
                    <MenuItem key={0} value="Commercial dispute">
                      Commercial dispute
                    </MenuItem>
                    <MenuItem key={1} value="Private dispute">
                      Private dispute
                    </MenuItem>
                    <MenuItem key={2} value="Unpaid invoice">
                      Unpaid invoice
                    </MenuItem>
                    <MenuItem key={3} value="Unpaid rent">
                      Unpaid rent
                    </MenuItem>
                    <MenuItem key={4} value="Unpaid loan">
                      Unpaid loan
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                  {props.errors.whoOwesYou && props.errors.whoOwesYou}
                  {!props.errors.whoOwesYou && props.errors.debtorName && props.errors.debtorName}
                  {!props.errors.whoOwesYou && !props.errors.debtorName && props.errors.debtorEmail && props.errors.debtorEmail}
                  {!props.errors.whoOwesYou && !props.errors.debtorName && !props.errors.debtorEmail && props.errors.debtOutstandingAmount && props.errors.debtOutstandingAmount}
                  {!props.errors.whoOwesYou && !props.errors.debtorName && !props.errors.debtorEmail && !props.errors.debtOutstandingAmount && props.errors.howLognHasDebtOutstanding && props.errors.howLognHasDebtOutstanding}
                  {!props.errors.whoOwesYou && !props.errors.debtorName && !props.errors.debtorEmail && !props.errors.debtOutstandingAmount && props.errors.howLognHasDebtOutstanding && props.errors.whatDescribesYourDebt && props.errors.whatDescribesYourDebt}
                  </MKTypography>
                </Grid>
                <Grid item xs={12}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>
        </MKBox>
      </MKBox>
    </>
  );
}

export default Step1;