/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../components/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../components/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="groups"
                    title="About Us"
                    description={`OVERDUE has no equal in offering the same services. At OVERDUE we sincerely care for the SMME, the entrepreneurial sector of the South African economy and its employees.`}
                  />
                  <MKTypography
                    display="block"
                    variant={"body2"}
                    color="text"
                    pr={6}
                  >
                    <br />
                    We provide cover for the insurable risk which all businesses and sole proprietors need, comprehensively integrated with a wide variety of legal services.
                    <br /><br />
                    All our insurance products are underwritten, supported by Stay Sure Insurance Brokers and it's insurance partners. Stay Sure Insurance Brokers (Pty) Ltd is an authorised financial services provider, FSP No 2635.
                    <br /><br />
                    OVERDUE is also a debt collection service, supported by Overdue Collections (Pty) Ltd. Attorneys are registered at the various Councils to transact your Debt Collecting but offer the full spectrum of additional integrated services.
                    <br /><br />
                    Our commitment is to go beyond the norm, offering a unique approach in assisting our client.
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKBox mb={{ xs: 0, md: 0 }}>
                  <DefaultInfoCard
                    icon="gavel"
                    title="About Our Legal Team"
                    description="OVERDUE consists of a panel of Attorneys providing legal services and assisting with debt collection. Our advice is specifically tailored for SMME business owners, entrepreneurs, sole proprietors and its employees, regardless of the actual industry or size."
                  />
                <MKTypography
                    display="block"
                    variant={"body2"}
                    color="text"
                    pr={6}
                  >
                    <br />
                    Our 
                    <MKTypography
                      component="a"
                      href="/collectors/services"
                      variant={"body2"}
                      color="text"
                      sx={{ borderBottom: "1px solid"}}
                    >  legal services</MKTypography> are proactively designed to address the legal challenges and concerns which business owners and employees are faced with daily.
                    <br /><br />
                    OVERDUE has an experienced team of qualified legal advisors offering you an affordable, comprehensive, flexible, one-stop-service-solution to address all your concerns, challenges, and requirements.
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <Grid container justifyContent="flex-start" spacing={10}>
              <Grid item xs={12}>
                <CenteredBlogCard
                  image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/legal-insurance/commercial-legal-cover.jpg`}
                  title="Commercial Legal Cover"
                  description="Ensuring survival, securing your business needs"
                  action={{
                    type: "internal",
                    route: "/our-offering/legal-cover/commercial-legal-cover",
                    color: "info",
                    label: "Insure now",
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CenteredBlogCard
                  image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/legal-insurance/personal-legal-cover.jpg`}
                  title="Personal Legal Cover"
                  description="Intelligent legal solutions for the individual"
                  action={{
                    type: "internal",
                    route: "/our-offering/legal-cover/personal-legal-cover",
                    color: "info",
                    label: "Get cover now",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
