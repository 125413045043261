/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";


const validationSchema = yup.object({
  businessName: yup.string()
    .required("All fields are required"),
  businessType: yup.string()
    .required("All fields are required"),
  businessInvolved: yup.string()
    .required("All fields are required"),
  noEmployees: yup.string()
    .required("All fields are required"),
  turnover: yup.string()
    .required("All fields are required"),
  yearsActive: yup.number().typeError('A valid period in years is required')
    .min(0, "A valid positive number is required")
    .max(200, "Are you sure your business have been active for this long?")
    .required("All fields are required"),
});
function Step1(props) {

  const [stepValues, setStepValues] = useState({
    businessName: "",
    businessType: "",
    businessInvolved: "",
    noEmployees: "",
    turnover: "",
    yearsActive: ""
});


useEffect(() => {
  let cancel = false;
    if(cancel)
      return;

  if(props.step1Values) {
    setStepValues(props.step1Values);
  }

  return () => { 
    cancel = true;
  }
}, [props.step1Values])

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;


    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(0);
    props.setCurrentStepName("Company Information");
    props.setNextStepName("Personal Information");

  return () => { 
          cancel = true;
        }
  }, [props]);

  return (
    <>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Tell us about your business
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
        <Formik
          enableReinitialize={true}
          initialValues={stepValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            props.setStep1Values(values);
            props.setCurrentStep(2);
            }
          }
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12}>
                  <MKInput 
                    label="Name of the business" 
                    id="businessName" 
                    value={props.values.businessName}
                    onChange={props.handleChange('businessName')}
                    error={props.errors.businessName && Boolean(props.errors.businessName)}
                    fullWidth
                  />
              </Grid>
              <Grid item xs={12}>
                  <MKInput 
                    label="Type of business" 
                    id="businessType" 
                    value={props.values.businessType}
                    onChange={props.handleChange('businessType')}
                    error={props.errors.businessType && Boolean(props.errors.businessType)}
                    fullWidth
                    select
                  >
                    <MenuItem key="0" value="Sole Proprietorship">
                      Sole Proprietorship
                    </MenuItem>
                    <MenuItem key="1" value="Partnership">
                      Partnership
                    </MenuItem>
                    <MenuItem key="2" value="Close Corporation (CC)">
                      Close Corporation (CC)
                    </MenuItem>
                    <MenuItem key="3" value="Public Company">
                      Public Company
                    </MenuItem>
                    <MenuItem key="4" value="Private Company">
                      Private Company
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Trade or nature of business" 
                    id="businessInvolved" 
                    value={props.values.businessInvolved}
                    onChange={props.handleChange('businessInvolved')}
                    error={props.errors.businessInvolved && Boolean(props.errors.businessInvolved)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Number of employees" 
                    id="noEmployees" 
                    value={props.values.noEmployees}
                    onChange={props.handleChange('noEmployees')}
                    error={props.errors.noEmployees && Boolean(props.errors.noEmployees)}
                    fullWidth
                    select
                   >
                    <MenuItem key="0" value="1 - 30">
                      1 - 30
                    </MenuItem>
                    <MenuItem key="1" value="31 - 100">
                      31 - 100
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Turnover (R)" 
                    id="turnover" 
                    value={props.values.turnover}
                    onChange={props.handleChange('turnover')}
                    error={props.errors.turnover && Boolean(props.errors.turnover)}
                    fullWidth
                    select
                   >
                    <MenuItem key="0" value="0 - 2 million">
                      0 - 2 million
                    </MenuItem>
                    <MenuItem key="1" value="2 - 5 million">
                      2 - 5 million
                    </MenuItem>
                    <MenuItem key="2" value="5 - 12 million">
                      5 - 12 million
                    </MenuItem>
                    <MenuItem key="3" value="12 - 24 million">
                      12 - 24 million
                    </MenuItem>
                    <MenuItem key="4" value="24 - 32 million">
                      24 - 32 million
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Years active" 
                    id="yearsActive" 
                    value={props.values.yearsActive}
                    onChange={props.handleChange('yearsActive')}
                    error={props.errors.yearsActive && Boolean(props.errors.yearsActive)}
                    fullWidth
                   />
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                    {props.errors.businessName && props.errors.businessName}
                    {!props.errors.businessName && props.errors.businessType && props.errors.businessType}
                    {!props.errors.businessName && !props.errors.businessType && props.errors.businessInvolved && props.errors.businessInvolved}
                    {!props.errors.businessName && !props.errors.businessType && !props.errors.businessInvolved && props.errors.yearsActive && props.errors.yearsActive}
                  </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" href="/our-offering/legal-cover/commercial-legal-cover" variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>
        </MKBox>
      </MKBox>
    </>
  );
}

export default Step1;