// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";

function CollectionTermsAndConditions(props) {
  
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
              <Breadcrumbs
                routes={[
                  { label: "Overdue Terms & Conditions" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
            <Grid container justifyContent="center" sx={{ textAlign: "left" }}>
              <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                  <MKBox
                    p={1}
                    width="100%"
                    variant="gradient"
                    bgColor="info">
                  <MKTypography variant="h3" color="white">
                    TERMS AND CONDITIONS
                  </MKTypography>
                </MKBox>
                <MKTypography variant="body" color="dark" opacity={0.8}>
                  Overdue as a product of Overdue (Pty) Ltd and its panel of Attorneys. They have agreed to collect outstanding debt owed to you or your business by debtors and in turn have handed over to the appointed Attorney, who might be changed from time to time. Both parties hereto agree as follow:
                </MKTypography>
              </Grid>
              <Grid item xs={12}>
                <MKTypography variant="body2" color="dark">
                    <ol>
                      <li>The percentage due of successfully collected debt, inclusive of payment plans and monies paid in at any address of you, is 25% (excluding VAT). Overdue (Pty) Ltd T/A Overdue reserves the right to review the collection fee subject to 30 days written notice.</li>
                      <li>Our appointed Attorneys have undertaken to pay you, on a monthly basis, all successful collections of the debt less collection fee, which shall be calculated as in 1 above and costs as calculated in 3 below.</li>
                      <li>Our appointed Attorneys reserves the right to collect from the debtor all expenses and fees as provided for, in the Debt Collectors Act, No.114 and these costs will be deducted from payments made and any other pro-rata sum of such.</li>
                      <li>Interest collected, at the Mora rate, in addition to the capital debt will also be paid over to you subject to the stipulations in 1 above.</li>
                      <li>Our appointed Attorneys will request to make payments directly into their Trust account or any other nominee's Trust account as they deem fit. You undertake to inform the appointed Attorney, in writing on a weekly basis, of all payments that are received directly at your branches or offices. In such an event Overdue (Pty) Ltd T/A Overdue and their appointed Attorney will be entitled to an amount equal to the commission as determined in 1 above, on all payments made directly.</li>
                      <li>You reserve the right to withdraw the debt or any part thereof, but only if the handover of the particular debt, or part thereof was due to an administrative error on your behalf. Overdue (Pty) Ltd T/A Overdue and their appointed Attorney will be entitled to collection fee on monies collected after date of handover as per clause 1 above.</li>
                      <li>Our appointed Attorneys will take the necessary steps to recover the debt as per your instructions. Should it become necessary to make use of the services of an alternative Attorney or Advocate or any professional person, the following will be applicable:</li>
                      <li>You will be informed by Overdue (Pty) Ltd T/A Overdue or its appointed Attorney and you must give written permission for the handover.</li>
                      <li>You will be held liable, directly towards the Attorney or Advocate or any professional person, for the cost incurred.</li>
                      <li>It is however agreed that Overdue (Pty) Ltd T/A Overdue and its appointed Attorney will also have the sole discretion to make use of the services of an alternative Attorney or Advocate or any other professional person without a written permission by you. In such an event Overdue (Pty) Ltd T/A Overdue or its appointed Attorney will be liable for all costs towards the alternative Attorney or Advocate or any other professional person.</li>
                      <li>You reserve the right to withdraw the debt or any part thereof other than those referred to in 6 above with 30 days written notice to Overdue (Pty) Ltd T/A Overdue or the appointed Attorney. You will be held liable for expenses incurred by Overdues (Pty) Ltd T/A OverdueT/A Overdue and its appointed Attorney as in clause 3 above and co due as per Clause 1 & 6 above.</li>
                  </ol>
                  </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default CollectionTermsAndConditions;