// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";

import MKTypography from "../../components/MaterialKit/MKTypography";

import ProductCard from "../../components/Cards/ProductCard";

function InsuranceLandingPage(props) {
  
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Breadcrumbs
                routes={[
                  { label: "Our Offering", route: "/our-offering/legal-cover" },
                  { label: "Legal Cover" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                <MKTypography variant="h3" color="dark">
                  Legal Cover
                </MKTypography>
                <MKTypography variant="body2" color="dark" opacity={0.8}>
                  Protecting you against unplanned costs of getting professional legal assistance
                </MKTypography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
              <ProductCard 
                  icon={"businesscenter"}
                  heading="Commercial"
                  text="Ensuring survival, securing your business needs"
                  route="/our-offering/legal-cover/commercial-legal-cover"
                  image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/legal-insurance/commercial-legal-cover.jpg`}
                />
            </Grid>
            <Grid item sm={12} md={6}>
              <ProductCard 
                  icon={"personrounded"}
                  heading="Personal"
                  text="Intelligent legal solutions for the individual"
                  route="/our-offering/legal-cover/personal-legal-cover"
                  image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/legal-insurance/personal-legal-cover.jpg`}
                />
              </Grid>
          </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}


export default InsuranceLandingPage;