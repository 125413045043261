// react native
import { useState, useEffect } from "react";

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";

import Step1 from "./sections/Step1";
import Step2 from "./sections/Step2";


function StartLegalInsurance(props) {
  const [percentage, setPercentage] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [step1Values, setStep1Values] = useState(null);
  const [step2Values, setStep2Values] = useState(null);
  const [currentStepName, setCurrentStepName] = useState("");
  const [nextStepName, setNextStepName] = useState("");


  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
    if(props.currentStep) {
      setCurrentStep(props.currentStep);
    }
    return () => { 
      cancel = true;
    }
  }, [props.currentStep])
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
      <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Breadcrumbs
                routes={[
                  { label: "Legal Cover", route: '/our-offering/legal-cover' },
                  { label: "Personal", route: '/our-offering/legal-cover/personal-legal-cover' },
                  { label: "Quote" }
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 0, lg: 6 }}>
          <Container>
            <Grid container item>
              <MKBox
                width="100%"
                bgColor="white"
                borderRadius="xl"
                shadow="xl"
                mb={6}
                sx={{ overflow: "hidden" }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    position="relative"
                    px={0}
                    sx={{
                      backgroundImage: ({
                        palette: { gradients },
                        functions: { rgba, linearGradient },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.8),
                          rgba(gradients.dark.state, 0.8)
                        )}, url(${process.env.REACT_APP_CDN_Assets_Base}/pages/our-offering/legal-insurance/background.png)`,
                      backgroundSize: "cover",
                    }}
                  >
                    <MKBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      height="100%"
                    >
                      <MKBox 
                        py={3} pr={12} pl={{ xs: 12, sm: 12 }} my="auto">
                        <MKTypography style={{"textAlign": "center"}} variant="h3" color="white" mb={3}>
                          {currentStepName}
                        </MKTypography>
                        <CircularProgressbar 
                          styles={buildStyles({
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'butt',
                            // Text size
                            textSize: '16px',
                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,
                            pathColor: "#9c0f18",
                            textColor: 'white',
                            trailColor: 'white'
                          })}
                          value={percentage} 
                          text={`${(currentStep < 0 ? -currentStep : currentStep)} of 3`}
                        />
                        <MKTypography style={{"textAlign": "center"}} justifyContent="center" alignItems="center" variant="body2" color="white" opacity={0.8} mb={2}>
                            &nbsp;
                        </MKTypography>
                        <MKTypography variant="h4" color="white" opacity={0.8} mb={2} style={{"textAlign": "center"}} >
                          {currentStep < 0 ? "BACK" : "NEXT"}
                        </MKTypography>
                        <MKTypography variant="body2" color="white" opacity={0.8} mb={2} style={{"textAlign": "center"}} >
                          {nextStepName}
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                  {currentStep === 1 && <Step1 
                      isMobile={props.isMobile} 
                      sessionInfo={props.sessionInfo} 
                      setPercentage={setPercentage} 
                      setCurrentStep={setCurrentStep} 
                      step1Values={step1Values}
                      setStep1Values={setStep1Values}
                      setCurrentStepName={setCurrentStepName}
                      setNextStepName={setNextStepName}
                    />}
                    {currentStep === 2 && <Step2
                      isMobile={props.isMobile} 
                      sessionInfo={props.sessionInfo} 
                      setPercentage={setPercentage} 
                      setCurrentStep={setCurrentStep} 
                      step1Values={step1Values}
                      step2Values={step2Values}
                      setStep2Values={setStep2Values}
                      setCurrentStepName={setCurrentStepName}
                      setNextStepName={setNextStepName}

                      refreshSession={props.refreshSession}
                    />}
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default StartLegalInsurance;