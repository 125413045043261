const baseURL = process.env.REACT_APP_Comms_Base_Url;
const apiKey = process.env.REACT_APP__Api_Key;

export const postUserDebtCollectionDoumentToken = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/comms/debt/doc/token/post`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });

export const postContactUs = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/comms/contactus/post`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });

    export const postOhNo = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/comms/debt/ohno/post`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });

export const postLanding = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/comms/landing/post`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });

export const postJoinCollectors = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/comms/collectors/join/post`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });


export const postLegalOffering = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/comms/offering/legal/post`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });


export const postReferFriend = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/comms/debt/referral/friend`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });