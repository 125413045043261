import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Main from './Main';
import { Account } from './cognito/Account';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./assets/theme";

export default function App() {
  const { pathname } = useLocation();
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
      
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    return () => { 
      cancel = true;
    }
  }, [pathname]);
  
  return (
    <div className="App">
      <Account>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Main />
        </ThemeProvider>
      </Account>
    </div>
  );
}