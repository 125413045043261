/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Link } from "react-router-dom";

import colors from "../../../assets/theme/base/colors";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "../../MaterialKit/MKBox";
import MKTypography from "../../MaterialKit/MKTypography";


function ProductCard({ icon, heading, text, route, image }) {
  const { info } = colors;
  return (
    <MKBox p={1} my="auto" sx={{"maxWidth": "800px", height: '100%' }}>
      <Card
        sx={{
          border: 1,
          backgroundSize: "cover",
          borderColor: info.main,
          height: '100%'
        }}
      >
        <MKBox p={1} sx={{ height: '100%' }}>
          <MKBox my="auto"sx={{ height: '100%' }}>
            <Grid container spacing={0} sx={{ height: '100%' }}>
              <Grid item xs={12}>
                <MKBox
                  component="img"
                  src={image}
                  alt={heading}
                  borderRadius="lg"
                  width="100%"
                  height="80%"
                  position="relative"
                  zIndex={1}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="left" gap={1}>
                  <MKTypography color="dark" variant="h1">
                    {typeof icon === "string" ? <Icon >{icon}</Icon> : icon}
                  </MKTypography>
                  <MKTypography color="info" variant="h3" sx={{"textAlign": "center"}}>
                    {heading}
                  </MKTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={9} lg={10}>
                <MKBox p={2}>
                  <MKTypography color="dark" variant="body">
                    {text}
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={3} lg={2}
                  container
                  direction="column"
                  alignItems="flex-end"
                  justifyContent="flex-end">
                <MKTypography variant="h1" color="info" component={Link} to={route}>
                    <i className="fas fa-play-circle" />
                </MKTypography>
              </Grid>
            </Grid>
            </MKBox>
          </MKBox>
        </Card>
    </MKBox>
  );
}

export default ProductCard;
