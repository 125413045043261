// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import PricingCard from "../../components/Cards/PricingCard";
import MKTypography from "../../components/MaterialKit/MKTypography";

function LegalInsurance(props) {
  
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Breadcrumbs
                routes={[
                  { label: "Our Offering", route: "/" },
                  { label: "Legal Cover", route: '/our-offering/legal-cover' },
                  { label: "Personal" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                <MKTypography variant="h3" color="dark">
                  Personal Legal Cover
                </MKTypography>
                <MKTypography variant="body2" color="dark" opacity={0.8}>
                  Select the legal insurance cover that best suits your needs:
                </MKTypography>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
          <Grid item sm={12} lg={6} xl={3}>
              <PricingCard 
                amount={49} 
                link="/our-offering/legal-cover/personal-legal-cover/ec79963c-de13-40f5-a2f7-96c809e43821/quote"
                linkText="get a quote"
                product={
                  {
                      name: "ACCESS", 
                      components: [
                        "R50 000 p/a for court representation",
                        "Cover for main member only",
                        "Basic contracting drafting assistance",
                        ""
                    ]
                  }
                }/>
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <PricingCard 
                highlightText="POPULAR" 
                amount={99} 
                amountCents={0}
                link="/our-offering/legal-cover/personal-legal-cover/3c75e0e0-e95a-4ba5-9f31-897ffb462c6c/quote"
                linkText="get a quote"
                product={
                  {
                      name: "ESSENTIAL", 
                      components: [
                        "R100 000 p/a for court representation",
                        "Cover for your spouse and four children",
                        "Disciplinary hearings in instance of dismissal",
                        ""
                    ]
                  }
                }/>
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <PricingCard 
                highlightText="RECOMMENDED" 
                amount={199} 
                amountCents={0}
                link="/our-offering/legal-cover/personal-legal-cover/0aa0529a-7b26-4bc6-ab81-22991c1a7488/quote"
                linkText="get a quote"
                product={
                  {
                      name: "ELITE", 
                      components: [
                        "R200 000 p/a for court representation",
                        "Cover for your spouse and four children",
                        "Unopposed divorce matters",
                        "Bail cover up to R6 000"
                    ]
                  }
                }/>
            </Grid>
            <Grid item sm={12} lg={6} xl={3}>
              <PricingCard 
                amount={315} 
                amountCents={0}
                link="/our-offering/legal-cover/personal-legal-cover/17b9022c-f571-4c45-9ac4-c83569c26ce0/quote"
                linkText="get a quote"
                product={
                  {
                      name: "ELITE PLUS", 
                      components: [
                        "R300 000 p/a for court representation",
                        "Cover for your spouse and four children",
                        "Opposed divorce matters up to R10 000",
                        "Bail cover up to R10 000"
                    ]
                  }
                }/>
            </Grid>
          </Grid>
        </MKBox>
      </Card>
    </>
  );
}

export default LegalInsurance;
