/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from "@mui/material/Switch";

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";

import {postUserDebtCollection} from '../../../apis/api';
import StepperSignIn from "../../../components/Stepper/StepperSignIn";

const validationSchema = yup.object({
  proceed: yup.string()
    .required("All fields are required")
});

function Step4(props) {
    const [stepValues, setStepValues] = useState({
        proceed: ""

    });
    const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
    const [backdropClose, setBackdropClose] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    
    const handleChecked = () => setTermsAccepted(!termsAccepted);

    useEffect(() => {
      let cancel = false;
      if(cancel)
        return;

      if(props.step4Values) {
        setStepValues(props.step4Values);
      }

      return () => { 
        cancel = true;
      }
    }, [props.step4Values])

    useEffect(() => {
      let cancel = false;
      if(cancel)
        return;

      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      props.setPercentage(65);
      props.setCurrentStepName("Confirmation");
      props.setNextStepName("Invoice");

      return () => { 
        cancel = true;
      }
    }, [props]);

    useEffect(() => {
      let cancel = false;
      if(cancel)
        return;

      setSessionInfo(props.sessionInfo);

      return () => { 
        cancel = true;
      }
    }, [props.sessionInfo]);
  
  const goBack = (e) => {
    props.setCurrentStep(3);
  }

  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            {`${sessionInfo ? 'Confirmation' : 'Verify that it is you'}`}
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
          {`${sessionInfo ? 'Please note that by sending a letter of demand to the debtor, this can cause the Debtor to become agitated and stressed.' : ''}`}
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
        {sessionInfo && <Formik
            enableReinitialize={true}
            initialValues={stepValues}
            validationSchema={validationSchema}
          onSubmit={values => {
            if(values.proceed === "no") {
              props.setCurrentStep(-4);
              props.setNextStepName("Confirmation");
            } 
            else {
              setBackdropClose(true);

              const userInfo = {
                userId: sessionInfo.sub,
                name: sessionInfo.given_name,
                surname: sessionInfo.family_name,
                telephone: sessionInfo.phone_number,
                email: sessionInfo.email
              };
              var postData = {...props.postValues, ...userInfo};
              postUserDebtCollection(postData)
                  .then(data => {
                      props.setStep4Values({...values, ...data});
                      props.setCurrentStep(5);
                      setBackdropClose(false);
                  })
                  .catch(err => {
                      setBackdropClose(false);
                  });
              }
          }}
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12}>
                  <MKInput 
                    label="Name" 
                    id="name" 
                    value={sessionInfo.given_name}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Surname" 
                    id="surname" 
                    value={sessionInfo.family_name}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Company" 
                    id="company" 
                    value={sessionInfo.nickname}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Telephone" 
                    id="telephone" 
                    value={sessionInfo.phone_number}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Email" 
                    id="email" 
                    value={sessionInfo.email}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Are you sure you ready to proceed?" 
                    id="proceed" 
                    value={props.values.proceed}
                    onChange={props.handleChange('proceed')}
                    error={props.errors.proceed && Boolean(props.errors.proceed)}
                    fullWidth
                    select
                  >
                    <MenuItem key={0} value="yes">
                      Yes
                    </MenuItem>
                    <MenuItem key={1} value="no">
                      No
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}  sx={{ textAlign: "left" }}>
                  <Switch checked={termsAccepted} onChange={handleChecked} />
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    ml={-1}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    onClick={handleChecked}
                  >
                    &nbsp;&nbsp;I accept the&nbsp;
                  </MKTypography>
                  <MKTypography
                    component="a"
                    href="/collectionterms"
                    target="_blank"
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    Terms and Conditions
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                    {props.errors.proceed && props.errors.proceed}
                  </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" onClick={goBack} variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton disabled={!termsAccepted} type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>}
        {!sessionInfo && <StepperSignIn 
          isMobile={props.isMobile} 
          sessionInfo={props.sessionInfo} 
          refreshSession={props.refreshSession}
          goBack={goBack}
        />}
        </MKBox>
      </MKBox>
    </>
  );
}

export default Step4;