/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import colors from "../../../assets/theme/base/colors";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';

// Material Kit 2 React components
import MKBox from "../../MaterialKit/MKBox";
import MKTypography from "../../MaterialKit/MKTypography";
import MKButton from "../../../components/MaterialKit/MKButton";

function PricingCard({ amount, amountCents, amountText, amountSubText, product, link, linkText, highlightText, discount, discountTerms }) {
  let idCounter = 0;
  const { dark, info } = colors;
  return (
    <MKBox p={1} my="auto" sx={{height: "100%"}}>
      <Card
        sx={{
          border: 1,
          backgroundSize: "cover",
          borderColor: highlightText ? info.main : dark.main,
          height: product?.includes ? 'auto' : "100%"
        }}
      >
        <MKBox p={3}>
          <MKBox my="auto">
            {amount && <MKTypography
              variant="h1"
              color="info"
              align="right"
            >
              <MKTypography color="info" sx={{fontSize: 20, display: 'inline'}}>R </MKTypography>
              {discount && <span style={{textDecorationLine: 'line-through'}}>{amount.toLocaleString()}</span>}
              {!discount && amount.toLocaleString()}
              <MKTypography color="info" sx={{fontSize: 20, display: 'inline'}}>.{!amountCents ? "00" : ((amountCents < 10 ? '0' : '') + amountCents)}</MKTypography>
            </MKTypography>}
            {amountText && <MKTypography
              variant="h1"
              color="info"
              align="right"
            >{amountText}
            </MKTypography>}
            {amount && <MKTypography variant="body2" color="dark" align="right">
              {discount && <b><StarIcon sx={{my: 0, verticalAlign: -1}}/>{`R ${discount}`}</b>} per month
            </MKTypography>}
            {(amountSubText || amountText) && <MKTypography variant="body2" color="dark" align="right">
              &nbsp;{amountSubText}
            </MKTypography>}
            <MKBox px={1} borderRadius="sm" bgColor={highlightText ? "light" : "none"} sx={{width: "70%"}}>
              {highlightText && <MKTypography
                align="left"
                variant="h6"
                color="info"
                py={0.5}
              >{highlightText}</MKTypography>}
              {!highlightText && <MKTypography
                variant="h6"
                color="info"
                py={0.5}
              >&nbsp;</MKTypography>}
            </MKBox>
            <hr color={dark.main} width="70%"/>
            <MKTypography
              variant="h4"
              color="dark"
              py={0.5}
            >
              {product.name}
            </MKTypography>
            <MKTypography
              variant="body2"
              color="info"
              py={0}
            >&nbsp;</MKTypography>
            {product?.features && product?.features.map((feature) => ( <MKBox key={++idCounter} display="flex" p={0}>
              <MKTypography variant="button" color="info">
                {feature.feature ? <DoubleArrowIcon fontSize="small"/> : <span>&nbsp;</span>}
              </MKTypography>
              <MKTypography
                component="span"
                variant="button"
                color="dark"
                opacity={0.8}
                ml={2}
                fontWeight="regular"
              >
                {feature.feature ? feature.feature : <span>&nbsp;</span>}
              </MKTypography>
            </MKBox>))}
            {product?.components && product?.components.map((component) => ( <MKBox key={++idCounter} display="flex" p={0}>
              <MKTypography variant="button" color="info">
                {component ? <DoubleArrowIcon fontSize="small"/> : <span>&nbsp;</span>}
              </MKTypography>
              <MKTypography
                component="span"
                variant="button"
                color="dark"
                opacity={0.8}
                ml={2}
                fontWeight="regular"
              >
                {component ? component : <span>&nbsp;</span>}
              </MKTypography>
            </MKBox>))}
            <MKTypography
              variant="body2"
              color="info"
              py={0.5}
            >&nbsp;</MKTypography>
            <MKTypography align="center" py={0.5}>
              <MKButton align="center"  type="link" href={link} variant="gradient" color="info" sx={{ height: "100%" }}>
                {linkText}
              </MKButton>
            </MKTypography>
            {discountTerms && <MKTypography
              variant="body2"
              color="info"
              py={0.5}
            >&nbsp;</MKTypography>}
            {discountTerms && <MKBox display="flex" p={0}>
              <MKTypography variant="button" color="info">
                {discountTerms ? <StarIcon fontSize="small"/> : <span>&nbsp;</span>}
              </MKTypography>
              <MKTypography
                component="span"
                variant="button"
                color="dark"
                opacity={0.8}
                ml={2}
                fontWeight="regular"
              >
                {discountTerms ? discountTerms : ""}
              </MKTypography>
            </MKBox>}
          </MKBox>
        </MKBox>
      </Card>
      <MKBox p={2} my="auto">
        {product?.features && <MKTypography variant="h6" color="dark" align="left" py={1.5}>
          {product.name} includes:
        </MKTypography>}
        {product?.features && product?.features.map((feature) => ( <MKBox key={++idCounter}>
          <MKBox key={++idCounter} display="flex" p={0}>
            <MKTypography variant="button" color="info">
              {feature.feature ? <DoubleArrowIcon fontSize="small"/> : <span>&nbsp;</span>}
            </MKTypography>
            <MKTypography
              component="span"
              variant="button"
              color="info"
              ml={2}
              fontWeight="bold"
            >
              {feature.feature ? feature.feature : <span>&nbsp;</span>}
            </MKTypography>
          </MKBox>
          {feature?.includes && feature?.includes.map((include) => ( <MKBox key={++idCounter} display="flex" p={0.5}>
            <MKTypography variant="caption" color="info">
              {include  ?  <CheckIcon/> : <span>&nbsp;</span>}
            </MKTypography>
            <MKTypography
              component="span"
              variant="caption"
              color="dark"
              opacity={0.8}
              ml={2}
              fontWeight="regular"
            >
              {include ? include : <span>&nbsp;</span>}
            </MKTypography>
            </MKBox>))}
          </MKBox >))}
        {product?.includes && <MKTypography variant="h6" color="dark" align="left" py={1.5}>
          {product.name} includes:
        </MKTypography>}
        
        {product?.includes && product?.includes.map((include) => ( <MKBox key={++idCounter} display="flex" p={0.5}>
            <MKTypography variant="caption" color="info">
              {include  ?  <CheckIcon/> : <span>&nbsp;</span>}
            </MKTypography>
            <MKTypography
              component="span"
              variant="caption"
              color="dark"
              opacity={0.8}
              ml={2}
              fontWeight="regular"
            >
              {include ? include : <span>&nbsp;</span>}
            </MKTypography>
          </MKBox>))}
        </MKBox>
    </MKBox>
  );
}

export default PricingCard;
