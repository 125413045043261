import { useState, useEffect } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKButton from "../../../components/MaterialKit/MKButton";


function SuccessModal(props) {
    
    const [isMobile, setIsMobile] = useState(props.isMobile);
    const toggleModal = () => setShow(!show);
    const [show, setShow] = useState(props.show);

    useEffect(() => {
        let cancel = false;
          if(cancel)
            return;
       
            setShow(props.show);
    
        return () => { 
          cancel = true;
        }
    
      }, [props.show]);

    useEffect(() => {
        let cancel = false;
          if(cancel)
            return;
       
        setIsMobile(props.isMobile);
    
        return () => { 
          cancel = true;
        }
    
      }, [props.isMobile]);

    return (
        <Modal 
            disableAutoFocus={true}
            open={show} onClose={toggleModal} 
            sx={{ border: "none", display: "grid", placeItems: "center" }}
        >
            <Slide direction="down" in={show} timeout={500}>
            <MKBox
                position="relative"
                width={isMobile ? "85vw" : "500px"}
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
            >
                <Grid container sx={{ textAlign: "center" }}>
                    <Grid item xs={12} sx={{ mt: -3 }}>
                        <MKTypography style={{"fontSize": "70px"}}>
                            <CheckCircleIcon style={{"color": "#4caf4f"}} />
                        </MKTypography>
                        <MKTypography variant="h2" color="dark">Success</MKTypography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 0 }} />
                <MKBox borderRadius="xl" bgColor="white" display="flex" alginItems="center" justifyContent="space-between" p={2}>
                
                <MKTypography variant="h5" color="dark">{props.header}</MKTypography>
                <CloseIcon color="dark" fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                    {props.text}
                </MKTypography>
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                    close
                </MKButton>
                </MKBox>
            </MKBox>
            </Slide>
        </Modal>
    );
}

export default SuccessModal;