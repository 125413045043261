import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import MKBox from "../MaterialKit/MKBox";
import MKTypography from "../MaterialKit/MKTypography";

import Ticker from 'react-ticker';
import moment from 'moment';

function NewsTicker({ headlines, maxArticles, speed }) {
    const [newsFeedHeadlines, setNewsFeedHeadlines] = useState(null);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setNewsFeedHeadlines(headlines);
    }, [headlines]);

    useEffect(() => {
        if (newsFeedHeadlines && (counter >= (maxArticles ?? newsFeedHeadlines.length))) {
            setCounter(0);
        }
    }, [counter, maxArticles, newsFeedHeadlines]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <MKBox bgColor="white" borderRadius="lg">
                    {newsFeedHeadlines && <Ticker speed={speed} mode="chain">
                        {() => (
                            <MKBox p={1}>
                                <MKTypography sx={{ "whiteSpace": "nowrap" }} variant="body2" color="dark">
                                    <strong>{newsFeedHeadlines[counter]?.date ? `${moment.utc(newsFeedHeadlines[counter].date).local().format('DD MMM')}: ` : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}</strong>{newsFeedHeadlines[counter]?.title}
                                </MKTypography>
                                {setCounter(prevCounter => (prevCounter + 1) % newsFeedHeadlines.length)}
                            </MKBox>
                        )}
                    </Ticker>}
                    {!newsFeedHeadlines && <MKBox p={1}>
                        <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                            <CircularProgress size={20} color="inherit" />
                            <MKTypography sx={{ "whiteSpace": "nowrap" }} variant="body2" color="dark">
                                Downloading content...
                            </MKTypography>
                        </Stack>
                    </MKBox>}
                </MKBox>
            </Grid>
        </Grid>
    );
}

export default NewsTicker;