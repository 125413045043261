/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKButton from "../../../components/MaterialKit/MKButton";
import MKTypography from "../../../components/MaterialKit/MKTypography";

// Material Kit 2 React examples
import DefaultCounterCard from "../../../components/Cards/CounterCards/DefaultCounterCard";

function Featuring() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="dark">
              Collecting your debt
            </MKTypography>
            <MKTypography variant="body2" color="dark" opacity={0.8}>
              On completion of your debt collection application, a debt collector will immediately proceed with the following 4 steps:
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DefaultCounterCard
              align="justify"
              count={1}
              prefix="Step "
              separator=","
              title="In Mora"
              description='Place the Debtor "In Mora", a Letter of Demand advising an individual or company, they are in default of outstanding money.'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DefaultCounterCard
              align="justify"
              count={2}
              separator=","
              prefix="Step "
              title="Courtesy Call"
              description="Debtor to receive a Courtesy Call where our Attorney clarifies their position and requests that the Debtor pay immediately."
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DefaultCounterCard
              align="justify"
              count={3}
              prefix="Step "
              title="Summons"
              description="We issue Summons if the Debtor fails to pay, where the Sheriff will punctually serve the summons."
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DefaultCounterCard
              align="justify"
              count={4}
              prefix="Step "
              title="Action"
              description="Should the Debtor still refuse to pay we proceed by issuing Warrant of Execution, a Garnishee Order or apply to sell their immovable property."
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <MKButton
              component={MuiLink}
              href="/start-debt-collecting"
              rel="noreferrer"
              variant="gradient"
              size="small"
              color="info"
            >
              start debt collecting
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
