// @mui material components
import Icon from "@mui/material/Icon";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EngineeringIcon from '@mui/icons-material/Engineering';

export const headerMenuItems = [
  {
    key: 1,
    icon: <Icon>home</Icon>,
    columns: 1,
    rowsPerColumn: 1,
    name: "Home",
    route: "/home"
  },
  {
    key: 3,
    icon: <Icon>gavel</Icon>,
    columns: 1,
    rowsPerColumn: 1,
    name: "Our offering",
    collapse: [{
      key: 300,
      name: "",
      collapse: [{
        key: 333,
        name: "Why Overdue",
        route: "/our-offering/why-overdue"
      },
      {
        key: 334,
        name: "Debt Collection",
        route: "/our-offering/debt-collection",
      },
      {
        key: 335,
        name: "Legal Cover",
        route: "/our-offering/legal-cover",
      }]
    }]
  },
  {
    key: 4,
    icon: <EngineeringIcon />,
    columns: 1,
    rowsPerColumn: 1,
    name: "Collectors",
    collapse: [{
      key: 4400,
      name: "",
      collapse: [{
        key: 4401,
        name: "The Collectors",
        route: "/collectors/the-collectors",
      },
      {
        key: 4402,
        name: "Services",
        route: "/collectors/services",
      },
      {
        key: 4403,
        name: "Join The Collectors",
        route: "/collectors/join-the-collectors",
      }]
    }]
  },
  {
    key: 5,
    icon: <AlternateEmailIcon />,
    columns: 1,
    rowsPerColumn: 1,
    name: "Contact us",
    route: "/contact-us",
  }
];

export const headerMenuItemSignedOut = [
  {
    key: 21,
    icon: <Icon>person</Icon>,
    columns: 1,
    rowsPerColumn: 1,
    name: "Profile",
    collapse: [{
      name: "Not signed in",
      key: 2101,
      collapse: [
        {
          key: 210206,
          name: "Team Blog",
          route: "/team-blog",

        },
        {
          key: 210101,
          name: "Sign in",
          route: "/authenticate",

        }
      ]
    }
    ]
  }
];

export const headerMenuItemSignedIn = userFullNames => ([
  {
    key: 21,
    icon: <Icon>person</Icon>,
    columns: 1,
    rowsPerColumn: 1,
    name: "Profile",
    collapse: [
      {
        name: userFullNames,
        key: 2102,
        collapse: [
          {
            key: 210205,
            name: "Team Blog",
            route: "/team-blog",

          },
          {
            key: 210203,
            name: "My Debt Dashboard",
            route: "/dashboard",

          },
          {
            key: 210202,
            name: "Sign Out",
            route: "/authenticate",

          }
        ]
      }
    ]
  }
]);

export const footerMenuItems = [
  {
    name: "Home",
    route: "/home",
    items: [
      { name: "Contact Us", route: "/contact-us" },

      { name: "Terms & Conditions", route: "/terms-and-conditions" },
      { name: "Privacy Policy", route: "/privacy-policy" }
    ]
  },
  {
    name: "Our Offering",
    items: [
      { name: "Why Overdue?", route: "/our-offering/why-overdue" },
      { name: "Debt Collection", route: "/our-offering/debt-collection" },
      { name: "Legal Cover", route: "/our-offering/legal-cover" }
    ],
  },
  {
    name: "Collectors",
    items: [
      { name: "The Collectors", route: "/collectors/the-collectors" },
      { name: "Services", route: "/collectors/services" },
      { name: "Join The Collectors", route: "/collectors/join-the-collectors" }
    ],
  }
];

export const footerMenuProfileSignedOut = [
  {
    name: "Profile",
    items: [
      { name: "Team Blog", route: "/team-blog" },
      { name: "Sign In", route: "/authenticate" },
    ],
  }
];

export const footerMenuProfileSignedIn = [
  {
    name: "Profile",
    items: [
      { name: "Team Blog", route: "/team-blog" },
      { name: "My Debt Dashboard", route: "/dashboard" },
      { name: "Sign Out", route: "/authenticate" }
    ],
  }
];