import React, { useState, useContext, useEffect, useCallback } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { headerMenuItems, headerMenuItemSignedOut, headerMenuItemSignedIn, footerMenuItems, footerMenuProfileSignedIn, footerMenuProfileSignedOut } from "../routes";
import { AccountContext } from '../cognito/Account';

//pages 
import DefaultPage from '../pages/DefaultPage';
import DefaultLandingPage from '../pages/DefaultLandingPage';
import StartCommercialLegalInsurancePage from '../pages/StartCommercialLegalInsurancePage';
import StartDebtCollecting from '../pages/StartDebtCollectingPage';
import StartLegalInsurance from '../pages/StartLegalInsurancePage';
import FinaliseDebtCollecting from '../pages/FinaliseDebtCollectingPage';
import FinaliseLegalInsurancePage from '../pages/FinaliseLegalInsurancePage';
import FinaliseCommercialLegalInsurancePage from '../pages/FinaliseCommercialLegalInsurancePage';
import TermsAndConditions from '../pages/TermsAndConditionsPage';
import WhyOverdue from '../pages/WhyOverduePage';
import DebtCollection from '../pages/DebtCollectionPage';
import Services from '../pages/ServicesPage';
import InsuranceLandingPage from '../pages/InsuranceLandingPage';
import LegalInsurance from '../pages/LegalInsurancePage';
import CommercialLegakInsurancePage from '../pages/CommercialLegakInsurancePage';
import AboutCollectors from '../pages/AboutCollectorsPage';
import JoinTheCollectors from '../pages/JoinTheCollectorsPage';
import ContactUs from '../pages/ContactUsPage';
import MyDebtDashboard from '../pages/MyDebtDashboard';
import CollectionTermsAndConditions from '../pages/CollectionTermsAndConditionsPage';
import PrivacyPolicyPage  from "../pages/PrivacyPolicyPage "
import BlogPage from "../pages/BlogPage";
import AuthenticatePage from "../pages/AuthenticatePage";

// Material Kit 2 React examples
import DefaultNavbar from "../components/Navbars/DefaultNavbar";
import DefaultFooter from "../components/Footers/DefaultFooter";
import breakpoints from "../assets/theme/base/breakpoints";

// Material Kit 2 React components
import MKBox from "../components/MaterialKit/MKBox";

function Main() {

  const [sessionInfo, setSessionInfo] = useState({});
  const [menuItems, setMenuItems] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoints.values.lg);
  const { getSession } = useContext(AccountContext);
  const [footerMenu, setFooterMenu] = useState([]);

  const refreshSession = useCallback(() => {
    getSession()
        .then((session) => {
            setSessionInfo(session.idToken.payload);
        })
        .catch((err) => {
            setSessionInfo(null);
        });
    }, [getSession]);

    useEffect(() => {
      let cancel = false;
      if(cancel)
        return;
      
        
      refreshSession();
        
        return () => { 
          cancel = true;
        }
    }, [refreshSession]);

    useEffect(() => {
      function handleResize() {
        setIsMobile(window.innerWidth < breakpoints.values.lg);
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      let cancel = false;
        if(cancel)
          return;
      
      if(sessionInfo) {
        setFooterMenu([...footerMenuItems, ...footerMenuProfileSignedIn]);
        setMenuItems([...headerMenuItems, ...headerMenuItemSignedIn(sessionInfo?.name ?? "")]);
      }
      else {
        setFooterMenu([...footerMenuItems, ...footerMenuProfileSignedOut]);
        setMenuItems([...headerMenuItems, ...headerMenuItemSignedOut]);
      }
  
      return () => { 
        cancel = true;
      }
    }, [sessionInfo, isMobile, refreshSession]);

    return (
      <>
      <DefaultNavbar
        routes={menuItems}
        action={{
          type: "internal",
          route: "/start-debt-collecting",
          label: "start debt collecting",
          color: "white",
        }}
        transparent
        light
      />
        <Routes>
          <Route exact path="/landing" element={<DefaultLandingPage isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} show={false} showError={false} />} key={0} />
          <Route exact path="/landing/success" element={<DefaultLandingPage isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} show={true} showError={false} />} key={1} />
          <Route exact path="/landing/fail" element={<DefaultLandingPage isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} show={false} showError={true} />} key={11111} />
          <Route path="/offering/overdue" element={<Navigate to="/our-offering/why-overdue" />}  key={-2}/>
          <Route exact path="/our-offering/why-overdue" element={<WhyOverdue isMobile={isMobile} sessionInfo={sessionInfo} />} key={2} />
          <Route path="/offering/debtcollection" element={<Navigate to="/our-offering/debt-collection" />}  key={-3}/>
          <Route exact path="/our-offering/debt-collection" element={<DebtCollection isMobile={isMobile} sessionInfo={sessionInfo} />} key={3} />
          <Route path="/offering/insurance/personal" element={<Navigate to="/our-offering/legal-cover/personal-legal-cover" />}  key={-4}/>
          <Route exact path="/our-offering/legal-cover/personal-legal-cover" element={<LegalInsurance isMobile={isMobile} sessionInfo={sessionInfo} />} key={4} />
          <Route path="/collectors/about" element={<Navigate to="/collectors/the-collectors" />}  key={-5}/>
          <Route exact path="/collectors/the-collectors" element={<AboutCollectors isMobile={isMobile} sessionInfo={sessionInfo} />} key={5} />
          <Route exact path="/collectors/services" element={<Services isMobile={isMobile} sessionInfo={sessionInfo} />} key={6} />
          <Route path="/collectors/join" element={<Navigate to="/collectors/join-the-collectors" />}  key={-7}/>
          <Route exact path="/collectors/join-the-collectors" element={<JoinTheCollectors isMobile={isMobile} sessionInfo={sessionInfo} />} key={7} />
          <Route path="/contact" element={<Navigate to="/contact-us" />}  key={-8}/>
          <Route exact path="/contact-us" element={<ContactUs isMobile={isMobile} sessionInfo={sessionInfo} />} key={8} />
          <Route path="/terms" element={<Navigate to="/terms-and-conditions" />}  key={-9}/>
          <Route exact path="/terms-and-conditions" element={<TermsAndConditions isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={9} />
          <Route path="/start" element={<Navigate to="/start-debt-collecting" />}  key={-10}/>
          <Route exact path="/start-debt-collecting" element={<StartDebtCollecting isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={10} />
          <Route path="/" element={<DefaultPage isMobile={isMobile} sessionInfo={sessionInfo} debtCollecting={false} refreshSession={refreshSession} />}  key={12} />
          <Route path="*" element={<Navigate to="/" />}  key={14}/>
          {sessionInfo && <Route exact path="/dashboard/debt-review/applications/:debtId" element={<FinaliseDebtCollecting isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={15} />}
          {sessionInfo && <Route exact path="/dashboard" element={<MyDebtDashboard isMobile={isMobile} sessionInfo={sessionInfo} />}  key={17} />}
          <Route exact path="/collectionterms" element={<CollectionTermsAndConditions isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={19} />
          <Route path="/privacy" element={<Navigate to="/privacy-policy" />}  key={-20}/>
          <Route exact path="/privacy-policy" element={<PrivacyPolicyPage isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={20} />
          <Route exact path="/our-offering/legal-cover/personal-legal-cover/:coverId/quote" element={<StartLegalInsurance isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={21} />
          {sessionInfo && <Route exact path="/dashboard/legal-cover/personal-legal-cover/quotes/:quoteId" element={<FinaliseLegalInsurancePage isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={22} />}
          <Route exact path="/authenticate" element={<AuthenticatePage isMobile={true} show={true} refreshSession={refreshSession} sessionInfo={sessionInfo}/>} key={24} />
          <Route path="/offering/insurance/commercial" element={<Navigate to="/our-offering/legal-cover/commercial-legal-cover" />}  key={-25}/>
          <Route exact path="/our-offering/legal-cover/commercial-legal-cover" element={<CommercialLegakInsurancePage isMobile={isMobile} sessionInfo={sessionInfo} />} key={25} />
          <Route exact path="/our-offering/legal-cover/commercial-legal-cover/:coverId/quote" element={<StartCommercialLegalInsurancePage isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={26} />
          {sessionInfo && <Route exact path="/dashboard/legal-cover/commercial-legal-cover/quotes/:quoteId" element={<FinaliseCommercialLegalInsurancePage isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={27} />}
          <Route path="/offering/insurance" element={<Navigate to="/our-offering/legal-cover" />}  key={-28}/>
          <Route exact path="/our-offering/legal-cover" element={<InsuranceLandingPage isMobile={isMobile} sessionInfo={sessionInfo} />} key={28} />
          <Route exact path="/team-blog" element={<BlogPage isMobile={isMobile} sessionInfo={sessionInfo} refreshSession={refreshSession} />} key={29} />
        </Routes>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter menus={footerMenu} />
        </MKBox>
      </>
    );
}

export default Main;