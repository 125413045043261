// react native
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";

// Api
import {getUserDebtCollection} from '../../apis/api';
import FailModal from "../../components/Modals/FailModal";

import Step5 from "../StartDebtCollectingPage/sections/Step5";
import Step7 from "./sections/Step7";
import Step8 from "./sections/Step8";


function FinaliseDebtCollecting(props) {
  const [percentage, setPercentage] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepName, setCurrentStepName] = useState("");
  const [nextStepName, setNextStepName] = useState("");
  const [backdropClose, setBackdropClose] = useState(true);
  const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
  const [debtCollectionData, setDebtCollectionData] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  let { debtId } = useParams();

  useEffect(() => {
    if(props.currentStep) {
      setCurrentStep(props.currentStep);
    }
  }, [props.currentStep])

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;
   
    if(props.sessionInfo) {
      setSessionInfo(props.sessionInfo);
    }

    return () => { 
      cancel = true;
    }

  }, [props.sessionInfo]);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;

    setIsMobile(props.isMobile);

    return () => { 
      cancel = true;
    }
  }, [props.isMobile]);

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;
   
    if(sessionInfo && sessionInfo.sub && debtId) {
      getUserDebtCollection(debtId, sessionInfo.sub)
        .then((data) => {
          setDebtCollectionData(data);
          setBackdropClose(false);
        })
        .catch((err) => {
          setShowError(true);
          setBackdropClose(false);
        });
    }

    return () => { 
      cancel = true;
    }

  }, [debtId, sessionInfo]);

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;
   
    if(debtCollectionData && debtCollectionData.id) {
      const step = debtCollectionData.paymentStatus === "Paid" ? 7 : 5;
      setCurrentStep(step);

    }

    return () => { 
      cancel = true;
    }

  }, [debtCollectionData]);
  
  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
      <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Breadcrumbs
                routes={[
                  { label: "Profile", route: "/dashboard" },
                  { label: "Dashboard", route: "/dashboard" },
                  { label: "Debt Collecting" },
                ]}
              />
            
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 0, lg: 6 }}>
          <Container>
            <Grid container item>
              <MKBox
                width="100%"
                bgColor="white"
                borderRadius="xl"
                shadow="xl"
                mb={6}
                sx={{ overflow: "hidden" }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    position="relative"
                    px={0}
                    sx={{
                      backgroundImage: ({
                        palette: { gradients },
                        functions: { rgba, linearGradient },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.8),
                          rgba(gradients.dark.state, 0.8)
                        )}, url(${process.env.REACT_APP_CDN_Assets_Base}/pages/start-debt-collecting/background.jpg)`,
                      backgroundSize: "cover",
                    }}
                  >
                    <MKBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      height="100%"
                    >
                      <MKBox 
                        py={3} pr={12} pl={{ xs: 12, sm: 12 }} my="auto">
                        <MKTypography style={{"textAlign": "center"}} variant="h3" color="white" mb={3}>
                          {currentStepName}
                        </MKTypography>
                        <CircularProgressbar 
                          styles={buildStyles({
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'butt',
                            // Text size
                            textSize: '16px',
                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,
                            pathColor: "#9c0f18",
                            textColor: 'white',
                            trailColor: 'white'
                          })}
                          value={percentage} 
                          text={`${(currentStep > 7 ? "7" : currentStep)} of 7`}
                        />
                        <MKTypography style={{"textAlign": "center"}} justifyContent="center" alignItems="center" variant="body2" color="white" opacity={0.8} mb={2}>
                            &nbsp;
                        </MKTypography>
                        <MKTypography variant="h4" color="white" opacity={0.8} mb={2} style={{"textAlign": "center"}} >
                          {(currentStep < 7 ? "NEXT" : "OPTIONAL")}
                        </MKTypography>
                        <MKTypography variant="body2" color="white" opacity={0.8} mb={2} style={{"textAlign": "center"}} >
                          {nextStepName}
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    {debtCollectionData && currentStep === 5 && <Step5 
                      isMobile={props.isMobile} 
                      sessionInfo={sessionInfo} 
                      setPercentage={setPercentage} 
                      setCurrentStep={setCurrentStep} 
                      setCurrentStepName={setCurrentStepName}
                      setNextStepName={setNextStepName}
                      debtCollectId={debtCollectionData.id}
                    />}
                    {debtCollectionData && currentStep === 7 && <Step7 
                      isMobile={props.isMobile} 
                      sessionInfo={sessionInfo} 
                      setPercentage={setPercentage} 
                      setCurrentStep={setCurrentStep} 
                      setCurrentStepName={setCurrentStepName}
                      setNextStepName={setNextStepName}
                      debtCollectionData={debtCollectionData}
                    />}
                    {currentStep === 8 && <Step8 
                      isMobile={props.isMobile} 
                      sessionInfo={sessionInfo} 
                      setPercentage={setPercentage} 
                      setCurrentStep={setCurrentStep} 
                      setCurrentStepName={setCurrentStepName}
                      setNextStepName={setNextStepName}
                    />}
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <FailModal isMobile={isMobile} show={showError} header="Data download" text="We apologise, some data could not be downloaded. Please reload." />
    </>
  );
}

export default FinaliseDebtCollecting;