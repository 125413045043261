/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// @mui icons
import DeckIcon from '@mui/icons-material/Deck';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKButton from "../../../components/MaterialKit/MKButton";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import FilledInfoCard from "../../../components/Cards/InfoCards/FilledInfoCard";

import SuccessModal from "../../../components/Modals/SuccessModal";
import FailModal from "../../../components/Modals/FailModal";

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import {postLanding} from '../../../apis/comms';

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

const validationSchema = yup.object({
  name: yup.string()
    .required('Name is required'),
    phone: yup.string().matches(phoneRegExp, 'A valid mobile number is required'),
    email: yup.string().email('Must be a valid email').max(255)
      .when(['phone'], {                                                    
        is: (phone) => ((!phone || phone.length === 0) ),
        then: yup.string()                                                   
        .required('At least one contact detail is required'),                 
    }),
});

function Information(props) {
  const [show, setShow] = useState(props.show);
  const [showError, setShowError] = useState(props.showError);
  const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [backdropClose, setBackdropClose] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
      setShowError(props.showError);

    return () => { 
      cancel = true;
    }
  }, [props.showError]);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
      setShow(props.show);

    return () => { 
      cancel = true;
    }
  }, [props.show]);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
    setSessionInfo(props.sessionInfo);

    return () => { 
      cancel = true;
    }
  }, [props.sessionInfo]);

  useEffect(() => {
    let cancel = false;
    if(cancel)
        return;

    setIsMobile(props.isMobile);
    
    return () => { 
      cancel = true;
    }
  }, [props.isMobile])

  const goGetCover = (e) => {
    navigate(`/our-offering/legal-cover`, {replace: true});
  }


  return (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropClose}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
    <MKBox component="section" py={2}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={8}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12}>
                <MKBox mb={1}>
                  <FilledInfoCard icon={"gavel"} description="Simple, online, real time debt collection services" title="Debt Collection" />
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKBox mb={1}>
                  <FilledInfoCard icon={<DeckIcon/>} description="Protecting you against unplanned costs of getting professional legal assistance" title="Legal Cover" />
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKBox mb={1}>
                  <FilledInfoCard icon={<SignLanguageIcon/>} description="Empowering you to do it yourself" title="Power to YOU" />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <Grid container justifyContent="flex-start" spacing={10}>
              <Grid item xs={12}>
                <Card>
                  <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
                    <MKBox component="section" p={2} method="post">
                      <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                        <MKTypography variant="h3" mb={1}>
                          Tell us what you need?
                        </MKTypography>
                      </MKBox>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                          name: sessionInfo && sessionInfo.given_name ? sessionInfo.given_name : '',
                          phone: sessionInfo && sessionInfo.phone_number ? sessionInfo.phone_number : '',
                          email: sessionInfo && sessionInfo.email ? sessionInfo.email : '',  
                          message: ""
                        }}
                      validationSchema={validationSchema}
                      onSubmit={values => {
                        setBackdropClose(true);
                        setShow(false);
                        setShowError(false);
                        postLanding(values)
                          .then((data) => {
                            setBackdropClose(false);
                            setShow(true);
                            navigate(`/landing/success`, {replace: true});
                          })
                          .catch((err) => {
                            setBackdropClose(false);
                            setShowError(true);
                            navigate(`/landing/fail`, {replace: true});
                          });
                      }}
                    >
                    {(props) => (
                      <Form>
                        <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Your name" 
                              id="name" 
                              value={props.values.name}
                              onChange={props.handleChange('name')}
                              error={props.errors.name && Boolean(props.errors.name)}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Email" 
                              id="email" 
                              value={props.values.email}
                              onChange={props.handleChange('email')}
                              error={props.errors.email && Boolean(props.errors.email)}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Phone" 
                              id="phone" 
                              value={props.values.phone}
                              onChange={props.handleChange('phone')}
                              error={props.errors.phone && Boolean(props.errors.phone)}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Message" 
                              id="message" 
                              value={props.values.message}
                              onChange={props.handleChange('message')}
                              error={props.errors.message && Boolean(props.errors.message)}
                              fullWidth 
                              multiline
                              rows={4}
                              helperText="Optional"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKTypography variant="caption" color="error" align="center">
                            {props.errors.name && props.errors.name}
                            {!props.errors.name && props.errors.email && props.errors.email}
                            {!props.errors.name && !props.errors.email && props.errors.phone && props.errors.phone}
                            </MKTypography>
                          </Grid>
                          <Grid item xs={6}  sx={{ textAlign: "left" }}>
                            <MKButton type="button" onClick={goGetCover} variant="gradient" color="light" sx={{ height: "100%" }}>
                              Get Cover Now
                            </MKButton>
                          </Grid>
                          <Grid item xs={6}  sx={{ textAlign: "right" }}>
                            <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                              Get More Info
                            </MKButton>
                          </Grid>
                        </Grid>
                      </Form>
                      )}
                    </Formik>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
    <SuccessModal isMobile={isMobile} show={show} header="Message sent" text="Thank you for getting in touch. You can expect a response from us." />
    <FailModal isMobile={isMobile} show={showError} header="Message not sent" text="We appreciate you getting in touch, unfortunately we didnt receive your message." />
    </>
  );
}

export default Information;
