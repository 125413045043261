/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";

const validationSchema = yup.object({
  mayWeProceedWithLetterOfDemand: yup.string()
    .required("All fields are required")
});

function Step2(props) {
  const [stepValues, setStepValues] = useState({
    mayWeProceedWithLetterOfDemand: ""

});

useEffect(() => {
  let cancel = false;
  if(cancel)
    return;
  
  if(props.step2Values) {
    setStepValues(props.step2Values);
  }

  return () => { 
    cancel = true;
  }
}, [props.step2Values])

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(40);
    props.setCurrentStepName("Letter of Demand");
    props.setNextStepName("Choose Attorney");

    return () => { 
      cancel = true;
    }
  }, [props]);
  
  const goBack = (e) => {
    props.setCurrentStep(1);
  }
  
  return (
    <>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Letter of Demand
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            &nbsp;
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <Formik
            enableReinitialize={true}
            initialValues={stepValues}
            validationSchema={validationSchema}
            onSubmit={values => {
            props.setStep2Values(values);
            if(values.mayWeProceedWithLetterOfDemand === "yes") {
              props.setCurrentStep(3);
            }
            else {
              props.setCurrentStep(-2);
              props.setNextStepName("Letter of Demand");
            }
          }}
        >
        {(props) => (
          <Form>
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                  <MKInput 
                    label="May we proceed and send a Letter of Demand now?" 
                    id="mayWeProceedWithLetterOfDemand" 
                    value={props.values.mayWeProceedWithLetterOfDemand}
                    onChange={props.handleChange('mayWeProceedWithLetterOfDemand')}
                    error={props.errors.mayWeProceedWithLetterOfDemand && Boolean(props.errors.mayWeProceedWithLetterOfDemand)}
                    fullWidth
                    select
                  >
                    <MenuItem key={0} value="yes">
                      Yes
                    </MenuItem>
                    <MenuItem key={1} value="no">
                      No
                    </MenuItem>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="caption" color="error" align="center">
                    {props.errors.mayWeProceedWithLetterOfDemand && props.errors.mayWeProceedWithLetterOfDemand}
                  </MKTypography>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "left" }}>
                  <MKButton type="button" onClick={goBack} variant="gradient" color="light" sx={{ height: "100%" }}>
                    Back
                  </MKButton>
                </Grid>
                <Grid item xs={6}  sx={{ textAlign: "right" }}>
                  <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                    Next
                  </MKButton>
                </Grid>
              </Grid>
            </Form>
            )}
        </Formik>
        </MKBox>
      </MKBox>
    </>
  );
}

export default Step2;