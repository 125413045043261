// react native
import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import HorizontalPageCard from "../../components/Cards/PageCards/HorizontalPageCard";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";
import HorizontalQnATeamCard from "../../components/Cards/TeamCards/HorizontalQnATeamCard";
import FailModal from "../../components/Modals/FailModal";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { getAboutCollectors } from '../../apis/collectors';
function AboutCollectors(props) {
const [backdropClose, setBackdropClose] = useState(true);
const [showError, setShowError] = useState(false);
const [collectors, setCollectors] = useState(null);
const [isMobile, setIsMobile] = useState(props.isMobile);

useEffect(() => {
  let cancel = false;
  if(cancel)
    return;

  setIsMobile(props.isMobile);

  return () => { 
    cancel = true;
  }
}, [props.isMobile]);


  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;

  
    getAboutCollectors()
      .then((data) => {
        var dataSorted = data.sort(function(a, b) {
          return a.orderNo - b.orderNo;
        });
        setCollectors(dataSorted);
        setBackdropClose(false);
      })
      .catch((err) => {
        setShowError(true);
        setBackdropClose(false);
      });
  

  return () => { 
          cancel = true;
        }
  }, [props]);

  const about = [
    "We believe that you are in charge of your Debt Collection journey, therefore we place the POWER in your hands by letting you choose your own Collector.",
    "",
    "The Collectors range from seasoned Attorneys to Debt Collection agencies."
  ];

  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
              <Breadcrumbs
                routes={[
                  { label: "Collectors", route: "/collectors/the-collectors" },
                  { label: "The Collectors" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
          <MKBox component="section" py={{ xs: 0, lg: 6 }}>
              <Container>
                  <Grid container item>
                    <HorizontalPageCard
                      image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/the-collectors/about/background.jpg`}
                      name="The Collectors"
                      position={{ color: "info", label: "" }}
                      paragraphs={about}
                      flipImage={true}
                    />
                  </Grid>
              </Container>
          </MKBox>
          <br/>
          <MKBox 
            component="section" 
            pt={3} 
            pb={3}
            bgColor="dark"
            variant="gradient"
          >
          <Container>
            <Grid container spacing={2} justifyContent="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                  <MKTypography variant="h3" color="white">
                    Get to know The Collectors
                  </MKTypography>
              </Grid>
              {collectors && collectors.map((collector) => (<Grid item sx={{display:"flex"}} key={collector.id} md={12} lg={6}>
                <MKBox mb={1} sx={{display: "flex"}}>
                  <HorizontalQnATeamCard
                    image={collector.image}
                    name={`${collector.name} ${collector.surname}`}
                    position={{ color: "info", label: collector.companyName }}
                    questionsAnswers={JSON.parse(`${collector.questionAnswers}`)}
                    region={`${collector.region}`}
                  />
                </MKBox>
              </Grid>))}
            </Grid>
          </Container>
        </MKBox>
        <br/>
      </Card>
      <FailModal isMobile={isMobile} show={showError} header="Data download" text="We apologise, some data could not be downloaded. Please reload." />
    </>
  );
}

export default AboutCollectors;