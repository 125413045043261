// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import PricingCard from "../../components/Cards/PricingCard";
import MKTypography from "../../components/MaterialKit/MKTypography";

function CommercialLegakInsurancePage(props) {
  
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Breadcrumbs
                routes={[
                  { label: "Our Offering", route: "/" },
                  { label: "Legal Cover", route: '/our-offering/legal-cover' },
                  { label: "Commercial" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                <MKTypography variant="h3" color="dark">
                  Commercial Legal Cover
                </MKTypography>
                <MKTypography variant="body2" color="dark" opacity={0.8}>
                  Applicable to SMMEs, Sole Proprietors & Entrepreneurs.
                </MKTypography>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item sm={12} md={6}>
              <PricingCard 
                amountText="Plan A"
                amountSubText=""
                discountTerms="Premiums quoted are risk profile dependent" 
                link="/our-offering/legal-cover/commercial-legal-cover/a/quote"
                linkText="get a quote"
                product={
                {
                    name: "STANDARD", 
                    components: [
                        "Limit up to R150 000",
                        "Up to R 100 million annual turnover",
                        "Up to 100 Employees"
                    ],
                    includes: ["Labour matters", "Civil legal action", "Contravention of statutory and regulatory matters", "General legal advice"]
                }
                }/>
            </Grid>
            <Grid item sm={12} md={6}>
              <PricingCard 
                amountText="Plan B"
                amountSubText=""
                discountTerms="Premiums quoted are risk profile dependent" 
                link="/our-offering/legal-cover/commercial-legal-cover/b/quote"
                linkText="get a quote"
                product={
                {
                    name: "COMFORT", 
                    components: [
                        "Limit up to R250 000",
                        "Up to R 100 million annual turnover",
                        "Up to 100 Employees"
                    ],
                    includes: ["Labour matters", "Civil legal action", "Contravention of statutory and regulatory matters", "General legal advice", "Legal drafting of commercial contracts", "Compliance and risk management", ""]
                }
                }/>
            </Grid>
          </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}


export default CommercialLegakInsurancePage;
