/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// Material Kit 2 React components
import MKBox from "../MaterialKit/MKBox";
import MKTypography from "../MaterialKit/MKTypography";

function Breadcrumbs({ routes, ...rest }) {
  return (
    <MKBox bgColor={`${rest.bgColor ? rest.bgColor : ''}`} borderRadius="md" py={1} px={2} width="100%">
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="medium" />} {...rest}>
        {routes.map(({ label, route }) =>
          route ? (
            <MKTypography
              key={label}
              component={Link}
              to={route}
              variant="button"
              color={`${rest.color ? rest.color : 'white'}`}
              fontWeight="regular"
              opacity={0.8}
              sx={{
                "&:hover": {
                  color: ({ palette: { secondary } }) => secondary.main,
                },
              }}
            >
              {label}
            </MKTypography>
          ) : (
            <MKTypography color={`${rest.color ? rest.color : 'white'}`} key={label} variant="button" fontWeight="regular">
              {label}
            </MKTypography>
          )
        )}
      </MuiBreadcrumbs>
    </MKBox>
  );
}

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Breadcrumbs;
