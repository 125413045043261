/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import moment from 'moment';
import {saveAs} from "file-saver";

// @mui material components
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKButton from "../../../components/MaterialKit/MKButton";
import MKInput from "../../../components/MaterialKit/MKInput";

import {postUserDebtCollectionDoumentToken} from '../../../apis/comms';
import FailModal from "../../../components/Modals/FailModal";


function Step7(props) {
  const [debtCollectionData, setDebtCollectionData] = useState(props.debtCollectionData);
  const [backdropClose, setBackdropClose] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isMobile, setIsMobile] = useState(props.isMobile);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(100);
    props.setCurrentStepName("Your Documents");
    props.setNextStepName("Refer a Friend");
  }, [props]);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;

    setIsMobile(props.isMobile);

    return () => { 
      cancel = true;
    }
  }, [props.isMobile]);

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;
   
    setDebtCollectionData(props.debtCollectionData);

    return () => { 
      cancel = true;
    }

  }, [props.debtCollectionData]);


  const goReferFriend = (e) => {
    props.setCurrentStep(8);
  }

  const downloadFile = (e) => {
      fetch(debtCollectionData.letterOfDemandUri)
      .then(res => res.blob())
      .then(blob => saveAs(blob, 'overdue-letter-of-demand.pdf'));
  }

  const regenerateAndDownloadFile = (e) => {
      const payload = {
        UserId: props.sessionInfo.sub,
        DebtCollectId: debtCollectionData.id
      };
      setBackdropClose(true);
      postUserDebtCollectionDoumentToken(payload)
        .then((data) => {
                fetch(data.letterOfDemandUri)
                  .then(res => res.blob())
                  .then(blob => saveAs(blob, 'overdue-letter-of-demand.pdf'));
          setBackdropClose(false);
        })
        .catch((err) => {
          setShowError(true);
          setBackdropClose(false);
        });
  }
  
  return (
    <>
    <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Document
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Thank you for choosing us to help you.  We trust you have enjoyed your journey and we will see a favourable outcome.
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12}>
                  <MKInput 
                    label="Who owes you?" 
                    id="debtorName" 
                    value={debtCollectionData.debtorName}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Debtors email address" 
                    id="debtorEmail" 
                    value={debtCollectionData.debtorEmail}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="How much is outstanding?" 
                    id="debtOutstandingAmount" 
                    value={debtCollectionData.debtOutstandingAmount.toLocaleString('en-ZA', {
                      style: 'currency',
                      currency: 'ZAR'
                  })}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="How long has the debt been outstanding?" 
                    id="howLognHasDebtOutstanding" 
                    value={debtCollectionData.howLognHasDebtOutstanding}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="What describes your debt" 
                    id="whatDescribesYourDebt" 
                    value={debtCollectionData.whatDescribesYourDebt}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput 
                    label="Your Attorney" 
                    id="attorney" 
                    value={debtCollectionData.attorney}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MKInput 
                    label="Status" 
                    id="status" 
                    value={debtCollectionData.status}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MKInput 
                    label="Payment Status" 
                    id="paymentStatus" 
                    value={debtCollectionData.paymentStatus}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
            <Grid item xs={12}>
              {debtCollectionData.letterOfDemandUriExpires && <MKTypography variant="h6" color="info">
                You can download a copy here.<br/>
              </MKTypography>}
              {debtCollectionData.letterOfDemandUriExpires && moment.utc(debtCollectionData.letterOfDemandUriExpires).isSameOrAfter(moment()) && <>
              <MKButton onClick={downloadFile} type="button" variant="gradient" color="info" sx={{ height: "100%" }}>
                Download
              </MKButton>
              <MKTypography variant="body2" color="dark"><br/>Your Letter of Demand has been sent to the Debtor and Attorney.</MKTypography>
              </>}
              {debtCollectionData.letterOfDemandUriExpires && moment.utc(debtCollectionData.letterOfDemandUriExpires).isBefore(moment()) && <>
                <MKButton onClick={regenerateAndDownloadFile} type="button" variant="gradient" color="info" sx={{ height: "100%" }}>
                Re-Download
              </MKButton>
              <MKTypography variant="body2" color="dark"><br/>Your Letter of Demand has been sent to the Debtor and Attorney.</MKTypography>
              </>}
              {!debtCollectionData.letterOfDemandUriExpires && <>
                <MKTypography variant="h6" color="info">
                  Your Letter of Demand is still being generated...<br/>
                </MKTypography>
                <MKButton type="link" href={`/dashboard/debt-review/applications/${debtCollectionData.id}`} variant="gradient" color="info" sx={{ height: "100%" }}>
                  Refresh
                </MKButton>
                <MKTypography variant="body2" color="dark"><br/>Your Letter of Demand is being generated and emailed to your Debtor as well as your Attorney.</MKTypography>
              </>}
            </Grid>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>
              <MKButton type="link" href="/dashboard" variant="gradient" color="light" sx={{ height: "100%" }}>
                Close
              </MKButton>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <MKButton type="button" onClick={goReferFriend}  variant="gradient" color="dark" sx={{ height: "100%" }}>
                Refer a Friend
              </MKButton>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
      <FailModal isMobile={isMobile} show={showError} header="Data download" text="We apologise, some data could not be downloaded. Please reload." />
    </>
  );
}

export default Step7;