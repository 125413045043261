import parsePhoneNumber from 'libphonenumber-js';

import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from '../UserPool';

const AccountContext = createContext();

const Account = (props) => {

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if(user) {
                user.getSession((err, session) => {
                    if(err) {
                        reject();
                    }
                    else {
                        resolve(session);
                    }

                });
            }
            else {
                reject();
            }
        });
    }

    const signOut = async () => {

        return await new Promise((resolve) => {
            const user = UserPool.getCurrentUser();
    
            user.signOut();
            resolve();
        });
    }

    const signUp = async (UserName, Password, Name, Surname, Company, Telephone) => {
        const phoneNumber = parsePhoneNumber(Telephone, 'ZA');
        if (!phoneNumber) {
          throw new Error('Enter phone number in format 072 123 1234');
        }
        const fixedTelephone = phoneNumber.formatInternational().replace(/ /g, '');
        return await new Promise((resolve, reject) => {
            const userAttributes = [
                {
                    Name: 'email',
                    Value: UserName.toLowerCase().trim()
                },
                {
                    Name: 'name',
                    Value: (Name + " " + Surname)
                },
                {
                    Name: 'given_name',
                    Value: Name
                },
                {
                    Name: 'family_name',
                    Value: Surname
                },
                {
                    Name: 'nickname',
                    Value: Company
                },
                {
                    Name: 'phone_number',
                    Value: fixedTelephone
                }
            ];
            UserPool.signUp(UserName.toLowerCase().trim(), Password, userAttributes, null, (err, data) => {
                if(err) {
                    reject(err);
                }
                else {
                    resolve();
                }
            });
        });
    }

    const sendLoginLinkEmail = async (email) =>
        await new Promise((resolve, reject) => {
          fetch(process.env.REACT_APP_SignInApiGateway, {
              method: "post",
              headers: {
              "Content-Type": "application/json",
              },
              body: JSON.stringify({email})
          }).then((response) => {
              resolve(response.json());
          }).catch((reqErr) => {
              reject(reqErr);
          });
      });

    const authenticate = async (Username, Password) => {

      const authResponseData = await sendLoginLinkEmail(Username);

      // TODO: improve error response from lambda
      if (authResponseData.message.includes('link has been sent to')) {
        // successfully sent
      } else {
        // error sending - user probably doesn't exist
        throw new Error('User does not exist, please register.');
      }

    }

    const signUpAndAuthenticate = async (UserName, Name, Surname, IdNumber, Telephone) =>
        await new Promise((topResolve, topReject) => {
            authenticate(UserName)
            .then(data => {
                topResolve(data);
            })
            .catch(err => {
                const Password = window.btoa(Array.from(window.crypto.getRandomValues(new Uint8Array(30 * 2))).map((b) => String.fromCharCode(b)).join("")).replace(/[+/]/g, "").substring(0, 30);
                const phoneNumber = parsePhoneNumber(Telephone, 'ZA');
                if (!phoneNumber) {
                    topReject('Enter phone number in format 072 123 1234');
                }
                const fixedTelephone = phoneNumber.formatInternational().replace(/ /g, '');
                const userAttributes = [
                    {
                        Name: 'email',
                        Value: UserName.toLowerCase().trim()
                    },
                    {
                        Name: 'name',
                        Value: (Name + " " + Surname)
                    },
                    {
                        Name: 'given_name',
                        Value: Name
                    },
                    {
                        Name: 'family_name',
                        Value: Surname
                    },
                    {
                        Name: 'locale',
                        Value: IdNumber
                    },
                    {
                        Name: 'nickname',
                        Value: 'N/A'
                    },
                    {
                        Name: 'phone_number',
                        Value: fixedTelephone
                    }
                ];
                UserPool.signUp(UserName.toLowerCase().trim(), Password, userAttributes, null, (err, data) => {
                    if(err) {
                        topReject(err);
                    }
                    else {
                        authenticate(UserName)
                        .then(data => {
                            topResolve(data);
                        })
                        .catch(err => {
                            topReject(err);
                        });
                    }
                });
            });
        });

    const answerCustomChallenge = async (email, answer) => {
      return await new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email.toLowerCase().trim(),
            Pool: UserPool
        });
        const authDetails = new AuthenticationDetails({
            Username: email.toLowerCase().trim()
        });

        user.setAuthenticationFlowType('CUSTOM_AUTH');

        user.initiateAuth(authDetails, {
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
          customChallenge: function (challengeParameters) {
            user.sendCustomChallengeAnswer(answer, this);
          }
        });

      });

    }

    return (

        <AccountContext.Provider value={{authenticate, signUpAndAuthenticate, getSession, signOut, signUp, answerCustomChallenge}}>
            {props.children}
        </AccountContext.Provider>

    )
};

export {Account, AccountContext};
