// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";

function PrivacyPolicyPage(props) {


  const Styles={
    indentation:{
          marginTop: '0in', 
          marginRight: '0in', 
          marginBottom: '0in', 
          marginLeft: '20.9pt',
          textIndent: '-2.55pt', 
          border: 'none'
        }
}



  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Breadcrumbs
              routes={[
                { label: "Privacy Policy" },
              ]}
            />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
            <Grid container justifyContent="center" sx={{ textAlign: "left" }}>
            
              <Grid item xs={12} style={{ ol: { counterReset: "item -1" }, ol_li_ol: { counterReset: "item 0" } }}>
                <MKBox
                    p={1}
                    width="100%"
                    variant="gradient"
                    bgColor="info">
                  <MKTypography variant="h4" color="white">
                    Privacy policy
                  </MKTypography>
                </MKBox>
                  <br/>
                  <MKTypography variant="h6" color="dark">
                    BY ACCESSING
                    AND USING THIS APPLICATION, THE USER CONSENTS TO THE PROCESSING OF THEIR
                    PERSONAL INFORMATION BY Overdue (PTY) LTD T/A OVERDUE ON THE BASIS SET OUT
                    IN THIS PRIVACY POLICY. IF THE USER DOES NOT CONSENT, THE USER MUST IMMEDIATELY
                    STOP ACCESSING AND/OR USING THIS APP.
                </MKTypography>
                <br/>
                <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                  <MKTypography variant="h5" color="white">
                    1.  Introduction
                  </MKTypography>
                </MKBox>
          
                     <MKTypography  variant="body2">1.1
                     Overdue (Pty) Ltd
                      T/A Overdue collects, uses and, in some circumstances, shares the personal
                      information of Users in and through this app. </MKTypography>
                     <MKTypography  variant="body2">1.2
                     Overdue (Pty) Ltd
                      T/A Overdue respects the rights of Users whose personal information is
                      collected and used by it, including their right to protection against the
                      unlawful collection, retention, sharing and use of such personal information.</MKTypography>
                     <MKTypography  variant="body2">1.3
                    The purpose of this
                      Privacy Policy is to provide Users with information about the information
                      processing activities of Overdue (Pty) Ltd T/A Overdue and the manner in
                      which their rights are protected.</MKTypography>
                     <MKTypography  variant="body2">1.4
                    This Privacy Policy
                      shall serve as a blanket notification to Users about Overdue (Pty) Ltd T/A
                      Overdue's processing activities which will remain valid for as long as one has
                      an active account with Overdue (Pty) Ltd T/A Overdue. Users will not be
                      notified separately on each occasion that Overdue (Pty) Ltd T/A Overdue
                      processes the same personal information in the same way over the period.</MKTypography>
                     <MKTypography  variant="body2">1.5
                     Overdue (Pty) Ltd
                      T/A Overdue may amend the provisions of this Privacy Policy to comply with any
                      changes in the law, and/or pursuant to any changes to its information
                      processing activities or privacy practices. Such amendments will be published
                      on the app and will become operable from the date of such publication.</MKTypography>
                     <MKTypography  variant="body2">1.6
                    This Privacy Policy,
                      and the interpretation and enforcement thereof, will be governed by the laws of
                      the Republic of South Africa. </MKTypography>
                     <MKTypography  variant="body2">1.7
                    The provisions of
                      this Privacy Policy must, as far as possible, be incorporated into the
                      agreement between Overdue (Pty) Ltd T/A Overdue and Users. </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">2.
                      Definitions
                        and interpretation</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">2.1
                    In this Privacy
                      Policy, the following words bear the following meanings:</MKTypography>
                     <MKTypography   variant="body2">2.1.1
                    “app”
                      means the Overdue (Pty) Ltd T/A Overdue application software or any part
                      thereof;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.2
                    "consent"
                      means any voluntary, specific and informed expression of will in terms of which
                      permission is given by or on behalf of a User for the processing of their
                      personal information;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.3
                    ''direct
                      marketing"<b> </b>means to approach a data subject, either in person or by
                        mail or electronic communication, for the direct or indirect purpose of
                        promoting or offering to supply any goods or services to the data subject;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.4
                    "information
                      officer" means .the person registered as such by Overdue (Pty) Ltd T/A
                      Overdue with the Regulator from time to time;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.5
                    ''operator"
                      means an outside third party who processes personal information for or on
                      behalf of Overdue (Pty) Ltd T/A Overdue in terms of a contract or mandate;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2" >2.1.6
                    "personal
                      information" means any information linked to a User or information that can
                      identify a User, including but not limited to:</MKTypography>
                     <MKTypography   style={Styles.indentation}  variant="body2">2.1.6.1
                    information relating
                      to a User's gender, nationality, ethnic or social origin age, language,
                      financial status and creditworthiness;</MKTypography>
                     <MKTypography   style={Styles.indentation}  variant="body2">2.1.6.2
                    a User's identity
                      number, e-mail address, physical address, telephone number, location
                      information or online identifier;</MKTypography>
                     <MKTypography   style={Styles.indentation}  variant="body2">2.1.6.3
                    a User's personal
                      opinions, views or preferences;</MKTypography>
                     <MKTypography   style={Styles.indentation}  variant="body2">2.1.6.4
                    correspondence sent
                      by a User which is of a private or confidential nature; and</MKTypography>
                     <MKTypography   style={Styles.indentation}  variant="body2">2.1.6.5
                    the User's name if it
                      appears with other personal information relating to that User, or if the
                      disclosure of their name on its own would reveal further personal information
                      about that User;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.7
                    "POPI" means the
                      Protection of Personal Information Act 4 of 2013, including any regulations or
                      codes of conduct promulgated under it;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.8
                    "PROATIA" means the
                      Promotion of Access to Information Act 2 of 2000;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.9
                    "process or
                      processing" means, in relation to personal information, any operation or
                      activity or any set of operations, whether or not by automatic means,
                      including:</MKTypography>
                     <MKTypography   style={Styles.indentation}  variant="body2">2.1.9.1
                    the collection,
                      receipt, recording, organisation, collation, storage, updating or modification,
                      retrieval, alteration, consultation or use of that information;</MKTypography>
                     <MKTypography   style={Styles.indentation}  variant="body2">2.1.9.2
                    dissemination by
                      means of transmission, distribution or making available in any other form; or</MKTypography>
                     <MKTypography   style={Styles.indentation}  variant="body2">2.1.9.3
                    merging, linking, as
                      well as restriction, degradation, erasure or destruction of that information;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.10
                    “Regulator” means the
                      Information Regulator established in terms of POPI;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">2.1.11
                    "User" means a
                      visitor or user of this app, or any of the content or services associated with
                      this app or the application; and</MKTypography>
                     <MKTypography  variant="body2">2.2
                    Any reference in this
                      Privacy Policy to:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">2.2.1
                    the singular includes
                      the plural and <i>vice versa</i>;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">2.2.2
                    any one gender
                      includes the other genders, as the case may be;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">2.2.3
                    an Act, Regulation or
                      other law is to a South African Act, Regulation or other law, unless the
                      context indicates otherwise, and to the version of that law in force in South
                      Africa at the effective date of this Policy and includes any amendment or
                      re-enactment made to that law after the effective date of this Policy.</MKTypography>
                     <MKTypography  variant="body2">2.3
                    When calculating any
                      number <a name="30j0zll" />1of days for the purposes of this Policy, the first
                        day must be excluded and the last day of the relevant interval included, unless
                        the last day is not a business day, then the last day will be the next
                        succeeding business day.</MKTypography>
                     <MKTypography  variant="body2">2.4
                    The word
                      "include" means "include without limitation". Use of the word
                      "include" or "in particular" is for illustration or emphasis only and where
                      followed by specific examples must not be interpreted as limiting the meaning
                      of the general wording preceding it.</MKTypography>
                     <MKTypography  variant="body2">2.5
                    A requirement that
                      any notice, request, demand or other communication made in terms of this
                      Privacy Policy must be in writing will be met if it is in the form of a data
                      message as defined in the Electronic Communications and Transactions Act, No.
                      25 of 2002, and is accessible in a manner usable for subsequent reference.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">3.
                      responsible
                        party</MKTypography>
                    </MKBox>
                     
                     <MKTypography  variant="body2">3.1
                    Overdue(Pty) Ltd T/A Overdue will be the party who will be collecting and
                      processing a User's personal information and as such is designated as the
                      "responsible party" for the purposes of this Policy. </MKTypography>
                     
                     <MKTypography  variant="body2">3.2
                     Overdue (Pty) Ltd T/A Overdue's contact details are as follows:</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">3.2.1
                    Email
                      address: letschat@overdue.co.za</MKTypography>
                     
                     <MKTypography  variant="body2">3.3
                     Overdue (Pty) Ltd T/A Overdue may instruct third party operators from time to time
                      to undertake certain processing activities relating to the User's personal
                      information. </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">4.
                      WHAT
                        PERSONAL INFORMATION IS COLLECTED </MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">4.1
                     Overdue (Pty) Ltd T/A Overdue may collect the following personal information from
                      the User:</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">4.1.1
                    Name,
                      surname;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">4.1.2
                    Phone
                      number;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">4.1.3
                    ID
                      number;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">4.1.4
                    Email
                      address;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">4.1.5
                    Address; </MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">4.1.6
                    Personal
                      information specifically requested and required to perform Overdue (Pty) Ltd
                      T/A Overdue's contractual obligations to the User or to comply with one or more of its
                        obligations in law.</MKTypography>
                     
                     <MKTypography  variant="body2"><a name="_1fob9te" />4.2
                    The
                      supply of personal information by the User to Overdue (Pty) Ltd T/A Overdue
                      is voluntary and not mandatory. However, if the User refuses to supply any
                      personal information, certain consequences may naturally flow from such a
                      refusal, such as preventing Overdue (Pty) Ltd T/A Overdue from concluding or
                      performing any contract with the User, or preventing Overdue (Pty) Ltd T/A
                      Overdue from complying with one or more of its obligations in law. </MKTypography>
                     <MKTypography  style={{ marginTop: '0in', marginRight: '0in', marginBottom: '10.0pt', marginLeft: '28.35pt', border: 'none' }}></MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">5.
                      purpose/s
                        for collection and processing of personal information</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">5.1
                     Overdue (Pty) Ltd
                      T/A Overdue shall only collect a User's personal information for a specific,
                      explicitly defined and lawful purpose relating to a function or activity of
                      Overdue (Pty) Ltd T/A Overdue's business. </MKTypography>
                     <MKTypography  variant="body2">5.2
                    Such purposes may
                      include the following:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.1
                    to enter into a
                      contract with a User;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.2
                    to perform any
                      obligations under a contract with a User;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.3
                    to comply with a
                      legal obligation;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.4
                    to protect a
                      legitimate interest of a User (unless the User has specifically objected in
                      writing to all or some of the processing activities on reasonable grounds);</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.5
                    to pursue its own
                      legitimate interests or the legitimate interests of a third party who it is
                      sharing the information with (unless the User has specifically objected in
                      writing to all or some of the processing activities on reasonable grounds);</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.6
                    to process personal
                      information for direct marketing purposes (unless the User has opted out of
                      receiving any direct marketing material);</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.7
                    to
                      present content to the User in the most effective way for them and their
                      viewing device and browser;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.8
                    to
                      customise and display content including, but not limited to products, services,
                      articles, listings and advertisement to the User in a way that Overdue (Pty) Ltd T/A Overdue feels may interest the User or be most beneficial to
                        them;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.9
                    to
                      send content including, but not limited to products, services, articles,
                      listings and advertisement content to the User via email or other electronic
                      media, where the User has consented to be contacted by Overdue (Pty) Ltd T/A Overdue with such content;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.10
                    to
                      enable the User to voluntarily participate in interactive features on the app;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">5.2.11
                    to
                      notify the User about changes to the app or Overdue (Pty) Ltd T/A Overdue's
                      products, services or offerings.</MKTypography>
                     <MKTypography  variant="body2">5.3
                    If Overdue (Pty)
                      Ltd T/A Overdue intends to process a User's personal information for any other
                      purpose not listed in clause 5.2 or which is otherwise not automatically
                      permitted by law, it shall ensure that it obtains the User's written consent to
                      do so.</MKTypography>
                     <MKTypography  variant="body2">5.4
                     Overdue (Pty) Ltd
                      T/A Overdue will not sell a User's personal information to any third party
                      without the prior written consent of the User.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">6.
                      collection
                        directly from user</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">6.1
                     Overdue (Pty) Ltd
                      T/A Overdue shall, as far as possible, always collect personal information
                      about a User directly from the User, except in the following circumstances:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">6.1.1
                    Where personal
                      information is collected from a public record, or from another source if the
                      information has already been made public by the User;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">6.1.2
                    where the User has
                      given their written consent to Overdue (Pty) Ltd T/A Overdue to collect
                      their information from another source;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">6.1.3
                    where the collection
                      of a User's personal information from another source will not prejudice any of
                      the User's legitimate interests;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">6.1.4
                    where the collection
                      of personal information from another source is necessary to maintain Overdue
                      (Pty) Ltd T/A Overdue's legitimate interests or those of any third party it
                      intends sharing the information with;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">6.1.5
                    where the collection
                      of personal information directly from the User would prejudice the purpose for
                      the collection;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">6.1.6
                    where the collection
                      of personal information directly from the User is not reasonably practicable in
                      the circumstances. </MKTypography>
                     <MKTypography  variant="body2">6.2
                    If Overdue (Pty)
                      Ltd T/A Overdue collects personal information from a source other than the
                      User, it shall record in writing the details of that source, including the full
                      names and contact details of that source where applicable.</MKTypography>
                     <MKTypography  variant="body2">6.3
                    The User hereby
                      consents to Overdue (Pty) Ltd T/A Overdue requesting and obtaining credit
                      information pertaining to the User from any reputable credit reporting agency
                      or institution for the purpose of concluding any transaction for the sale of
                      goods or services to the User on this app.</MKTypography>
                     <MKTypography  variant="body2">6.4
                    Personal information
                      may be collected from or supplied by the User in any of the following ways: </MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.1
                    during
                      the process of registering/signing up as a member/user on this app; </MKTypography>
                     <MKTypography  style={Styles.spacing}><b></b></MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.2
                    provided
                      by the User as part of their profile pages on the app;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.3
                    provided
                      by the User when applying for or buying insurance cover on the app;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.4
                    when
                      subscribing to a service, entering into a competition or promotion, or
                      participating in a survey offered on this app;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.5
                    when
                      submitting a claim on the app;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.6
                    when
                      posting a comment, review, reply or recommendation on this app;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.7
                    when
                      requesting further services or information from Overdue (Pty) Ltd T/A
                      Overdue;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.8
                    when
                      contacting Overdue (Pty) Ltd T/A Overdue to report a problem with the app or
                      for any other reason;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">6.4.9
                    when
                      completing any forms on the app.</MKTypography>
                     
                     <MKTypography    variant="body2">6.5
                    The User
                      may visit the app without providing any personal information. However, the
                      app's servers may still collect technical information regarding the use of the
                      app, which is aggregated for analytical purposes, technical maintenance and for
                      improving the content offered on the app. Such information may include details
                      of the User's visit, information about the User's computer or viewing device,
                      including IP (Internet Protocol) address, operating system and browser type,
                      the User's location, and usage information. An individual User will not be
                      identified from or by this information and Overdue (Pty) Ltd T/A Overdue is
                      entitled to copy, distribute or otherwise use such information without
                      limitation.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">7.
                      Cookies</MKTypography>
                    </MKBox>
                     <MKTypography    variant="body2">7.1
                    “Cookies”
                      are small text files transferred by a webserver to a User's hard drive and
                      thereafter stored on their computer or viewing device. The types of
                      information a Cookie collects includes a User's username, the date and time of
                      their visits to the app, their browsing history and preferences.</MKTypography>
                     
                     <MKTypography  variant="body2">7.2
                     Overdue (Pty) Ltd T/A Overdue may use Cookies on this app to:</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">7.2.1
                    distinguish
                      one User from another on the app;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">7.2.2
                    remember
                      the User's last session when they return to the app;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">7.2.3
                    estimate
                      the app's audience size and usage patterns;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">7.2.4
                    store
                      information about the User's preferences, which allows Overdue (Pty) Ltd T/A
                      Overdue to customize the app and content according to the Users individual
                      preferences; and</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">7.2.5
                    speed up
                      searches on the app.</MKTypography>
                     <MKTypography  style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.75in', border: 'none' }}></MKTypography>
                     <MKTypography  variant="body2">7.3
                    The
                      provisions of this clause are only applicable to Cookies used by Overdue
                      (Pty) Ltd T/A Overdue. In some instances, third-party service providers may use
                      Cookies on the app. Overdue (Pty) Ltd T/A Overdue cannot and does not
                      control or access Cookies used by third party service providers and takes no
                      responsibility therefore.</MKTypography>
                     
                     <MKTypography variant="body2">7.4
                    The User
                      has the right and ability to either accept or decline the use of Cookies when
                      accessing the app on their viewing device, whether they are logged in as a
                      member/user, or simply casually visiting the app. However, declining the use of
                      Cookies may limit a User's access to certain features on the app.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">8.
                      GENERAL
                        CONDITIONS FOR PROCESSING PERSONAL INFORMATION</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">8.1
                     Overdue (Pty) Ltd
                      T/A Overdue shall comply with all laws, contracts or regulations when it
                      processes a User's personal information.</MKTypography>
                     <MKTypography  variant="body2">8.2
                     Overdue (Pty) Ltd
                      T/A Overdue shall not act unreasonably when processing a User's personal
                      information. This means that it will collect and process a User's personal
                      information in a way that the User can reasonably expect and in a way that is
                      fair. </MKTypography>
                     <MKTypography  variant="body2">8.3
                     Overdue (Pty) Ltd
                      T/A Overdue shall respect the User's right to privacy at all times. If there is
                      another way in which it can achieve the same goal without posing any risk of
                      harm to the privacy rights of the User, then it will choose that option.</MKTypography>
                     <MKTypography  variant="body2">8.4
                    Similarly, if Overdue (Pty) Ltd T/A Overdue needs to process personal information but there are
                      less privacy-invasive methods of collecting, using and sharing that
                      information, then it will use those methods. </MKTypography>
                     <MKTypography  variant="body2">8.5
                     Overdue (Pty) Ltd
                      T/A Overdue shall ensure that the personal information that is collected and
                      processed is and remains relevant to the identified purpose/s for such processing,
                      and that such information is and remains adequate, but not excessive, for
                      achieving the identified purpose/s.</MKTypography>
                     <MKTypography  variant="body2">8.6
                    If there are any
                      alternative ways to achieve the identified purpose/s without processing
                      personal information, Overdue (Pty) Ltd T/A Overdue shall not process that
                      personal information.</MKTypography>
                     <MKTypography  variant="body2">8.7
                     Overdue (Pty) Ltd
                      T/A Overdue shall ensure that the processing activities it chooses to apply are
                      proportionate to achieving the identified purpose/s and that no less privacy
                      invasive measures are available to achieve the same purpose/s.</MKTypography>
                     <MKTypography  variant="body2">8.8
                     Overdue (Pty) Ltd
                      T/A Overdue shall ensure that, regardless of the stated purpose/s for
                      processing personal information, the rights and interests of Users will not be
                      unnecessarily prejudiced or infringed, unless it cannot be avoided, and then in
                      such cases, it shall ensure that its own rights and/or interests justify such
                      prejudice or infringement taking place.</MKTypography>
                     <MKTypography  variant="body2">8.9
                    Once Overdue (Pty)
                      Ltd T/A Overdue has achieved the purpose for the collection of the User's
                      personal information, it will destroy or delete such information, unless the
                      User has directed otherwise in writing, or Overdue (Pty) Ltd T/A Overdue is
                      required by law to retain the information for a longer period of time. </MKTypography>
                     <MKTypography  variant="body2">8.10
                    If Overdue (Pty)
                      Ltd T/A Overdue no longer needs to process personal information to achieve the
                      purpose originally specified, it will stop using that information. </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">9.
                      DISCLOSURE
                        AND SHARING OF PERSONAL INFORMATION </MKTypography>
                    </MKBox>
                     <MKTypography   variant="body2">9.1
                     Overdue (Pty) Ltd T/A Overdue may, in the course of providing any content or
                      services on this app, or for the purposes of concluding or performing any sale
                      or other transaction with a User, share certain personal information with third
                      party operators who perform certain processing activities on behalf of Overdue (Pty) Ltd T/A Overdue. </MKTypography>
                     
                     <MKTypography  variant="body2">9.2
                    The
                      information shared and the categories of third party operators, such as,
                      without limitation, brokers, insurers and reinsurers with whom it is shared
                      will always be notified on the app.</MKTypography>
                     
                     <MKTypography  variant="body2">9.3
                     Overdue (Pty) Ltd T/A Overdue may also share
                        aggregated information about Users of this app and their usage patterns. Overdue (Pty) Ltd T/A Overdue may also use such aggregated information to help
                        advertisers target specific audiences. Such aggregated information will be
                        de-identified and the User's personal information will not be disclosed. </MKTypography>
                     
                     <MKTypography   variant="body2">9.4
                    Other
                      than as stated in clause 9.1 and 9.3, Overdue (Pty) Ltd T/A Overdue shall
                      not share a User's personal information with any third parties unless it has
                      the User's express consent to do so.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">10.
                      USER'S
                        RIGHTS IN RELATION TO THE PROCESSING OF THEIR PERSONAL INFORMATION</MKTypography>
                    </MKBox>
                     <MKTypography   variant="body2">10.1
                    Users
                      shall have the following rights in relation to the processing of their personal
                      information:</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">10.1.1
                    to access
                      and correct any personal information held by Overdue (Pty) Ltd T/A Overdue
                      about them;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">10.1.2
                    to object
                      to the processing of their information; and</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">10.1.3
                    to lodge
                      a complaint with the Regulator. </MKTypography>
                     
                     <MKTypography   variant="body2">10.2
                    Users may
                      make a request in terms of clause 10.1.1 by following the process for making
                      such a request as set out in Overdue (Pty) Ltd T/A Overdue's PROATIA manual.
                      </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">11.
                      Further
                        processing</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">11.1
                     Overdue (Pty) Ltd
                      T/A Overdue shall not process a User's personal information for any purpose not
                      previously specified except in the following circumstances:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">11.1.1
                    where the User has
                      consented to such further processing;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">11.1.2
                    where the further
                      processing is necessary for the exercise of any contractual rights or the
                      fulfillment of any obligations between Overdue (Pty) Ltd T/A Overdue and the
                      User;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">11.1.3
                    where the further
                      processing activities are linked to or compatible with the original purpose;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">11.1.4
                    where the further
                      processing is necessary for the prevention, detection, investigation, prosecution
                      and punishment of an offence;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">11.1.5
                    where the further
                      processing is necessary to enforce any law;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">11.1.6
                    where the further
                      processing is necessary for the conduct of legal proceedings in any court or
                      tribunal that have commenced or are reasonably contemplated;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">11.1.7
                    where the further
                      processing is necessary to prevent or mitigate a serious and imminent threat to
                      the life or health of the User or another individual;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">11.1.8
                    where the further
                      processing is necessary for historical, statistical or research purposes.</MKTypography>
                     <MKTypography  variant="body2">11.2
                     Overdue (Pty) Ltd
                      T/A Overdue shall ensure that if it intends processing personal information for
                      other purposes not previously specified, it shall notify the User of such
                      further purposes and the possible consequences of the intended further
                      processing for the User. </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">12.
                      Accuracy,
                        correctness and completeness of personal information</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">12.1
                     Overdue (Pty) Ltd
                      T/A Overdue shall take reasonably practicable steps to ensure that the personal
                      information kept by it about Users is complete, accurate, not misleading and is
                      updated when necessary. </MKTypography>
                     <MKTypography  variant="body2">12.2
                    However, if a User is
                      aware of any personal information in Overdue (Pty) Ltd T/A Overdue's custody
                      that is incorrect, inaccurate or which needs to be updated, the User must make
                      a written request to Overdue (Pty) Ltd T/A Overdue's information officer at
                      letschat@overdue.co.za to update or correct the relevant information.</MKTypography>
                     <MKTypography  variant="body2">12.3
                    If a User has
                      contested the accuracy of any personal information being used by Overdue
                      (Pty) Ltd T/A Overdue, it shall immediately stop using that information until
                      its accuracy has been verified.</MKTypography>
                     <MKTypography  variant="body2">12.4
                     Overdue (Pty) Ltd
                      T/A Overdue reserves its right to only adhere to a request from a User in terms
                      of clause 12.2 if the correction or updating of that information will result in
                      the personal information being correct and accurate.</MKTypography>
                     <MKTypography  variant="body2">12.5
                    Where personal
                      information that has been shared by Overdue (Pty) Ltd T/A Overdue with a
                      third party is subsequently updated or corrected, Overdue (Pty) Ltd T/A
                      Overdue shall ensure that all third parties, with whom that information was
                      shared, receives the updated and/or corrected version of the information as
                      soon as it has been updated and/or corrected. </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">13.
                      SECURITY
                        SAFEGUARDS</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">13.1
                     Overdue (Pty) Ltd
                      T/A Overdue is committed to protecting the personal information in its custody
                      against any loss of, damage to or unauthorised destruction of that information,
                      and to prevent any unauthorised parties from accessing that information.</MKTypography>
                     <MKTypography  variant="body2">13.2
                     Overdue (Pty) Ltd
                      T/A Overdue takes steps to continually identify and document any risks to the
                      personal information it has in its possession or under its control and that
                      appropriate security safeguards are in place against those risks.</MKTypography>
                     <MKTypography  variant="body2">13.3
                     Overdue (Pty) Ltd
                      T/A Overdue shall ensure that in any contracts entered into with third party
                      operators who process personal information on Overdue (Pty) Ltd T/A
                      Overdue's behalf, include the following obligations:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">13.3.1
                    the operator shall
                      not process any personal information without Overdue (Pty) Ltd T/A Overdue's
                      knowledge and authority;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">13.3.2
                    the operator shall
                      treat all personal information given to it as confidential and shall not
                      disclose it to any unauthorised third parties;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">13.3.3
                    the operator shall
                      establish and maintain adequate security measures which are the same or offer
                      similar protection over the personal information as that employed by s;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">13.3.4
                    the operator shall
                      notify Overdue (Pty) Ltd T/A Overdue immediately where there are reasonable
                      grounds to believe that any personal information has been leaked to or accessed
                      by any unauthorised person;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">13.3.5
                    if the operator is
                      situated in another country, it must comply with the data protection laws in
                      that country and be able to provide verification that it is so compliant;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">13.3.6
                    if an operator is
                      legally obliged to disclose any personal information processed by them on Overdue (Pty) Ltd T/A Overdue's behalf to other parties, it must notify Overdue
                      (Pty) Ltd T/A Overdue beforehand to enable Overdue (Pty) Ltd T/A Overdue
                      and/or individual Users to protect their rights if necessary. </MKTypography>
                     <MKTypography  variant="body2">13.4
                    Users must maintain
                      the secrecy of any passwords used to gain access to this app and should change
                      such passwords regularly.</MKTypography>
                     <MKTypography  variant="body2">13.5
                     Overdue (Pty) Ltd
                      T/A Overdue shall ensure that all personal information on its systems is
                      properly backed-up and that back-up copies are stored separately from the live
                      files.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">14.
                      NOTIFICATION
                        OF BREACH OF SECURITY</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">14.1
                    If personal
                      information about a User is inadvertently leaked or Overdue (Pty) Ltd T/A
                      Overdue's security has been unlawfully breached by any unauthorised party,
                      Overdue (Pty) Ltd T/A Overdue shall immediately identify the relevant Users
                      who may be affected by the security breach, and shall contact them at their
                      last known email address or contact details or by the quickest means possible.</MKTypography>
                     <MKTypography  variant="body2">14.2
                     Overdue (Pty) Ltd
                      T/A Overdue shall provide sufficient information to the User to allow him or
                      her to take the necessary protective measures against the potential
                      consequences of the compromise, or shall advise Users of the steps to be taken
                      by them and the possible consequences that may ensue from the breach for them.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">15.
                      Decisions
                        based on personal information processed</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">15.1
                    If Overdue (Pty)
                      Ltd T/A Overdue is required to make a decision about a User using any personal
                      information that has been obtained, it shall ensure that a record of such
                      information and the decision made is kept for a reasonable period of time to
                      give the User an opportunity to request access to that record.</MKTypography>
                     <MKTypography  variant="body2">15.2
                     Overdue (Pty) Ltd
                      T/A Overdue shall allow a User a reasonable opportunity to make representations
                      before any decision is made solely on the basis of the personal information
                      processed, if that decision will affect the legal position of the User, or will
                      otherwise adversely affect them in some manner or form.</MKTypography>
                     <MKTypography  variant="body2">15.3
                     Overdue (Pty) Ltd
                      T/A Overdue shall always ensure that the underlying logic behind any decision
                      made pursuant to the automated processing of personal information is sound and
                      that this underlying logic can be communicated to the User to enable them to
                      make representations.</MKTypography>
                     <MKTypography  variant="body2">15.4
                    If Overdue (Pty)
                      Ltd T/A Overdue has made a decision based on incorrect personal information, it
                      shall immediately revisit that decision as soon as it receives notice or
                      becomes aware of the error or inaccuracy of that information.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">16.
                      Linked
                        third party apps</MKTypography>
                    </MKBox>
                     <MKTypography   variant="body2">16.1
                    This app
                      may contain links or references to other apps, including those of advertisers
                      (“<b>third party apps</b>“) which are not under Overdue (Pty) Ltd T/A
                        Overdue's control.</MKTypography>
                     
                     <MKTypography   variant="body2">16.2
                    The
                      provisions of this Privacy Policy are not applicable to third party apps or
                      websites and Overdue (Pty) Ltd T/A Overdue shall not be responsible for the
                        information processing practices and/or privacy policies of those third party
                        apps, websites or the cookies that those apps or websites may use.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">17.
                      DIRECT
                        MARKETING </MKTypography>
                    </MKBox>
                     <MKTypography   variant="body2">17.1
                    The User
                      hereby consents to the processing of their personal information for the purpose
                      of direct marketing by means of electronic communications including automatic
                      calling machines, facsimile machines, SMS's or electronic mail.</MKTypography>
                     
                     <MKTypography  variant="body2">17.2
                    Where a
                      User is a pre-existing customer of Overdue (Pty) Ltd T/A Overdue, Overdue
                      (Pty) Ltd T/A Overdue shall be entitled, without the User's consent, to sending
                      electronic communications to the User for the purpose of marketing similar
                      products or services offered by Overdue (Pty) Ltd T/A Overdue. </MKTypography>
                     
                     <MKTypography  variant="body2">17.3
                    The User
                      may object, free of charge, and without unnecessary formality, to the use of
                      their details either when the information was first collected from them or when
                      each subsequent electronic communication is sent to them by Overdue (Pty)
                      Ltd T/A Overdue.</MKTypography>
                     
                     <MKTypography   variant="body2">17.4
                    The User
                      can opt out of receiving further marketing communications by un-checking
                      certain boxes on the forms used on the app to collect their personal
                      information, or by contacting Overdue (Pty) Ltd T/A Overdue at
                      letschat@overdue.co.za.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">18.
                      Children's
                        personal information</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">Overdue (Pty) Ltd T/A Overdue shall not process
                      any personal information relating to a person under the age of 18 years unless
                      it has obtained consent from that person's parent or legal guardian. If this
                      app is being accessed by the parent or guardian of a child under the age of 18
                      years, and personal information pertaining to that child is being provided by
                      the parent or guardian, then they hereby expressly consent to Overdue (Pty)
                      Ltd T/A Overdue processing such information according to the further provisions
                      of this Privacy Policy. </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">19.
                      cross
                        border Transfers of personal information </MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">19.1
                     Overdue (Pty) Ltd
                      T/A Overdue may transfer personal information to another country in the
                      following circumstances:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">19.1.1
                    the transfer is
                      necessary for the performance of a contract that Overdue (Pty) Ltd T/A
                      Overdue has with the User;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">19.1.2
                    the transfer is
                      necessary for the conclusion or performance of a contract with a third party
                      which is for the benefit of or in the interest of the User;</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">19.1.3
                    the transfer is
                      otherwise for the benefit of the User; or</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">19.1.4
                    the User has
                      consented to the transfer of their information. </MKTypography>
                     <MKTypography   variant="body2">19.2
                    If Overdue (Pty) Ltd T/A Overdue is required to transfer personal information from
                      South Africa to a third party in a foreign country, it shall ensure that the
                      third party is subject to a law, binding code of conduct or contract which
                      effectively upholds principles for the reasonable processing of personal
                      information which are substantially similar to the data protection offered in
                      the Republic of South Africa. </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">20.
                      Retention
                        of information</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">20.1
                     Overdue (Pty) Ltd
                      T/A Overdue will keep a record of any personal information collected for no
                      longer than is necessary to achieve the specific purpose for which it collected
                      such information in the first place unless:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">20.1.1
                    It is required by law
                      to keep a record of such information for a longer period of time; or</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">20.1.2
                    It needs to keep a
                      record of such information for another lawful purpose; or</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">20.1.3
                    It has a contractual
                      obligation to keep a record of such information; or</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">20.1.4
                    The User has
                      consented to their information being kept for a longer period.</MKTypography>
                     <MKTypography  variant="body2">20.2
                     Overdue (Pty) Ltd
                      T/A Overdue may, if it has de-identified personal information, kept such
                      information for historical, statistical or research purposes. Overdue (Pty)
                      Ltd T/A Overdue shall ensure that appropriate safeguards are in place to
                      prevent those records from being used for any other purposes, or against the
                      information being re-identified.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">21.
                      Returning,
                        destroying or deleting personal information</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">21.1
                    Where Overdue
                      (Pty) Ltd T/A Overdue is no longer authorised to retain a record of any
                      personal information, it shall either:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">21.1.1
                    ensure
                      that the information is permanently destroyed or deleted as soon as reasonably
                      practicable; or </MKTypography>
                     
                     <MKTypography style={Styles.indentation}  variant="body2" >21.1.2
                    return
                      the information to the User or transfer it to a third party, if requested by
                      the User in writing to do so. </MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">22.
                      Consent</MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">22.1
                    The User hereby
                      consents to the processing of their personal information in terms of the
                      provisions of this Privacy Policy. </MKTypography>
                     <MKTypography  variant="body2">22.2
                    The User acknowledges
                      and agrees that such consent has been given voluntarily after the User has read
                      and understood the provisions of this Privacy Policy, in particular, regarding
                      the following:</MKTypography>
                     <MKTypography  style={Styles.indentation}  variant="body2">22.2.1
                    the types
                      of personal information to be processed;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">22.2.2
                    the
                      specific processing activities to be undertaken;</MKTypography>
                     
                     <MKTypography  style={Styles.indentation}  variant="body2">22.2.3
                    the
                      specific purpose/s for such processing; and </MKTypography>
                     
                     <MKTypography style={Styles.indentation}  variant="body2" >22.2.4
                    the
                      possible consequences for the User that may arise from such processing. </MKTypography>
                     <MKTypography  variant="body2">22.3 Should a User wish to withdraw any
                      consent previously given by the User, they must notify Overdue (Pty) Ltd T/A
                      Overdue's information officer in writing.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">23.
                      LODGING
                        AN OBJECTION </MKTypography>
                    </MKBox>
                     <MKTypography  variant="body2">23.1
                    A User may, on
                      reasonable grounds, object to the processing of their personal information at
                      any time after that processing has started. </MKTypography>
                     <MKTypography  variant="body2">23.2
                    If a User wishes to
                      object to the processing of their personal information, they must send written
                      notice of their objection to Overdue (Pty) Ltd T/A Overdue's information
                      officer, together with their reasons for doing so.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">24.
                      Choice of
                        Law</MKTypography>
                    </MKBox>
                     <MKTypography variant="body2">This Privacy Policy
                      shall be governed and interpreted in accordance with the laws of the Republic
                      of South Africa and the User agrees to submit to the exclusive jurisdiction of
                      the courts of the Republic South Africa.</MKTypography>
                    <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">25.
                      AMENDMENT
                        OF THIS PRIVACY POLICY </MKTypography>
                    </MKBox>
                     <MKTypography   variant="body2">25.1
                     Overdue (Pty) Ltd T/A Overdue reserves the right to change, update, add, remove
                      and/or amend any of the provisions of this Privacy Policy from time to time.
                      Such changes, updates, additions, removals or amendments will become effective
                      from the date of their publication on this app.</MKTypography>
                     
                     <MKTypography  variant="body2">25.2
                    It is the
                      User's obligation to periodically check the provisions of this Privacy Policy
                      for any such changes, updates, additions, removals or amendments. </MKTypography>
                     
                     <MKTypography   variant="body2">25.3
                    The
                      User's continued use of this app following any changes, updates, additions,
                      removals or amendments to this Privacy Policy will be considered notice of the
                      User's acceptance to abide by and be bound by this Privacy Policy, as amended.</MKTypography>
                      <MKBox
                  p={1}
                  width="100%"
                  variant="gradient"
                  bgColor="info">
                      <MKTypography variant="h5" color="white">Contact</MKTypography>
                    </MKBox>
                     <MKTypography   variant="body2" >For more information
                      on your rights to privacy over your information, or the information processing
                      activities of Overdue (Pty) Ltd T/A Overdue, please do not hesitate to
                      contact us directly on letschat@overdue.co.za.</MKTypography>
                    
                  
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default PrivacyPolicyPage;