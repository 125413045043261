const baseURL = process.env.REACT_APP_Collectors_Base_Url;
const apiKey = process.env.REACT_APP__Api_Key;

export const getCollectors = async () =>
  await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/collectors`, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "x-functions-key": apiKey,
            }
          }).then((response) => {
            resolve(response.json());
          }).catch((reqErr) => {
              reject(reqErr);
          });
        });

export const getAboutCollectors = async () =>
  await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/collectors/about`, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "x-functions-key": apiKey,
            }
          }).then((response) => {
            resolve(response.json());
          }).catch((reqErr) => {
              reject(reqErr);
          });
        });