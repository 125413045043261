/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

// Formik
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKInput from "../../../components/MaterialKit/MKInput";
import MKButton from "../../../components/MaterialKit/MKButton";
import SuccessModal from "../../../components/Modals/SuccessModal";
import FailModal from "../../../components/Modals/FailModal";

import {postContactUs} from '../../../apis/comms';

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

const validationSchema = yup.object({
  name: yup.string()
      .required('Name is required'),
      mobile: yup.string().matches(phoneRegExp, 'A valid mobile number needs to be captured'),
  landLine: yup.string().matches(phoneRegExp, 'A valid land line number needs to be captured'),
  email: yup.string().email('Must be a valid email').max(255)
      .when(['mobile', 'landLine'], {                                                    
        is: (mobile, landLine) => ((!mobile || mobile.length === 0) && (!landLine || landLine.length ===0)),
        then: yup.string()                                                   
        .required('At least one contact detail is required'),                 
                    }),
  message: yup.string()
      .required('Message is required'),
});

function Contact(props) {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);
  const [backdropClose, setBackdropClose] = useState(false);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
    setIsMobile(props.isMobile);
    setSessionInfo(props.sessionInfo);

    return () => { 
      cancel = true;
    }
  }, [props.isMobile, props.sessionInfo]);

  return (
    <>
    <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropClose}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${process.env.REACT_APP_CDN_Assets_Base}/pages/contact-us/background.jpg)`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact us
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Speak to one of our experts
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        +27 (0)861 086 000
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <AlternateEmailIcon/>
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        letschat@overdue.co.za
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Greenville, De Villiers Drive, Durbanville, 7550
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        PO Box 999, Durbanville, 7551
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                      
                      <MKButton type="link" href="https://www.facebook.com/fetchplease" target="_blank" variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton type="link" href="https://twitter.com/OverdueLegal" target="_blank" variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton type="link" href="https://www.linkedin.com/company/84036423/admin" target="_blank" variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-linkedin" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="section" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Get in touch
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Would you like to know more about our range of services or how we can best support you?  Simply fill out the form below and we will contact you.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        name: sessionInfo && sessionInfo.given_name ? sessionInfo.given_name : '',
                        surname: sessionInfo && sessionInfo.family_name ? sessionInfo.family_name : '',
                        company: sessionInfo && sessionInfo.nickname ? sessionInfo.nickname : '',
                        mobile: sessionInfo && sessionInfo.phone_number ? sessionInfo.phone_number : '',
                        landLine: '',
                        email: sessionInfo && sessionInfo.email ? sessionInfo.email : '',
                        message: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                      setBackdropClose(true);
                      setShow(false);
                      setShowError(false);
                      postContactUs(values)
                        .then((data) => {
                          setBackdropClose(false);
                          setShow(true);
                        })
                        .catch((err) => {
                          setBackdropClose(false);
                          setShowError(true);
                        });
                    }}
                  >
                  {(props) => (
                    <Form>
                      <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                        <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Name" 
                              id="name" 
                              value={props.values.name}
                              onChange={props.handleChange('name')}
                              error={props.errors.name && Boolean(props.errors.name)}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Surname" 
                              id="surname" 
                              value={props.values.surname}
                              onChange={props.handleChange('surname')}
                              error={props.errors.surname && Boolean(props.errors.surname)}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Mobile" 
                              id="mobile" 
                              value={props.values.mobile}
                              onChange={props.handleChange('mobile')}
                              error={props.errors.mobile && Boolean(props.errors.mobile)}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Land line" 
                              id="landLine" 
                              value={props.values.landLine}
                              onChange={props.handleChange('landLine')}
                              error={props.errors.landLine && Boolean(props.errors.landLine)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="email" 
                              label="Email" 
                              id="email" 
                              value={props.values.email}
                              onChange={props.handleChange('email')}
                              error={props.errors.email && Boolean(props.errors.email)}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Company" 
                              id="company" 
                              value={props.values.company}
                              onChange={props.handleChange('company')}
                              error={props.errors.company && Boolean(props.errors.company)}
                              fullWidth 
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKInput 
                              type="text" 
                              label="Message" 
                              id="message" 
                              value={props.values.message}
                              onChange={props.handleChange('message')}
                              error={props.errors.message && Boolean(props.errors.message)}
                              fullWidth 
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MKTypography variant="caption" color="error" align="center">
                            {props.errors.name && props.errors.name}
                            {!props.errors.name && props.errors.mobile && props.errors.mobile}
                            {!props.errors.name && !props.errors.mobile && props.errors.landLine && props.errors.landLine}
                            {!props.errors.name && !props.errors.mobile && !props.errors.landLine && props.errors.email && props.errors.email}
                            {!props.errors.name && !props.errors.mobile && !props.errors.landLine && !props.errors.email && props.errors.message && props.errors.message}
                            </MKTypography>
                          </Grid>
                          <Grid item xs={12} sx={{ textAlign: "right" }}>
                            <MKButton type="submit" variant="gradient" color="info" sx={{ height: "100%" }}>
                              Send message
                            </MKButton>
                          </Grid>
                        </Grid>
                      </Form>
                      )}
                  </Formik>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
    <SuccessModal isMobile={isMobile} show={show} header="Message sent" text="Thank you for getting in touch. You can expect a response from us." />
    <FailModal isMobile={isMobile} show={showError} header="Message not sent" text="We appreciate you getting in touch, unfortunately we didnt receive your message." />
</>
  );
}

export default Contact;
