/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Material Kit 2 React components
import MKBox from "../../../components/MaterialKit/MKBox";
import MKTypography from "../../../components/MaterialKit/MKTypography";
import MKButton from "../../../components/MaterialKit/MKButton";
import MKInput from "../../../components/MaterialKit/MKInput";


function Step3(props) {
  const [legalPolicyData, setLegalPolicyData] = useState(props.legalPolicyData);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    props.setPercentage(100);
    props.setCurrentStepName("Quote Info");
    props.setNextStepName("Await your documentation");
    
    return () => { 
      cancel = true;
    }
  }, [props]);

  useEffect(() => {
    let cancel = false;
      if(cancel)
        return;
   if(props.legalPolicyData) {
      setLegalPolicyData(props.legalPolicyData);
   }

    return () => { 
      cancel = true;
    }

  }, [props.legalPolicyData]);


  return (
    <>
      <MKBox component="section" p={2} method="post">
        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
          <MKTypography variant="h2" mb={1}>
            Quote Info
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Thank you submitting a quote for Personal Legal Cover.
          </MKTypography>
        </MKBox>
        <MKBox pt={0.5} pb={3} px={3}>
          <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <MKInput 
                label="Quote Reference" 
                id="policyNumber" 
                value={legalPolicyData.reference}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MKInput 
                label="Policy holder" 
                id="policyHolder" 
                value={`${legalPolicyData.title} ${legalPolicyData.firstName} ${legalPolicyData.surname}`}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MKInput 
                label="Email" 
                id="email" 
                value={legalPolicyData.email}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MKInput 
                label="Product" 
                id="product" 
                value={legalPolicyData.cover}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MKInput 
                label="Cover Amount" 
                id="cover" 
                value={legalPolicyData.coverAmount}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MKInput 
                label="Premium (per month)" 
                id="coverRate" 
                value={legalPolicyData.coverRate}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <MKTypography style={{"fontSize": "70px"}}>
                  <CheckCircleIcon style={{"color": "#4caf4f"}} />
              </MKTypography>
              <MKTypography variant="h5" color="dark">Your quote is being processed...</MKTypography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "justify" }}>
              <MKTypography variant="body2" color="text" mb={2}>
              We will get back to you as soon as possible. If you have any queries please do not hesitate to contact +27 (0) 861 086 000.
              </MKTypography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <MKButton type="link" href="/our-offering/legal-cover" variant="gradient" color="dark" sx={{ height: "100%" }}>
                Close
              </MKButton>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </>
  );
}

export default Step3;