// react native


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import Breadcrumbs from "../../components/Breadcrumbs";
import MKTypography from "../../components/MaterialKit/MKTypography";
import HorizontalPageCard from "../../components/Cards/PageCards/HorizontalPageCard";

function Services(props) {

  const labourMatters1 = [
    "Workplace Dispute Resolutions with Unions",
    "Compliance with the Labour Relations Act & Basic Conditions of Employment Act",
    "Basic guide on UIF and the Compensation for Occupational Injuries & Diseases Act (COID Act)",
    "Employment Contracts"

  ];

  const labourMatters2 = [
    "Written warnings, disciplinary hearings and CCMA or Bargaining Council matters",
    "HR Policies and Procedures",
    "Retrenchment Procedures",
    "Other labour related matters which affect the client",

  ];


  const contracts1 = [
    "General legal advice in respect of the Consumer Protection Act and other relevant legislation influencing contracts and its enforceability",
    "Purchase and Sale Agreements",
    "Lease Agreements (residential and commercial)",
    "Service Level Agreements (SLA)",
    "General Terms and Conditions",
    "Customer and Supplier Agreements",
    "Loan Agreements",
    "Acknowledgements of Debt",
    "Credit Applications and any other form of legally binding document",
  ];

  const contracts2 = [
    "Confidentiality and non-disclosure agreements (NDA)",
    "Franchise agreements",
    "Independent Contractor Agreements",
    "Buy and sell Agreements",
    "Sale of Business Agreements",
    "All POPI compliance documents and agreements ",
    "Memorandums of Understanding",
    "Mutual Separation Agreements",
    "Settlement Agreements",
    "Last Wills / Testaments"
  ];

  const debtCollection = [
    "Acknowledgements of Debt",
    "Payment plans",
    "Letters of demand and final letters of demand.",
    "Blacklisting on TransUnion (this is only applicable if the amount to be collected is undisputed or if the defence raised by the debtor is not regarded as a valid legal defence)"
    
  ];

  const legalsupport = [
    "Company policies and procedures",
    "Commercial agreements",
    "HR Policies and procedures, which includes: Leave policies, HIV policies, Dress Code, Maturity and Smoking policies",
    "Strategic legal input",
    "Assistance with general legal matters that relate to the members business."
  ];
  
  
  return (
    <>
      <MKBox
        minHeight="25vh"
        width="100%"
        variant="gradient"
        bgColor="dark"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
              <Breadcrumbs
                routes={[
                  { label: "Collectors", route: "/collectors/services" },
                  { label: "Services" },
                ]}
              />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
            <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                  <MKTypography variant="h3" color="dark">
                    Services
                  </MKTypography>
                  <MKTypography variant="body2" color="dark" opacity={0.8}>
                    Additional services that we include are contracts, labour related matters, debt collection and general legal support.
                  </MKTypography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                  <HorizontalPageCard
                    image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/the-collectors/services/contracts.jpg`}
                    name="Contracts"
                    position={{ color: "info", label: "The drafting, perusing and revising of contracts, including, but not limited to:" }}
                    bullets={contracts1}
                    bullets2={contracts2}
                  />
              </Grid>
              <Grid item xs={12} lg={12}>
                  <HorizontalPageCard
                    image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/the-collectors/services/labour-matters.jpg`}
                    name="Labour Related Matters"
                    position={{ color: "info", label: "These labour services includes:" }}
                    bullets={labourMatters1}
                    bullets2={labourMatters2}
                    flipImage={true}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <HorizontalPageCard
                    image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/the-collectors/services/debt-collection.jpg`}
                    name="Debt collection"
                    position={{ color: "info", label: "Our speciality service includes:" }}
                    bullets={debtCollection}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <HorizontalPageCard
                    image={`${process.env.REACT_APP_CDN_Assets_Base}/pages/the-collectors/services/legal-support.jpg`}
                    name="General Legal Support"
                    position={{ color: "info", label: "General services include:" }}
                    bullets={legalsupport}
                    flipImage={true}
                  />
                </Grid>
              </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default Services;