const baseURL = process.env.REACT_APP_Legal_Base_Url;
const apiKey = process.env.REACT_APP__Api_Key;

export const getSmartLegalCovers = async (coverId) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/insurance/smartlegal/covers/${coverId}`, {
            method: "get",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            }
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });

export const getBankLookups = async () =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/insurance/banks/lookups`, {
            method: "get",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            }
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });

export const getCommsLookups = async () =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/insurance/communication/lookups`, {
            method: "get",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            }
        }).then((response) => {
            resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });

export const postUserLegalPolicy = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/insurance/smartlegal/policy`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
                resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });

export const getUserLegalPolicy = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/insurance/smartlegal/policy/get`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
                resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });


export const getCommercialQuote = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/insurance/commercial/quote/get`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
                resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });


export const postCommercialQuote = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/insurance/commercial/quote`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
                resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });