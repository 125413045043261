// react native
import {useState, useEffect} from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MaterialKit/MKBox";
import MKTypography from "../../components/MaterialKit/MKTypography";

// About Us page sections
import Information from "./sections/Information";
import Videos from "./sections/Videos";

// Images
import overdueLogoWhiteSvg from "../../assets/images/logos/logo-overdue-white.svg";

function DefaultLandingPage(props) {
  const loopArr = [
    "We empower you to effectively collect your outstanding debt",
    "Our Legal Experts focus on supporting you during the debt collection process",
    "Our gratification is in achieving good results for our clients"
  ];

  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(props.isMobile);
  const [sessionInfo, setSessionInfo] = useState(props.sessionInfo);

  useEffect(() => {
    let cancel = false;
    if(cancel)
      return;
    setSessionInfo(props.sessionInfo);

    return () => { 
      cancel = true;
    }
  }, [props.sessionInfo]);

  useEffect(() => {
    let cancel = false;
    if(cancel)
        return;

    setIsMobile(props.isMobile);
    
    return () => { 
      cancel = true;
    }
  }, [props.isMobile])

  useEffect(() => {
    let cancel = false;
    if(cancel)
        return;
        
    const intervalDelayMilliseconds = loopArr[index].length * 50;
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        // reset index if current index is greater than array size
        return prevIndex + 1 < loopArr.length ? prevIndex + 1 : 0;
      });
    }, intervalDelayMilliseconds);

    return () => {
      clearInterval(interval);
      cancel = true;
    };
  });
  return (
    <>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${process.env.REACT_APP_CDN_Assets_Base}/pages/home/background.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        {isMobile && <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Grid item xs={12}>
              <MKBox component="img" src={overdueLogoWhiteSvg} alt="Overdue Logo" width="100%" />
            </Grid>
            <Grid item xs={12}>
              <MKTypography minHeight="100px" variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                <span key={loopArr[index]}>{loopArr[index]}</span>
              </MKTypography>
            </Grid>
          </Grid>}
          {!isMobile && <Grid
            minHeight="40vh"
            container
            item
            xs={12}
            lg={12}
            justifyContent="left"
            alignItems="left"
            sx={{ mx: "auto", textAlign: "left" }}
          >
            <Grid item xs={12} sm={4} sx={{ mt: -15 }}>
              <MKBox component="img" src={overdueLogoWhiteSvg} alt="Overdue Logo" width="100%" />
            </Grid>
            <Grid item xs={12} sm={5}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={3}>
              <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3} style={{"textAlign": "left"}}>
                <span key={loopArr[index]}>{loopArr[index]}</span>
              </MKTypography>
            </Grid>
          </Grid>}
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information sessionInfo={sessionInfo} isMobile={isMobile} show={props.show} showError={props.showError} />
        <Videos />
      </Card>
    </>
  );
}

export default DefaultLandingPage;