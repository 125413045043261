/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

// @mui icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Kit 2 React components
import MKBox from "../../../MaterialKit/MKBox";
import MKTypography from "../../../MaterialKit/MKTypography";

function HorizontalQnATeamCard({ image, name, position, questionsAnswers,region }) {
 
  let key = 0;
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -6 }}>
          <MKBox width="100%" pt={2} pb={1} px={2}>
            <MKBox
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="lg"
              shadow="lg"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }} textAlign="left">
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MKTypography variant="h5">{name}</MKTypography>
            <MKTypography variant="h6" color={position.color}>
              {position.label}
            </MKTypography>
            <MKTypography variant="h6" mb={1}>
              {region}
            </MKTypography>
            {questionsAnswers && questionsAnswers.map((questionAnswer) => (
            <MKBox mb={2} key={++key}>
              <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content" id="panel1bh-header">
                      <MKTypography variant="h6" color="text">
                        {questionAnswer.question}
                      </MKTypography>
                  </AccordionSummary>
                <AccordionDetails>
                  {questionAnswer.answer && <MKTypography variant="body2" color="text">
                  {questionAnswer.answer}
                  </MKTypography>}
                  {questionAnswer && questionAnswer.answers && questionAnswer.answers.map((questionAnswerItem) => (<MKTypography key={++key} component={'span'} variant="body2" color="text">
                    {questionAnswerItem.answer}
                    {!questionAnswerItem.bullets && <br/>}
                    {!questionAnswerItem.bullets && <br/>}
                    {questionAnswerItem.bullets && <ul>
                      {questionAnswerItem.bullets.map((bullet) => (
                        <li key={++key}>{bullet}</li>
                      ))}
                    </ul>}
                  </MKTypography>))}
                </AccordionDetails>
              </Accordion>
            </MKBox>))}
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalQnATeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string)
};

export default HorizontalQnATeamCard;
